import { useContext, useEffect, useState } from "react";
import styles from "./live-stream.module.scss";
import { Event } from "components";
import MainLayoutContext from "context/main-layout";
import livestreamBg from "../../../assets/img/livestream-bg.png";
import { livestreamAction } from "../../../core/actions";
import { LivestreamData } from "../../../types/livestream";
import { DateTime } from "luxon";


const LiveStreaming = () => {
  const { updateHeading } = useContext(MainLayoutContext);
  const [groupedLivestreams, setGroupedLivestreams] = useState<{
    [key: string]: any;
  }>({});
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchLivestream = async () => {
    setLoading(true);
    const res = await livestreamAction.fetchMine(1);
    if (!res) return;

    setLoading(false);
    const data = res.data.result;
    console.log(data);
    data.sort(
      (a: any, b: any) =>
        new Date(a.event_start_time).getTime() -
        new Date(b.event_start_time).getTime()
    );
    const formatted = data.reduce(
      (l: { [key: string]: LivestreamData[] }, r: LivestreamData) => {
        const date = DateTime.fromISO(r.event_start_time).toFormat(
          "dd-MM-yyyy"
        );
        l[date] = l[date] ? l[date] : [];
        l[date].push(r);

        return l;
      },
      {}
    );

    setGroupedLivestreams(formatted);
  };

  useEffect(() => {
    updateHeading({
      header: "Live Classes",
      subHeader: "",
    });

    fetchLivestream().then();
  }, []);

  useEffect(() => {
    const dates = Object.keys(groupedLivestreams);
    if (dates?.length > 0) setSelectedDate(dates[0]);
  }, [groupedLivestreams]);

  return (
    <div className={styles.main}>
      <div className={styles.calender}>
        {Object.keys(groupedLivestreams).map((date, id) => {
          const d = DateTime.fromFormat(date, "dd-MM-yyyy");
          const today = DateTime.local();

          return (
            <div
              key={id}
              className={date === selectedDate ? styles.active : styles.all}
              onClick={(e) => setSelectedDate(date)}
            >
              {d.hasSame(today, "day") ? (
                <div>Today</div>
              ) : (
                <div>
                  <div>
                    <b>{d.day}</b>
                  </div>
                  <small>{d.monthShort}</small>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div>
        {selectedDate === "" ? (
          <div>
            <div className={`${styles.livestream} flex justify-center`}>
              <img src={livestreamBg} alt="livestream-bg" />
            </div>
            <div className={styles.title}>
              <span>You do not have any streams at the time.</span>
            </div>
          </div>
        ) : (
          <div className="mt-50 mb-30">
            <p className="red-header">
              <b>Upcoming Events</b>
            </p>
          </div>
        )}
      </div>

      <div className={styles.main}>
        <div className={styles.events}>
          {selectedDate !== "" &&
            groupedLivestreams[selectedDate].map((lv: LivestreamData, i: number) => (
              <Event key={i}
                title={lv.title}
                to={`${lv.id}`}
                tutor={lv.tutor_name}
                img={lv.thumbnail}
                date={lv.event_start_time}
                price={lv.price}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LiveStreaming;
