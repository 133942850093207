import OverlayLoader from "components/OverlayLoader";
import React, { FC } from "react";
import { ReactChild } from "react";
import styles from "./welcome.module.scss";

type Props = {
  children?: JSX.Element | JSX.Element[];
  spinner: boolean | ReactChild;
  active: boolean;
};

const WelcomeLayout: FC<Props> = ({ children, spinner, active }) => {
  return (
    <div>
      <div className={styles.welcome}>
        <h2 className={styles.textLogo}>Dyeka</h2>
        <div className="container">
          <h1 className="text-light text-center display-1 mt-0">
            Who’s learning?
          </h1>
          <div className="text-light text-center">
            Select a child’s profile to proceed.
          </div>
          <OverlayLoader spinner={spinner} active={active}>
            <div className={styles.frame}>{children}</div>
          </OverlayLoader>
        </div>
      </div>
    </div>
  );
};

export default WelcomeLayout;
