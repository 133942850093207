import React from "react";
// import { motion } from "framer-motion";

function ModalBox({ children, close }) {
  return (
    <div
      className="w-full h-screen z-[1000000] fixed top-0 left-0 backdrop_black pt-[120px] p-[20px] flex justify-center items-center"
      onClick={close}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div
        layout
        className="w-fit rounded-[10px] overflow-y-auto h-fit"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

export default ModalBox;
