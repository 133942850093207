import React, { useState } from "react";
import WorktodoCard from "../../../components/WorktodoCard";
import { WorktodoType } from "../../../types/lesson";

function WorkToDo() {
  const [path, setPath] = useState("classwork");

  const handleClick = (pathname: string) => {
    setPath(pathname);
  };

  const custom_data = [
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row space-x-5 w-full items-center justify-between text-[10px] sm:text-[12px] font-avenir">
        <div
          className={`flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "classwork"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("classwork")}
        >
          CLASS WORK
        </div>
        <div
          className={`flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "assignment"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("assignment")}
        >
          ASSIGNMENT
        </div>
        <div
          className={` flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "submitted"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("submitted")}
        >
          SUBMITTED
        </div>
      </div>

      <div className="mt-[30px] w-full flex flex-wrap gap-4 md:gap-5 sm:mt-[37px]">
        {custom_data?.map((data: WorktodoType) => (
          <WorktodoCard title={data?.title} date_posted={data?.date_posted} />
        ))}
      </div>
    </div>
  );
}

export default WorkToDo;
