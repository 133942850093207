import React from "react";
import { Link } from "react-router-dom";
import { WorktodoType } from "types/lesson";

function WorktodoCard(data: WorktodoType) {
  return (
    <Link
      to="/app/work-to-do/1"
      className="cursor-pointer flex-grow font-avenir px-[15px] pt-[15px] pb-[15px] text-[#15345B] max-w-[380px] h-[137px] bg-white shadow-sm rounded-[10px]"
    >
      <div className="flex h-fit w-full mb-[10px] justify-between">
        <div className="block w-full mr-[15px]">
          <div
            style={{ fontWeight: "bolder" }}
            className="font-black mb-[12px] text-[10px]"
          >
            {data?.title}
          </div>
          <div className="font-normal mb-[12px] text-[10px]">
            {data?.date_posted}
          </div>
        </div>

        <svg
          width="36"
          height="40"
          viewBox="0 0 36 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3679 36.8933H3.32577V2.58962H23.3538V11.7373H33.3679V36.8933ZM25.8574 3.06302L32.8496 9.45041H25.8574V3.06304V3.06302ZM34.7723 7.97307L27.4746 1.30667C26.7636 0.659517 25.8198 0.302734 24.8184 0.302734H0.822266V39.1802H35.8713V10.3994C35.8713 9.48469 35.4808 8.62252 34.7723 7.97305L34.7723 7.97307ZM8.33278 25.4588H25.8574V23.1719H8.33278V25.4588ZM8.33278 16.3111H28.3609V14.0242H8.33278V16.3111ZM8.33278 30.0326H18.3468V27.7457H8.33278V30.0326ZM8.33278 20.885H23.3538V18.598H8.33278V20.885ZM8.33278 11.7373H18.3468V9.45039H8.33278V11.7373Z"
            fill="#F4A62B"
          />
        </svg>
      </div>

      <div className="pt-[12px] border-t border-[#E3E3E3] text-[10px]">
        Add Class Comment
      </div>
    </Link>
  );
}

export default WorktodoCard;
