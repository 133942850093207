import {ChatMessageData} from "../../../types/livestream";
import './message.scss'
import {DateTime} from "luxon";

type Props = {
  message: ChatMessageData
}

const LivestreamMessage = ({ message }: Props) => {
  return (
    <div className="message-cover">
      {
        message &&
        <div className="d-flex">
          <div className={`content flex-grow-1 ${message.mine ? 'ms-5' : 'me-5'}`}>
            <p className="name">{message.fullName}</p>
            <div className="message">{message.message}</div>
            <p className="time flex-grow-1">{DateTime.fromISO(message.timestamp).toFormat('hh:mm a')}</p>
          </div>
        </div>
      }
    </div>
  )
}

export default LivestreamMessage