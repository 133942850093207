import { ReactChild, FC } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
export type OverlayLoaderProps = {
    active: boolean,
    children?: ReactChild | ReactChild[];
    spinner: boolean | ReactChild;
    text?: string | ReactChild;
    style?: React.CSSProperties;
};

const OverlayLoader: FC<OverlayLoaderProps> = ({ children, active, spinner, text, style }) => {
    return (
        <LoadingOverlay
            {...{ active, spinner, text }}
            styles={{
                overlay: (base: any) => ({
                    ...base,
                    borderRadius: '20px'
                })
            }}
        >
            {children}
        </LoadingOverlay>
    )
}
export default OverlayLoader