import MainLayoutContext from "context/main-layout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styles from "../my-class/my-class.module.scss";
import group_img from "../../../assets/img/Group 1000004363.png";
import formatToCurrency from "config/formatAmount";
import Axios from "core/service/axios";
import { handleError } from "core/utils/error-handler";
import UnpaidGroups from "./UnpaidGroup";
import { Loader } from "components";

function GroupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [group, setGroup] = useState({});
  const { updateHeading } = useContext(MainLayoutContext);
  const [loading, setLoading] = useState(true);
  const { groupid } = useParams();

  const fetchGroup = async (id) => {
    setLoading(true);

    Axios.get(`/groups/${id}`)
      .then((res) => {
        setLoading(false);
        const { success, data } = res.data;
        if (success) {
          setGroup(data);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        const { response } = handleError(err);
        alert(response.message);
      });

    setLoading(false);
  };

  useEffect(() => {
    updateHeading({
      header: `Join a Group Today`,
      subHeader:
        "You are doing great this week. Your teachers are proud of you. Keep it up!",
    });

    fetchGroup(groupid);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && group && <UnpaidGroups group={group} />}
    </>
  );
}

export default GroupPage;
