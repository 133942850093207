import "./test.scss";
import { TestData } from "../../types/test";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  test: TestData | any;
};

const TestMini = ({ test }: Props) => {
  const navigate = useNavigate();
  const { course_name } = useParams<{ [key: string]: string }>();

  const goToPage = () => {
    navigate(`/app/course-modules/${course_name}/test/${test.id}`);
  };

  return (
    <div className="test-mini" onClick={goToPage}>
      <p className="text-primary-color title">{test.title}</p>
      <p className="description">{test.description}</p>
      <div className="d-flex mt-3">
        {test.pass_mark && <p className="info">Passmark: {test.pass_mark}</p>}
        {test.no_of_retries && (
          <p className="ms-auto info">Max retries: {test.no_of_retries}</p>
        )}
      </div>
    </div>
  );
};

export default TestMini;
