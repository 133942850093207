import { useContext, useEffect, useState } from "react";
import { subscriptionAction } from "../../../../../core/actions/index";
import styles from "./billing-history.module.scss";
import Pagination from "../../../../../components/pagination";

import { toast } from "react-toastify";

const BillingHistory = () => {
  const [history, setHistory] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    size: 5,
    page: 1,
    total: 0,
  });

  const handleSelectPage = (page) => {
    fetchHistory(page, pageInfo.size).then();
  };

  const fetchHistory = async (page, size) => {
    subscriptionAction.history(page, size).then((res) => {
      if (!res.success) return toast.error(res.message);
      const { result, page_info } = res.data;
      const { size, page, total } = page_info;
      pageInfo.page = Number.parseInt(page);
      pageInfo.size = Number.parseInt(size);
      pageInfo.total = Number.parseInt(total);
      setPageInfo(pageInfo);
      setHistory(result);
    });
  };

  useEffect(() => {
    fetchHistory(pageInfo.page, pageInfo.size).then();
  }, []);

  return (
    <div>
      <span className={styles.title}>BILLING HISTORY</span>
      <table>
        <thead>
          <tr>
            <th>
              <div>Plan</div>
            </th>
            <th>Amount</th>
            <th>Status</th>
            <th>{`Date & Time`}</th>
            <th>
              <div>Method</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {history.map((t) => {
            return (
              <tr key={t.id}>
                <td>
                  <div>{t.plan.name} Plan</div>
                </td>
                <td>
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(t.amount)}
                </td>
                <td>
                  {t.status === "success" ? (
                    <span className={styles.successful}>Successful</span>
                  ) : (
                    <span className={styles.failed}>Failed</span>
                  )}
                </td>
                <td>{`${new Date(
                  t.transaction_date
                ).toLocaleDateString()}`}</td>
                <td>
                  <div>
                    <span className={styles.card_type}>
                      {t.card.type.charAt(0).toUpperCase() +
                        t.card.type.substring(1)}
                    </span>
                    {t.card.last4}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        onSelectPage={handleSelectPage}
        size={pageInfo.size}
        page={pageInfo.page}
        total={pageInfo.total}
      />
    </div>
  );
};

export default BillingHistory;
