import React, { ReactElement, useState } from "react";
import TabTitle from "./tab-title";
import styles from "./tabs.module.scss";

type Props = {
  children: ReactElement[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
};

const Tabs: React.FC<Props> = ({ children, selectedTab, setSelectedTab }) => {
  // const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div style={{ width: "100%" }} className={styles.container}>
      <ul className={styles.tabs}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </ul>
      <div>{children[selectedTab]}</div>
    </div>
  );
};

export default Tabs;
