import Toggle from "components/toggle";
import styles from "./subscribe.module.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { TableCheckIcon } from "assets/svg";
import { Button } from "components";
import UserContext from "context/user";
import { toast } from "react-toastify";
import { subscriptionAction } from "../../../../../core/actions/index";
import { appHelpers } from "../../../../../core/utils/helpers";
import { Modal } from "bootstrap";
import MainLayoutContext from "../../../../../context/main-layout";
import Card from "../../../../../components/card";

const Subscribe = ({ cards }) => {
  const { currentUser, refreshUser } = useContext(UserContext);
  const { setLoading } = useContext(MainLayoutContext);

  const [yearly, setYearly] = useState(false);
  const [plans, setPlans] = useState([]);

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(
    currentUser.subscription?.id
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

  const cardsModalRef = useRef(null);
  const [cardsModal, setCardsModal] = useState(null);

  const cancelPlanModalRef = useRef(null);
  const [cancelPlanModal, setCancelPlanModal] = useState(null);

  const fetchPlans = async () => {
    try {
      const res = await subscriptionAction.plans();
      if (res.success) setPlans(res.data.result);
      else toast.error(res.message);
    } catch (e) {
      console.log(e);
    }
  };

  const initSubscription = async () => {
    if (!cardsModal) return;
    if (cards?.length > 0) {
      cardsModal.show();
    } else toast.error("Please add a card first");
  };

  const subscribe = async () => {
    if (!selectedPaymentMethod) {
      toast.error("Please select a payment method");
      return;
    }

    setLoading(true);

    const data = {
      plan_id: Number(selectedPlan),
      card_id: selectedPaymentMethod,
      frequency: yearly ? "yearly" : "monthly",
    };

    const res = await subscriptionAction.subscribe(data);
    if (res) {
      if (res.success) {
        await refreshUser();
        cardsModal.hide();
        toast.success("Subscribed successfully");
      } else toast.error(res.message);
    }

    setLoading(false);
  };

  const initCancelSubscription = () => {
    if (!cancelPlanModal) return;
    cancelPlanModal.show();
  };

  const cancelSubscription = async () => {
    setLoading(true);

    try {
      const res = await subscriptionAction.cancel(currentUser.subscription_id);
      if (res.success) {
        await refreshUser();
        cancelPlanModal.hide();
        toast.success("Subscription cancelled successfully");
      } else toast.error(res.message);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const handleAmountChange = (amount) => {
    setSelectedAmount(parseInt(amount) * 100);
  };

  const handleSelectedPlan = (id) => {
    setSelectedPlan(id);
  };

  useEffect(() => {
    fetchPlans().then(() => {});
  }, []);

  useEffect(() => {
    if (cardsModalRef.current) setCardsModal(new Modal(cardsModalRef.current));
  }, [cardsModalRef]);

  useEffect(() => {
    if (cancelPlanModalRef.current)
      setCancelPlanModal(new Modal(cancelPlanModalRef.current));
  }, [cancelPlanModalRef]);

  return (
    <div className="subscribe-page">
      <div className={styles.history}>
        <div className={styles.left}>
          <p className={styles.billingHistory}>
            Select any plan to continue with your
            <span className="primary-color"> Subscription</span>
          </p>
        </div>
        {/* <div className={styles.right}>
          <button className={styles.button}>BILLING HISTORY</button>
        </div> */}
      </div>

      <div className={styles.grid}>
        {/* Hold Toggle Switches */}
        <div className={styles.toggles}>
          <Toggle
            option1="Monthly"
            option2="Yearly"
            name="period"
            handleToggle={() => setYearly(!yearly)}
            toggled={yearly}
          />
        </div>

        {/* Hold Custom Radio */}
        <div className={styles.radios}>
          {plans.map((plan) => {
            return (
              <div key={`plan-${plan.id}`} className={styles.subscriptionPlan}>
                <input
                  name="plans"
                  type="radio"
                  id={`plan-${plan.id}`}
                  checked={selectedPlan === plan.id}
                  onChange={() => {
                    handleSelectedPlan(plan.id);
                  }}
                />

                <label htmlFor={`plan-${plan.id}`}>
                  <div>
                    <div className={styles.planName}>{plan.name}</div>
                    <div className={styles.planCost}>
                      {yearly
                        ? appHelpers.formatCurrency(plan.yearly_amount)
                        : appHelpers.formatCurrency(plan.monthly_amount)}
                    </div>
                    <small>per {yearly ? "year" : "month"}</small>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>

      {/* Table */}
      <div className="mt-40">
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>Number of user profiles</td>
              {plans.map((plan) => (
                <td key={`plan-${plan.id}-profiles`}>{plan.profiles}</td>
              ))}
            </tr>
            <tr>
              <td>Number of devices you can use at the same time</td>
              {plans.map((plan) => (
                <td key={`plan-${plan.id}-devices`}>{plan.devices}</td>
              ))}
            </tr>
            <tr>
              <td>Number of devices you can watch offline</td>
              {plans.map((plan) => (
                <td key={`plan-${plan.id}-devices-off`}>{plan.devices}</td>
              ))}
            </tr>
            <tr>
              <td>Unlimited access to all videos and learning materials</td>
              {plans.map((plan) => (
                <td key={`plan-${plan.id}-vid-access`}>
                  <TableCheckIcon />
                </td>
              ))}
            </tr>
            <tr>
              <td>Access to livestream classes</td>
              {plans.map((plan) => (
                <td key={`plan-${plan.id}-live-access`}>
                  <TableCheckIcon />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {selectedPlan && (
        <div className="flex justify-content-center" style={{ marginTop: 50 }}>
          {selectedPlan !== currentUser.subscription?.id ? (
            <Button
              variant="stripped"
              className="bg-primary"
              onClick={initSubscription}
            >
              SUBSCRIBE
            </Button>
          ) : (
            <Button
              variant="stripped"
              className="bg-red"
              onClick={initCancelSubscription}
            >
              CANCEL
            </Button>
          )}
        </div>
      )}

      <div className="modal" ref={cardsModalRef} tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Payment Method</h5>
            </div>
            <div className="modal-body">
              <div className="cards">
                {cards.map((item) => (
                  <Card
                    key={`card-${item.id}`}
                    card={item}
                    className={`${
                      selectedPaymentMethod === item.id && "selected"
                    }`}
                    onClick={(id) => setSelectedPaymentMethod(id)}
                  />
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-end">
                <Button
                  className="btn bg-red btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Button>
                <Button
                  className="btn text-white bg-primary"
                  onClick={subscribe}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" ref={cancelPlanModalRef} tabIndex={-2}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Are you sure you want to cancel your plan
              </h5>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <Button
                  className="btn bg-red btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Button>
                <Button
                  className="btn text-white bg-primary"
                  onClick={cancelSubscription}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
