import "./livestream.scss";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import MainLayoutContext from "../../../../context/main-layout";
import { livestreamAction } from "../../../../core/actions";
import ReactPlayer from "react-player";
import {
  ChatMessageData,
  LivestreamData,
  LivestreamMemberData,
  StreamSession,
} from "../../../../types/livestream";
import { toast } from "react-toastify";
import ViewLivestream from "../../../../components/livestream/view-livestream";
import WatchLivestream from "../../../../components/livestream/watch-livestream";
import OT, {
  Session,
  Stream,
  Subscriber,
  SubscriberProperties,
} from "@opentok/client";
import { useNavigate } from "react-router-dom";
import {produce} from "immer";

const LivestreamIDPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { updateHeading, setLoading } = useContext(MainLayoutContext);

  const [livestream, setLivestream] = useState<LivestreamData>();
  const [joined, setJoined] = useState(false);

  const [session, setSession] = useState<Session>();
  const [streamSession, setStreamSession] = useState<StreamSession>();
  const sessionRef = useRef<Session>();
  const subscriptions = useRef<Subscriber[]>([]);
  const [messages, setMessages] = useState<ChatMessageData[]>([]);
  const [members, setMembers] = useState<LivestreamMemberData[]>([]);

  const fetchLivestream = async () => {
    setLoading(true);

    const res = await livestreamAction.fetchLivestream(Number(id));
    if (!res) {
      setLoading(false);
      return;
    }

    if (res.success) setLivestream(res.data);
    else toast.error(res.message);

    setLoading(false);
  };

  const joinLivestream = async () => {
    setLoading(true);

    if (livestream && livestream.session_id) {
      try {
        const init = await livestreamAction.init(livestream.id);
        if (!init) return;
        if (!init.success) {
          toast.error(init.message);
          return;
        }

        const data = init.data;
        const streamSession: StreamSession = {
          apiKey: data.apiKey,
          sessionId: data.session_id,
          token: data.token,
        };

        setStreamSession(streamSession);
        await initSession(streamSession);
      } catch (e: any) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const initSession = (streamSession: StreamSession) => {
    try {
      const session = OT.initSession(
        streamSession.apiKey,
        streamSession.sessionId
      );

      session.on("streamCreated", (e) => {
        const stream = e.stream;
        console.log("created");

        const streamOptions: SubscriberProperties = {
          insertMode: "append",
          width: "100%",
          height: "100%",
        };

        const subscriber = session.subscribe(
          stream,
          "player",
          streamOptions,
          (e) => {
            if (e) console.error(e);
          }
        );

        subscriptions.current.push(subscriber);
      });

      session.on("connectionCreated", function (e) {
        const connection = e.connection;

        const member = {
          id: connection.connectionId,
          fullName: connection.data.split("=")[1],
        };

        setMembers((members) =>
          produce(members, (data) => {
            data.push(member);
          })
        );
      });

      session.on("connectionDestroyed", function (e) {
        try {
          const id = e.connection.connectionId;
          setMembers((members) =>
            produce(members, (data) => {
              const findIndex = data.findIndex((member) => member.id === id);
              data.splice(findIndex, 1);
            })
          );
        } catch (e: any) {
          console.error(e.message);
        } finally {
          navigate(`/app/live-streaming/${livestream?.id}`);
        }
      });

      session.on("signal:msg", function (event: any) {
        const data = JSON.parse(event.data);

        const msg = {
          ...data,
          mine: event.from.connectionId === session.connection?.connectionId,
          timestamp: data.timestamp ? data.timestamp : new Date(),
        };

        console.log(msg);
        setMessages((messages) =>
          produce(messages, (data) => {
            data.push(msg);
          })
        );
      });

      setSession(session);
      setJoined(true);
    } catch (e: any) {
      console.error(e);
      setLoading(false);
    }
  };

  const leaveLivestream = async () => { };

  useEffect(() => {
    updateHeading({
      header: "Livestream",
      subHeader: "",
    });

    fetchLivestream().then();

    return () => {
      if (sessionRef.current) {
        for (const sub of subscriptions.current) {
          sub.subscribeToVideo(false);
          sessionRef.current.unsubscribe(sub);
        }

        sessionRef.current.off(
          "streamCreated connectionCreated connectionDestroyed signal:msg"
        );
        sessionRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (livestream) {
      updateHeading({
        header: livestream.title,
        subHeader: "",
      });
    }
  }, [livestream]);

  useEffect(() => {
    if (session) {
      sessionRef.current = session;
    }
  }, [session]);

  return (
    <div className="livestream-page">
      <div className="back-btn-cover">
        <Link to={`/app/live-streaming`} className="text-primary-color">
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </Link>
      </div>
      {/* <ReactPlayer
          //
          playsinline // very very imp prop
          playIcon={<></>}
          //
          pip={false}
          light={false}
          controls
          // muted={false}
          playing
          //
          url={"https://www.youtube.com/watch?v=8-DAeDtH7K8"}
          //
          height={"100%"}
          width={"50%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        /> */}
      {livestream &&
        (joined ? (
          <WatchLivestream
            livestream={livestream}
            session={session}
            messages={messages}
            members={members}
            streamSession={streamSession}
          />
        ) : (
          <ViewLivestream livestream={livestream} join={joinLivestream} />
        ))}
    </div>
  );
};

export default LivestreamIDPage;
