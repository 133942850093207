import { useState } from "react";
import Supervisor from "./supervisor";
import SwitchProfile from "./switch-profile";
import ChildProfile from "./child-profile";
import Tabs from "components/tabs";
import Tab from "components/tabs/tab";

const SettingsProfile = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handlesetSelectedTab = (value: number) => {
    setSelectedTab(value);
  };
  return (
    <div>
      <Tabs selectedTab={selectedTab} setSelectedTab={handlesetSelectedTab}>
        {/* <Tab title="GUARDIAN PROFILE"> */}
        <Tab title="SUPERVISOR">
          <Supervisor />
        </Tab>

        {/* <Tab title="MANAGE PROFILE"> */}
        <Tab title="CHILD PROFILE">
          <ChildProfile />
        </Tab>

        <Tab title="SWITCH PROFILE">
          <SwitchProfile />
        </Tab>
      </Tabs>
    </div>
  );
};

export default SettingsProfile;
