import {
  ProfileIcon,
  SubscriptionIcon,
  SecurityIcon,
  NotificationIcon,
  PrivacyPolicyIcon,
  TermsAndConditionsIcon,
} from "assets/svg";
import MainLayoutContext from "context/main-layout";
import { flattenDeep } from "lodash";
import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./settings.module.scss";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const SettingLayout = ({ children }: Props) => {
  const settingsNav = [
    {
      title: "PROFILE",
      description: "Access and update all profiles",
      icon: <ProfileIcon />,
      url: "/app/settings/profile",
      external: false,
    },
    // {
    //   title: "SUBSCRIPTION",
    //   description: "Select your preferred plan",
    //   icon: <SubscriptionIcon />,
    //   url: "/app/settings/subscription",
    //   external: false,
    // },
    {
      title: "PAYMENTS",
      description: "Manage and update your wallet",
      icon: <SubscriptionIcon />,
      url: "/app/settings/payments",
      external: false,
    },
    {
      title: "SECURITY",
      description: "Update your password",
      icon: <SecurityIcon />,
      url: "/app/settings/security",
      external: false,
    },
    {
      title: "NOTIFICATION",
      description: "Update your notification preferences",
      icon: <NotificationIcon />,
      url: "/app/settings/notifications",
      external: false,
    },
    {
      title: "PRIVACY POLICY",
      description: "View our privacy policies",
      icon: <PrivacyPolicyIcon />,
      url: "https://dyeka.ng/privacy-policy.html",
      external: true,
    },
    {
      title: "TERMS AND CONDITIONS",
      description: "View our terms of service and conditions",
      icon: <TermsAndConditionsIcon />,
      url: "https://dyeka.ng/terms-of-use.html",
      external: true,
    },
    {
      title: "FAQS",
      description: "View our frequently asked questions",
      icon: <TermsAndConditionsIcon />,
      url: "https://dyeka.ng/support.html",
      external: true,
    },
  ];

  const { updateHeading } = useContext(MainLayoutContext);
  useEffect(() => {
    updateHeading({
      header: "Settings",
      subHeader: "Modify your account preferences",
    });
  }, []);

  return (
    <div className={styles.grid}>
      <ul className={styles.settingNav}>
        {settingsNav.map((item, index) => (
          <li key={`setting-${index}`}>
            {item.external ? (
              <a
                target="_blank"
                rel="noreferrer" 
                href={item.url}
                className="flex p-20 align-items-start"
              >
                {item.icon}
                <div className={styles.navText}>
                  <div>{item.title}</div>
                  <small className={styles.small}>{item.description}</small>
                </div>
              </a>
            ) : (
              <NavLink
                to={item.url}
                className={({ isActive }) => "flex p-20 align-items-start" + (isActive ? " settingActive" : "")}
              >
                {item.icon}
                <div className={styles.navText}>
                  <div>{item.title}</div>
                  <small className={styles.small}>{item.description}</small>
                </div>
              </NavLink>
            )}
          </li>
        ))}
      </ul>

      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default SettingLayout;
