import GroupsCard from "components/GroupsCard";
import MainLayoutContext from "context/main-layout";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { GetGroupApiService } from "core/service/groups";
import { handleError } from "core/utils/error-handler";
import { Button, Loader } from "components";
import { DUMMY_DATA } from "config/dummyData";
import Axios from "core/service/axios";
import styles from "./privateclasses.module.scss";
import hand_png from "../../../assets/img/hand.png";
import FilterBox from "components/FilterBox";
import CourseCard from "components/CourseCard";
import { appHelpers } from "core/utils/helpers";

function PrivateClasses() {
  const navigate = useNavigate();
  const { updateHeading } = useContext(MainLayoutContext);

  const [private_classes, setPrivateClasses] = useState([]);
  const [subject, setSubject] = useState("");
  const [price, setPrice] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [loading, setLoading] = useState(false);

  const [rawSubject, setRawSubject] = useState([]);

  const getPrivateClasses = () => {
    setLoading(true);
    Axios.get(
      `/live/one-on-one/list?page=1${subject ? "&subject_id=" + subject : ""}${price ? "&price=" + price : ""
      }${curriculum ? "&curriculum=" + curriculum : ""}`
    )
      .then((res) => {
        setLoading(false);
        const { success, data } = res.data;
        if (success) {
          setPrivateClasses(data.result);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        const { response } = handleError(err);
        alert(response.message);
      });
  };

  useEffect(() => {
    updateHeading({
      header: `Private Classes`,
      subHeader:
        "You are doing great this week. Your teachers are proud of you. Keep it up!",
    });
    getPrivateClasses();
    getSubjects();
  }, []);

  const handleSearch = () => {
    getPrivateClasses();
  };

  const getSubjects = () => {
    Axios.get(`/subjects`)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          setRawSubject(data.result);
          return;
        }
      })
      .catch((err) => {
        const { response } = handleError(err);
        alert(response.message);
      });
  };

  const goToClass = async (value) => {
    navigate(`/app/private-classes/${value?.id}`, {
      state: {
        ...value,
      },
    });
  };

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.imageBgDashboard}>
          <div className=" msm:justify-start 2lg:justify-center mmd:!p-[16px] h-full mlg:w-full w-[100%] flex !items-center justify-between overflow-hidden rounded-[10px]">
            <div className="pl-[51px] mmd:pl-0 flex h-fit !items-start !flex-col gap-[31px]">
              <div className="text-d-blue md:text-[28px] text-[35px] !font-[800] font-avenir leading-[35px]">
                Collaborate with your
                <br className="2lg:hidden" /> friends today.
              </div>

              <Button
                type="button"
                className="max-w-[255px] h-[55px]"
                onClick={() => navigate("/app/groups")}
              >
                Join a Group
              </Button>
            </div>
          </div>

          <img
            src={hand_png}
            alt=""
            loading="lazy"
            className="object-cover mid:top-[-50px] 2lg:hidden absolute top-0 right-0"
          />
        </div>

        <div className="mb-[45px] w-full items-center gap-4 grid grid-cols-4 text-[10px] mlg:grid-cols-2 mid:grid-cols-1 sm:text-[12px] font-avenir">
          <FilterBox
            title={"Subject"}
            type="select"
            options={rawSubject}
            name={"subject"}
            value={subject}
            setValue={setSubject}
          />

          <FilterBox
            title={"Price"}
            type="input"
            options={[]}
            name={"price"}
            value={price}
            setValue={setPrice}
          />

          <FilterBox
            title={"Curriculum"}
            type="input"
            options={[]}
            name={"curriculum"}
            value={curriculum}
            setValue={setCurriculum}
          />

          <Button
            type="button"
            className="w-full h-[55px]"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>

        <>
          {loading ? (
            <Loader />
          ) : (
            <section className="mlg:gap-[25px] px-4 mmd:gap-[18px] w-full items-center gap-4 grid grid-cols-4 mlg:grid-cols-2 mid:grid-cols-1">
              {private_classes?.map((item) => (
                // {DUMMY_DATA[3]?.data?.map((item) => (
                <CourseCard
                  PlayClick={() => goToClass(item)}
                  period={item?.payment_period}
                  bought={item?.bought}
                  key={item?.id}
                  thumbnail={
                    item.thumbnail ||
                    item?.live_event?.thumbnail ||
                    item?.lesson?.thumbnail
                  }
                  tutorName={
                    item.tutor_name ||
                    item?.live_event?.tutor_name ||
                    item?.lesson?.tutor_name ||
                    (item?.tutor?.first_name &&
                      `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)
                  }
                  description={appHelpers.truncateValue(
                    item?.description ||
                    item?.live_event?.description ||
                    item?.lesson?.description,
                    40
                  )}
                  live={true}
                  duration={
                    item?.duration ||
                    item?.preview_duration ||
                    item?.live_event?.preview_duration ||
                    item?.lesson?.preview_duration
                  }
                  price={
                    item?.price ||
                    item?.live_event?.price ||
                    item?.lesson?.price
                  }
                  startAge={""}
                  endAge={""}
                  subject={
                    item?.title ||
                    item?.name ||
                    item?.subject ||
                    item?.live_event?.subject ||
                    item?.lesson?.subject ||
                    item?.lesson?.name
                  }
                />
              ))}
            </section>
          )}
        </>
      </div>
    </>
  );
}

export default PrivateClasses;
