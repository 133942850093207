import { Button, Course, Loader, UngoingCourse } from "components";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import MainLayoutContext from "context/main-layout";
import { SecureStorage } from "core/utils/storage";
import hand_png from "../../../assets/img/hand.png";
import {
  DashboardInitialResultData,
  DashboardResultData,
  IDashboard,
  InnerResultData,
} from "types/dashboard";
import { IProfile } from "types/profiles";
import { GetDashboardApiService } from "core/service/dashboard";
import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import { SubjectData } from "types/subject";
import { handleError } from "core/utils/error-handler";
import { appHelpers } from "core/utils/helpers";
import { useNavigate } from "react-router-dom";
import { subjectAction } from "../../../core/actions";
import { toast } from "react-toastify";
import slugify from "slugify";
import CourseCard from "components/CourseCard";
import FilterBox from "components/FilterBox";
import Axios from "core/service/axios";

const Dashboard = () => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [price, setPrice] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [loading, setLoading] = useState(false);

  const [path, setPath] = useState("Numeracy");

  const handleClick = (pathname: string) => {
    setPath(pathname);
  };
  const { updateHeading, setSubjects } = useContext(MainLayoutContext);
  const [rawSubject, setRawSubject] = useState([]);
  const [dashboardData, setDashboardData] = useState<DashboardResultData>();
  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const profile: IProfile = JSON.parse(selectedProfile);

  const getDashboardData = useCallback(async () => {
    try {
      let res;
      setLoading(true);
      if (!subject && !price && !curriculum) res = (await Axios.get(`/home/all`)).data;
      else res = (await Axios.get(`/dashboard?page=1${subject ? "&subject_id=" + subject : ""}${price ? "&price=" + price : ""}${curriculum ? "&curriculum=" + curriculum : ""}`)).data;
      const { success, data } = res
      setLoading(false);
      if (success) {
        setDashboardData(data.result || data);
      }
    } catch (err) {
      setLoading(false);
      const { response } = handleError(err);
      alert(response.message);
    }
  }, [setLoading, setDashboardData, subject, price, curriculum]);


  const handleSearch = () => getDashboardData();

  const fetchSubjects = useCallback(async (class_id: any) => {
    const res = await subjectAction.classSubjects(class_id);
    const formatted: { [key: string]: any } = {};
    if (!res) return;

    if (res.success) {
      const data: SubjectData[] = res.data.result;
      setRawSubject(res.data.result);

      for (const subject of data) {
        const key = slugify(subject.name, "_").toLowerCase();
        formatted[key] = { ...subject };

        const lessonRes = await subjectAction.classLessons(subject.id, class_id);
        if (lessonRes && lessonRes.success) formatted[key].lessons = lessonRes.data.result;

        const testRes = await subjectAction.tests(subject.id);
        if (testRes && testRes.success) formatted[key].tests = testRes.data.result;
      }

      setSubjects(formatted);
    } else toast.error(res.message);
  }, [setRawSubject, setSubjects])

  const goToLesson = async (id: any) => {
    navigate(`/app/lesson/${id}`);
  };

  useEffect(() => {
    if (!profile?.class_id) {
      navigate('/app/profile');
    } else {
      updateHeading({
        header: `Welcome back, ${profile ? profile?.first_name : "Student"}!`,
        subHeader: "You are doing great this week. Your teachers are proud of you. Keep it up!",
      });
      getDashboardData();
      fetchSubjects(profile?.class_id).then();
    }
  }, []);

  const handleSeeAll = (value: DashboardInitialResultData) => {
    navigate(`/app/${['Live Classes', 'Groups', 'Private Classes', 'Course Modules'].includes(value.description) ? "" : "course/"}${appHelpers
      .removeCommaAndDotAndSpace(value.description.replace(" ", "-"))
      ?.toLocaleLowerCase()}`, {
      state: {
        ...value,
      },
    });
  };

  const renderCourseVideos = (dashboardData: DashboardResultData) => {
    const courseElems = [];
    for (const [key, value] of Object.entries(dashboardData)) {
      courseElems.push(
        <div key={`item-${key}-`}>
          {value?.data?.length !== 0 && (
            <div className="!mb-10">
              <div className="flex justify-content-between mt-20 mb-10">
                <div className="red-header">
                  <b>{appHelpers.removeCommaAndDot(value.description)}</b>
                </div>
                <small
                  className="text-primary-color cursor-pointer"
                  onClick={() => handleSeeAll(value)}
                >
                  See All
                </small>
              </div>
              {value.type === "videos" ? (
                <div className={styles.column_3}>
                  {value.data.slice(0, 3).map((item: any, index: number) => (
                    <UngoingCourse
                      img={item.thumbnail}
                      key={index}
                      percentage={item?.video_history?.percentage_watched}
                      title={item.title}
                      description={appHelpers.truncateValue(item?.description)}
                      subject={item.subject}
                      tutor={item.tutor_name}
                      PlayClick={() => goToLesson(item.id)}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.column_4}>
                  {value.data.slice(0, 4).map((item: any, index: number) => (
                    <CourseCard
                      PlayClick={() => goToLesson(item.id)}
                      period={item?.payment_period}
                      bought={item?.bought}
                      key={index}
                      thumbnail={
                        item.thumbnail ||
                        item?.live_event?.thumbnail ||
                        item?.lesson?.thumbnail
                      }
                      tutorName={
                        item.tutor_name ||
                        item?.live_event?.tutor_name ||
                        item?.lesson?.tutor_name ||
                        (item?.tutor?.first_name &&
                          `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)
                      }
                      description={appHelpers.truncateValue(
                        item?.description ||
                        item?.live_event?.description ||
                        item?.lesson?.description,
                        40
                      )}
                      live={true}
                      duration={
                        item?.duration ||
                        item?.preview_duration ||
                        item?.live_event?.preview_duration ||
                        item?.lesson?.preview_duration
                      }
                      price={
                        item?.price ||
                        item?.live_event?.price ||
                        item?.lesson?.price
                      }
                      startAge={""}
                      endAge={""}
                      subject={
                        item?.title ||
                        item?.name ||
                        item?.subject ||
                        item?.live_event?.subject ||
                        item?.lesson?.subject ||
                        item?.lesson?.name
                      }
                    />
                    // <Course
                    //   img={item.thumbnail}
                    //   key={index}
                    //   time={`${Math.floor(item?.preview_duration / 60)} mins`}
                    //   title={item.name}
                    //   description={appHelpers.truncateValue(item?.description)}
                    //   subject={item.subject}
                    //   tutor={item.tutor_name}
                    //   category={item.name}
                    //   noOfVideos={item?.no_of_videos}
                    //   duration={item?.preview_duration}
                    //   PlayClick={() => goToLesson(item.id)}
                    // />
                  ))}
                </div>
              )}{" "}
            </div>
          )}
        </div>
      );
    }
    return courseElems;
  };

  return (
    <div className={styles.layout}>
      <div className={styles.imageBgDashboard}>
        <div className=" msm:justify-start 2lg:justify-center mmd:!p-[16px] h-full mlg:w-full w-[100%] flex !items-center justify-between overflow-hidden rounded-[10px]">
          <div className="pl-[51px] mmd:pl-0 flex h-fit !items-start !flex-col gap-[31px]">
            <div className="text-d-blue md:text-[28px] text-[35px] !font-[800] font-avenir leading-[35px]">
              Collaborate with your
              <br className="2lg:hidden" /> friends today.
            </div>
            <Button type="button" className="max-w-[255px] h-[55px]" onClick={() => navigate("/app/groups")} >Join a Group</Button>
          </div>
        </div>
        <img src={hand_png} alt="" loading="lazy" className="object-cover mid:top-[-50px] 2lg:hidden absolute top-0 right-0" />
      </div>

      <div className="mb-[45px] px-4 w-full items-center gap-4 grid grid-cols-4 text-[10px] mlg:grid-cols-2 mid:grid-cols-1 sm:text-[12px] font-avenir">
        <FilterBox title={"Subject"} type="select" options={rawSubject} name={"subject"} value={subject} setValue={setSubject} />
        <FilterBox title={"Price"} type="input" options={[]} name={"price"} value={price} setValue={setPrice} />
        <FilterBox title={"Curriculum"} type="input" options={[]} name={"curriculum"} value={curriculum} setValue={setCurriculum} />
        <Button type="button" className="w-full h-[55px]" onClick={handleSearch}>Search</Button>
      </div>

      <>
        {loading && <Loader />}
        {!loading && dashboardData && renderCourseVideos(dashboardData)}
      </>
    </div>
  );
};

export default Dashboard;
