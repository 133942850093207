import React, { ReactChild, FC } from "react";
import styles from "./button.module.scss";

type Props = {
  type?: "button" | "submit" | "reset" | undefined;
  children?: ReactChild | ReactChild[];
  variant?: "outline" | "block" | "stripped" | "grey";
  className?: string;
  disabled?: boolean;
  size?: "medium";
  onClick?: () => void;
};

const Button: FC<Props> = ({
  type,
  children,
  onClick,
  className,
  variant,
  size,
  disabled,
  ...props
}) => (
  <button
    type={type}
    onClick={onClick}
    data-variant={variant}
    data-size={size}
    style={{
      background: variant === "grey" ? "rgb(244 243 243)" : "rgb(255 108 0)",
    }}
    className={[styles.btn, className].join(" ")}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default Button;
