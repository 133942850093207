import { AuthLayout, Loader } from "components";
import { lazy, Suspense, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRouteError } from "react-router-dom";
import { AuthRoutes } from "./routes/auth";
import { MainRoutes } from "./routes/main";
import { ErrorElement } from "pages/error";
import { ForgotPasswordIllustration } from "assets/svg";

export function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

const AppRouter = [
  AuthRoutes,
  MainRoutes,
  {
    index: true,
    element: <Navigate to="/app/home" />,
    errorElement: <ErrorElement />,
  },
  {
    path: "*",
    element: <AuthLayout color="red" welcomeText="Welcome back" intro="This is an error page" formDescription=" " logoSideText="" navigationLabel="Take me home?" navigationName="Click me" navigateTo="/" illustration={ForgotPasswordIllustration} />,
  },
];

export default AppRouter;
