import { Play } from 'assets/svg';
import React, { FC } from 'react';
import styles from './teacher-card.module.scss';

type Props = {
  img?: string;
  tutor: string;
  subject: string;
};

const TeacherCard: FC<Props> = ({ img, tutor, subject }) => {
  return (
    <div className={styles.card}>
      <div className={styles.img}>
        <img src={img} alt="dyeka courses" />
      </div>

      <div>
        <div className="pl-20 pb-20 mt-10">
          <div>
            <div className="text-red">
              <b>{tutor}</b>
            </div>
            <small>{subject}</small>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeacherCard;
