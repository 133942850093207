import React, { useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(Tooltip, Legend, ArcElement);

function DoughnutChart({ percentage, analysis, label, color }) {
  const doughnutRef = useRef();
  const doughnutPercent = percentage;
  const doughnutLabel = label;
  const colors = ["#579AFF", "#62BDD3", "#FF6C00", "#FF447C", "#4CB050", "#4CB3E0"];
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable the default aspect ratio
    // aspectRatio: 2, // Adjust the aspect ratio to control the size
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const doughnutData = {
    labels: doughnutLabel,
    datasets: [
      {
        label: "Course",
        data: doughnutPercent,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 5,
        borderJoinStyle: "round",
        hoverBorderWidth: 10,
      },
    ],
  };

  const DisplayLabel = ({ item, idx }) => {
    return (
      <div className="flex items-center gap-2 mb-2">
        <div className={`rounded-full w-2 h-2`} style={{ backgroundColor: colors[idx] }}></div>
        <div>
          <p className="font-[700] text-[14px]">{item}</p>
          <p className="font-[600] text-[12px]">{Math.round(percentage[idx])}%</p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex items-start justify-around">
      <div style={{ width: "300px", height: "300px" }}>
        <Doughnut data={doughnutData} options={options} ref={doughnutRef} />
      </div>
      <div>{label.length > 0 && label.map((item, idx) => <DisplayLabel key={idx} item={item} idx={idx} />)}</div>
    </div>
  );
}

export default DoughnutChart;
