import './member.scss'
import {LivestreamMemberData} from "../../../../types/livestream";
import userICOrange from '../../../../assets/svg/user-ic-orange.svg'

type Props = {
  member: LivestreamMemberData
}

const LivestreamMember = ({ member }: Props) => {
  return (
    <div className="livestream-member">
      <div className="d-flex align-items-center">
        <div className="profile-image me-3">
          <img src={userICOrange} alt='userIcon' />
        </div>
        <p className="name flex-grow-1">{member.fullName}</p>
      </div>
      <hr className='divider' />
    </div>
  )
}

export default LivestreamMember