import './question.scss'
import {TestQuestionsData, TestQuestionsOptionData} from "../../types/test";
import Option from "./option";
import {produce} from "immer";

type Props = {
  no: number
  question: TestQuestionsData
  updateQuestion: (id: number, question: TestQuestionsData) => void
}

const Question = ({ no, question, updateQuestion }: Props) => {

  const markOption = (id: number) => {
    updateQuestion(no, produce(question, data => {
      for (const optionId in data.options) {
        data.options[optionId].marked = false
      }

      data.options[id].marked = true
    }))
  }

  return (
    <div className="question">
      <div className="row">
        <div className="col-auto">
          <div className="question-no">{no + 1}</div>
        </div>
        <div className="col">
          <p className="question-text">{ question.question }</p>
          <div className="options">
            {
              question.options.map(((option, no) => (
                <Option key={`option-${option.id}`} option={option} no={no} markOption={markOption} />
              )))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Question