import axios, { InternalAxiosRequestConfig } from "axios";
import { getDefaultAuth } from "context/auth";
import { URL } from "../utils/constants";

const Axios = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = getDefaultAuth();
    if (token) {
      if (config.headers !== undefined) {
        config.headers["Authorization"] = token;
      }
    }
    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);

export default Axios;
