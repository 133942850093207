import { ReactNode } from 'react';

type Props = {
  title?: string;
  description?: string;
  icon?: JSX.Element;
  children?: ReactNode
};

const Tab = ({ children }: Props) => {
  return <div>{children}</div>;
};

export default Tab;
