import './livestream-chat.scss'
import {ChatMessageData} from "../../../types/livestream";
import {Session} from "@opentok/client";
import React, {ChangeEvent, FormEventHandler, MouseEventHandler, useContext, useState} from "react";
import {produce} from "immer";
import {DateTime} from "luxon";
import LivestreamMessage from "../message";
import SendIC from '../../../assets/svg/send-ic.svg'
import MainLayoutContext from "../../../context/main-layout";
import UserContext from "../../../context/user";
import {toast} from "react-toastify";

type Props = {
  messages: ChatMessageData[]
  session?: Session
}

const LivestreamChat = ({ messages, session }: Props) => {
  const { currentUser } = useContext(UserContext)
  const [newChat, setNewChat] = useState<ChatMessageData>({
    message: '', timestamp: '', fullName: `${currentUser.first_name} ${currentUser.last_name}`
  })

  const setMessage = (e: ChangeEvent<HTMLInputElement>) => {
    setNewChat(data => produce(data, newData => {
      newData.message = e.target.value
    }))
  }

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault()
    if (!session) {
      toast.error('not connected')
      return
    }

    session.signal({
      type: 'msg',
      data: JSON.stringify({
        ...newChat,
        timestamp: DateTime.now().toISO(),
      }),
    }, function (error) {
      if (error) console.error('Error sending signal:', error.name, error.message);
      else setNewChat({...newChat, message: '', timestamp: ''});
    })
  }

  return (
    <div className="chat-cover d-flex flex-column">
      <h4 className="side-section-header flex-shrink-0">Comments</h4>
      <div className="messages my-2 flex-grow-1">
        {
          messages.map((message, id) => (
            <LivestreamMessage message={message} key={`message-${id}`}/>
          ))
        }
      </div>
      <form onSubmit={sendMessage} className="mt-auto flex-shrink-0">
        <div className="dashboard-input custom-input-group send-comment-input">
          <input className="send-input" placeholder="Type a comment" value={newChat.message} onChange={setMessage}/>
          <div className="input-group-append send-ic" onClick={sendMessage}>
            <img src={SendIC} alt="send-ic"/>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LivestreamChat