import { Eye, Loading, SignupIllustration } from "assets/svg";
import { AxiosResponse } from "axios";
import { AuthLayout, Button } from "components";
import AuthContext from "context/auth";
import UserContext from "context/user";
import { SignUpApiService } from "core/service/auth";
import { handleError } from "core/utils/error-handler";
import useForm from "core/utils/use-form";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { IAuth } from "types/auth";
import { IResponse } from "types/responses";
import { IUser } from "types/user";
import { emailRegex, isValid, isFullyValid } from "utils";
import styles from "./signup.module.scss";
import { toast } from "react-toastify";
import { appHelpers } from "../../../core/utils/helpers";

const Signup = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [terms, setTerms] = useState(false);
  const { signUpState, updateSignupState, storeTemp } = useContext(UserContext);
  const { setAuthAndCache } = useContext(AuthContext);
  const { setLoading } = useContext(AuthContext);

  const togglePassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const toggleTerms = () => {
    if (terms) {
      setTerms(false);
    } else {
      setTerms(true);
    }
  };

  const { mutate, isLoading } = useMutation(SignUpApiService, {
    onSuccess: (res: AxiosResponse<IResponse<IUser>>) => {
      const { success, data } = res.data;
      if (success) {
        updateSignupState(inputs);
        storeTemp(data);
        setAuthAndCache(`${`Bearer`} ${data.token}`);
        navigate("/app/add-profile?type=onboarding");
        setLoading(false);
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      appHelpers.alertError(response?.message);
      setLoading(false);
    },
  });

  const submit = () => {
    setLoading(true);
    mutate({ ...inputs, device_token: "random" });
  };

  const { inputs, errors, handleChange, handleSubmit } = useForm<IUser>(
    signUpState,
    submit,
    true
  );

  const correctData = Boolean(
    inputs?.email
      ?.trim()
      ?.match(/^[A-Z0-9._%$#/*'&!?~`|{}=^+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) &&
    inputs?.first_name &&
    inputs?.last_name &&
    inputs?.phone_number &&
    inputs.password
  );

  return (
    <AuthLayout
      welcomeText="Free trial for 7 days"
      intro="Your child is unique, let’s make it official"
      formDescription="Please fill the form with a guardian or account supervisor information."
      logoSideText="Sign up to"
      navigationLabel="Already have an account?"
      navigationName="Login"
      navigateTo="/auth/login"
      illustration={SignupIllustration}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
            data-validation="lettersOnly, notNull"
            maxLength={20}
          />
          {errors.first_name && (
            <small className="error">{errors.first_name}</small>
          )}
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
            data-validation="lettersOnly, notNull"
            maxLength={20}
          />
          {errors.last_name && (
            <small className="error">{errors.last_name}</small>
          )}
        </div>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            value={inputs.email}
            onChange={handleChange}
            data-validation="email"
            maxLength={50}
          />
          {errors.email && <small className="error">{errors.email}</small>}
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phone_number"
            value={inputs.phone_number}
            onChange={handleChange}
            data-rules="maxLength_15, absNumber"
            maxLength={15}
          />
          {errors.phone_number && (
            <small className="error">{errors.phone_number}</small>
          )}
        </div>
        <div className={[styles.full, "form-group password"].join(" ")}>
          <label>Password</label>
          <input
            type={type}
            name="password"
            value={inputs.password}
            onChange={handleChange}
            data-validation="notNull"
          />
          {errors.password && (
            <small className="error">{errors.password}</small>
          )}
          <div className="show-password" onClick={togglePassword}>
            <Eye />
          </div>
        </div>
        <div className={[styles.full, "form-group"].join(" ")}>
          <label className="flex items-center">
            <input type="checkbox" className="mr-10" onChange={toggleTerms} />
            <p className="">
              Creating an account means you’re okay with our{" "}
              <a
                href="https://dyeka.ng/terms-of-use.html"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-primary-color">Terms of Service</span>{" "}
              </a>
              and{" "}
              <a
                href="https://dyeka.ng/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <span className="text-primary-color">Privacy Policy</span>
              </a>
              .
            </p>
          </label>
        </div>
        <div className={[styles.full, "mt-40"].join(" ")}>
          <Button type="submit" disabled={!terms || isLoading || !correctData}>
            {isLoading ? <Loading /> : "CREATE ACCOUNT"}
          </Button>

          <div className="mt-30">
            <span
              style={{ cursor: "pointer" }}
              className="text-primary-color font-medium text-[16px]"
              onClick={() => navigate(-1)}
            >
              Back
              {/* <i className="fa fa-chevron-left" aria-hidden="true"></i>
              <i className="fa fa-chevron-left" aria-hidden="true"></i> */}
            </span>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Signup;
