import formatToCurrency from "config/formatAmount";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VideoListCard from "../../../components/VideoListCard";
import { formatPrice } from "../../../config/formatPrice";
import { Outline } from "components";
import Fade from "@material-ui/core/Fade";
import Axios from "core/service/axios";
import { toast } from "react-toastify";
import Tabs from "components/tabs";
import Tab from "components/tabs/tab";
import { BCalendar, BClock, Paystack, Wallet } from "assets/svg";

function UnpaidClass({ lesson, styles, selectedVideo, changeVideo, setLoading }) {
  const navigate = useNavigate();
  const [showPayment, setShowPayment] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [discount, setDiscount] = useState("");

  const handlePayment = (id, channel, price) => {
    setLoading(true);
    Axios.post(`https://api.test.dyeka.ng/lessons/${id}/purchase-course`, {
      amount: price,
      channel,
      code: "",
    })
      .then((res) => {
        if (channel === "paystack") {
          toast.success("Redirecting");
          setLoading(false);

          window.location.href = res?.data?.data?.authorization_url;
        } else {
          toast.success("Payment Successful");
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.topic}>{lesson.name}</div>
        <div>
          <span className={styles.tutor}>Instructor - </span>
          {lesson.tutor.first_name} {lesson.tutor.last_name}
        </div>
      </div>
      <div className={styles.layout}>
        <div className={styles.videoSide}>
          <div className={styles.playerCover}>
            <img width="100%" src={lesson.thumbnail} alt="thumbnail" />
          </div>
          <div className="mt-20">
            <div className="flex items-start mmd:flex-col justify-between w-full">
              <div className="gap-[22px] flex items-start flex-col justify-between w-full">
                <div className={styles.heading}>
                  <div className={styles.subHeading}>About This Class</div>
                </div>

                <div className="mt-10">{lesson.description}</div>

                <div className="flex w-full items-center justify-between mt-12 md:mb-10 sm:mb-10">
                  <div className="flex items-center gap-[15px]">
                    <BClock />
                    <div className="flex items-start flex-col lg:text-[16px] gap-2 text-[20px] text-[#000] font-Avenirltstd">
                      Duration
                      <div className="font-[700] text-d-orange">{lesson?.duration || lesson?.preview_duration} minutes</div>
                    </div>
                  </div>

                  <div className="flex items-center gap-[15px]">
                    <BCalendar />
                    <div className="flex items-start flex-col lg:text-[16px] gap-2 text-[20px] text-[#000] font-Avenirltstd">
                      Ages
                      <div className="font-[700] text-d-orange">
                        {lesson?.start_age || lesson?.classes[0]?.start_age} - {lesson?.end_age || lesson?.classes[0]?.end_age} years old
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.learnSide}`}>
          <div className="">
            <div className="flex items-center gap-[23px]">
              <div className="h-[53px] rounded-[5px] bg-d-orange !px-[40px] flex items-center lg:text-[16px] md:text-[18px] text-[24px] font-[700] text-white font-Avenirltstd">{formatPrice(lesson?.price)}</div>

              <button type="button" className="h-[53px] rounded-[5px] border border-d-orange bg-transparent !px-[40px] flex items-center lg:text-[16px] md:text-[18px] text-[24px] font-[700] text-d-orange font-Avenirltstd" onClick={() => setShowPayment(!showPayment)}>
                Enroll
              </button>
            </div>
            {showPayment && (
              <Fade in={showPayment} style={{ transitionDelay: "100ms" }}>
                <div className="flex items-start flex-col">
                  <div className="flex">
                    <input value={discount} name="amount" id="amount" placeholder="Enter promo code" onChange={(e) => setDiscount(e.target.value)} className="w-full outline-none rounded-lg border h-[60px] font-[350px] text-[16px] text-[#999999] px-[26px] py-[20px] mb-[10-px] mt-[10px]" />
                  </div>
                  <div className="flex mb-[10px] items-center gap-[23px] mt-10">
                    <button type="button" className="text-white font-Avenirltstd bg-d-orange rounded h-[53px] flex space-x-2 px-[25px]" onClick={() => handlePayment(lesson.id, "wallet", lesson.price)}>
                      <Wallet /> <p className="text-[20px] font-[700]">Wallet</p>
                    </button>

                    <button type="button" className="text-white font-Avenirltstd bg-d-orange rounded h-[53px] flex space-x-2 px-[15px]" onClick={() => handlePayment(lesson.id, "paystack", lesson.price)}>
                      <Paystack /> <p className="text-[20px] font-[700]">Paystack</p>
                    </button>
                  </div>
                </div>
              </Fade>
            )}
            <div className={`${styles.contentHeading} mt-12`}>Contents</div>
            <div className="mt-2">
              {lesson.videos.length > 0 ? lesson.videos.map((video, index) => <Outline key={index} topic={video.title} active={selectedVideo && video.id === selectedVideo.id} duration={video.meta_data.duration} onClick={(e) => changeVideo(video)} />) : <div>No videos uploaded for this course</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnpaidClass;
