import React, { useEffect, useState } from "react";
import Subscribe from "./subscribe";
import PaymentMethod from "./payment-method";
import Tabs from "components/tabs";
import Tab from "components/tabs/tab";
import { Card } from "../../../../types/card";
import { subscriptionAction } from "../../../../core/actions";
import BillingHistory from "./billing-history";

const Subscription = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [cards, setCards] = useState<Card[]>([]);

  const fetchPaymentMethods = async () => {
    const res = await subscriptionAction.cards();
    if (res) {
      if (res.success) setCards(res.data.result);
      else console.error(res.message);
    }
  };

  useEffect(() => {
    fetchPaymentMethods().then();
  }, []);

  return (
    <div>
      <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab}>
        <Tab title="SUBSCRIBE">
          <Subscribe cards={cards} />
        </Tab>
        <Tab title="PAYMENT METHOD">
          <PaymentMethod />
        </Tab>
        <Tab title="BILLING HISTORY">
          <BillingHistory />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Subscription;
