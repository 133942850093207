import { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { base64Converter } from "./base64converter";
import { emailRegex } from "../../utils";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const useForm = <T>(
  initState: T,
  callback: () => void,
  validateKeyUp: boolean = false
) => {
  const [inputs, setInputs] = useState<T>(initState);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    callback();
  };

  //todo make dynamic
  const validations: {
    [k: string]: { validate: (value: string) => boolean; message: string };
  } = {
    lettersOnly: {
      validate: (value) => !value || /^[a-z]+$/i.test(value),
      message: "only letters are allowed here",
    },

    email: {
      validate: (value) => emailRegex.test(value),
      message: "invalid email",
    },

    notNull: {
      validate: (value) => !!(value && value !== ""),
      message: "cannot be blank",
    },
  };

  const rules: { [k: string]: (value: string) => boolean } = {
    maxLength_15: (value) => value.length <= 15,
    absNumber: (value) => !!(value && Number(value) >= 0),
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    e.persist();
    if (e.target.type === "file") {
      const files = (e.target as HTMLInputElement).files;
      if (files)
        base64Converter(files[0]).then((result) => {
          setInputs((inputs) => ({ ...inputs, [e.target.name]: result }));
        });
    } else {
      const name = e.target.name;
      const value = e.target.value;

      if (value && value.trim() !== "") {
        const inputRules = e.target.getAttribute("data-rules")?.split(",");
        if (inputRules) {
          for (const ruleKey of inputRules) {
            const rule = rules[ruleKey.trim()];
            if (!rule(value)) return false;
          }
        }
      }

      if (validateKeyUp) validate(e);
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };

  const validate = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    const inputValidation = e.target
      .getAttribute("data-validation")
      ?.split(",");
    if (inputValidation) {
      for (const validationKey of inputValidation) {
        const validation = validations[validationKey.trim()];
        if (!validation.validate(value)) {
          setErrors((errors) => ({ ...errors, [name]: validation.message }));
          return;
        }
      }
    }

    setErrors((errors) => ({ ...errors, [name]: "" }));
  };

  return { handleChange, handleSubmit, validate, inputs, errors };
};

export default useForm;
