import { Course, Loader } from "components";
import MainLayoutContext from "context/main-layout";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./course-modules.module.scss";
import { toast } from "react-toastify";
import { subjectAction } from "../../../core/actions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { SecureStorage } from "core/utils/storage";
import { IProfile } from "types/profiles";
import slugify from "slugify";
import { SubjectData } from "types/subject";
import { appHelpers } from "core/utils/helpers";
import CourseCard from "components/CourseCard";

const MyCourses = () => {
  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const profile: IProfile = JSON.parse(selectedProfile);
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState({} as { [key: string]: any });
  const { updateHeading } = useContext(MainLayoutContext);
  const navigate = useNavigate();

  const goToLesson = async (id: number) => {
    navigate(`/app/lesson/${id}`);
  };

  const fetchSubjects = useCallback(
    (class_id: any) =>
      (async () => {
        const res = await subjectAction.classSubjects(class_id);
        const formatted: { [key: string]: any } = {};
        if (!res) return;

        if (res.success) {
          const data: SubjectData[] = res.data.result;

          for (const subject of data) {
            const key = slugify(subject.name, "_").toLowerCase();
            formatted[key] = { ...subject };

            const lessonRes = await subjectAction.classLessons(subject.id, class_id);
            if (lessonRes && lessonRes.success) formatted[key].lessons = lessonRes.data.result;

            const testRes = await subjectAction.tests(subject.id);
            if (testRes && testRes.success) formatted[key].tests = testRes.data.result;
          }
          setSubjects(formatted);
        } else toast.error(res.message);
        setLoading(false);
      })(),
    [setLoading, setSubjects]
  );

  useEffect(() => {
    fetchSubjects(profile?.class_id);
    updateHeading({
      header: "Take a look at your lessons",
      subHeader: "The lessons here are all the available ones for your profile level.",
    });
  }, [fetchSubjects, profile?.class_id, updateHeading]);

  return (
    <div className={styles.layout}>
      <>
        {loading && <Loader />}
        {!loading &&
          Object.entries(subjects).map(([key, subject]: [string, any], sbj_index) => {
            if (subject.lessons.length <= 0) return null;
            return (
              <div className="subject" key={key}>
                <div className="flex justify-content-between mt-20 mb-10">
                  <div className="red-header">
                    <b>{subject.description}</b>
                  </div>
                  <Link to={`/app/my-courses/${key}`}>
                    <small className="text-primary-color">See All</small>
                  </Link>
                </div>
                <div className={styles.column_4}>
                  {subject.lessons.slice(0, 4).map((lesson: any, index: number) => (
                    <CourseCard
                      period={lesson?.payment_period}
                      PlayClick={() => goToLesson(lesson.id)}
                      bought={lesson?.bought}
                      key={index}
                      thumbnail={lesson.thumbnail || lesson?.live_event?.thumbnail || lesson?.lesson?.thumbnail || (lesson?.tutor?.first_name && `${lesson?.tutor?.first_name} ${lesson?.tutor?.last_name}`)}
                      tutorName={lesson.tutor_name || lesson?.live_event?.tutor_name || lesson?.lesson?.tutor_name}
                      description={appHelpers.truncateValue(lesson?.description || lesson?.live_event?.description || lesson?.lesson?.description, 40)}
                      live={true}
                      duration={lesson?.duration || lesson?.preview_duration || lesson?.live_event?.preview_duration || lesson?.lesson?.preview_duration}
                      price={lesson?.price || lesson?.live_event?.price || lesson?.lesson?.price}
                      startAge={""}
                      endAge={""}
                      subject={lesson?.title || lesson?.name || lesson?.subject || lesson?.live_event?.subject || lesson?.lesson?.subject || lesson?.lesson?.name}
                    />
                  ))}
                </div>
              </div>
            );
          })}
      </>
    </div>
  );
};

export default MyCourses;
