import React, { useState } from "react";
import dyeka_logo from "../../../assets/img/Dyeka Logo 6.png";
import { WorktodoType } from "../../../types/lesson";

function MediaFiles() {
  const [path, setPath] = useState("images");

  const handleClick = (pathname: string) => {
    setPath(pathname);
  };

  const custom_data = [
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
    {
      title: "Introduction to Mathematics",
      date_posted: "Posted 12 june 12:30",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row space-x-5 w-full items-center justify-between text-[10px] sm:text-[12px] font-avenir">
        <div
          className={`flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "images"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("images")}
        >
          IMAGES
        </div>
        <div
          className={`flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "documents"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("documents")}
        >
          DOCUMENTS
        </div>
        <div
          className={` flex cursor-pointer justify-center items-center w-[200px] h-[48px] rounded-[12px] ${
            path === "videos"
              ? "bg-[#15345B] text-[#FFFFFF]"
              : "bg-[#FFF8F2] text-[#FF6C00]"
          }`}
          onClick={() => handleClick("videos")}
        >
          VIDEOS
        </div>
      </div>

      <div className="mt-[30px] w-full flex flex-wrap gap-4 sm:mt-[37px]">
        {custom_data?.map((data: WorktodoType) => (
          <div className="w-[90px] flex-grow flex justify-center items-center h-[90px] rounded-[10px] bg-[#FFF8F2] shadow-sm">
            <img src={dyeka_logo} alt="single_image" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default MediaFiles;
