import { useContext, useEffect, useState, useRef, useCallback } from "react";
import styles from "./my-class.module.scss";
import { Button, Outline, TestOutline } from "components";
import MainLayoutContext from "context/main-layout";
import Tabs from "components/tabs";
import Tab from "components/tabs/tab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { lessonAction } from "../../../core/actions";
import { toast } from "react-toastify";
import UserContext from "context/user";
import { LessonData, LessonVideo, LessonTest } from "../../../types/lesson";
import { NoClass } from "assets/svg";
import ModalBox from "components/ViewImage";
import UnpaidClass from "./UnpaidClass";
import { copyTextToClipboard } from "config/copyToClipboard";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, FacebookMessengerShareButton, EmailIcon, FacebookIcon, FacebookMessengerIcon, LinkedinIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { useLocation, useSearchParams } from "react-router-dom";
import Axios from "core/service/axios";

const MyClass = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<File>();
  const { updateHeading, setLoading } = useContext(MainLayoutContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const { currentUser } = useContext(UserContext);
  const [lesson, setLesson] = useState<LessonData>();
  const [tests, setTests] = useState<LessonTest[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<LessonVideo>();
  const { id }: { id?: unknown } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [percentageWatched, setPercentageWatched] = useState(0);

  const fetchLesson = async (id: number) => {
    setLoading(true);

    const res = await lessonAction.fetch(id);
    if (res) {
      if (res.success) {
        saveLastWatched(res.data);
        setLesson(res.data);

        const getTestRes = await lessonAction.tests(res.data.id);
        if (getTestRes?.success) {
          setTests(getTestRes.data.result);
        }
      } else {
        toast.error(res.message);
        navigate("/app/course-modules");
      }
    }

    setLoading(false);
  };

  const changeVideo = async (video: LessonVideo) => {
    setLoading(true);

    //check for payment
    if (!lesson?.bought) {
      toast.error("Pay to view more");
      setLoading(false);
      return;
    }

    if (lesson) {
      const res = await lessonAction.video(lesson.id, video.id);

      if (res) {
        if (res.success) setSelectedVideo(res.data);
        else toast.error(res.message);
      }
    }

    setLoading(false);
  };

  const saveLastWatched = (lesson: LessonData) => {
    const prevId = Number(localStorage.getItem("lastWatchedLesson"));
    if (prevId && prevId === lesson.id) return;
    localStorage.setItem("lastWatchedLesson", lesson.id.toString());
  };

  const getLastWatched = async () => {
    const prevId = Number(localStorage.getItem("lastWatchedLesson"));
    if (prevId) await fetchLesson(prevId);
  };

  useEffect(() => {
    updateHeading({
      header: "Welcome back to class!",
      subHeader: "Let’s continue where you left off. Good luck!",
    });

    if (id)
      fetchLesson(id as number)
        .then()
        .catch();
    else getLastWatched().then().catch();
  }, []);

  const url = window.location.href; // `app.dyeka.ng/app/lesson/${lesson?.id}`;

  const handleCopy = () => {
    copyTextToClipboard(url)
      .then(() => {
        console.log("Copied.");
        toast.success("Copied to clipboard");
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Error Copying to clipboard");
      });
  };

  useEffect(() => {
    const reference = searchParams.get("reference");
    if (reference) {
      setLoading(true);
      Axios.patch(`https://api.test.dyeka.ng/lessons/verify-payment?reference=${reference}`)
        .then((res) => {
          const currentUrl = window.location.href; // Get the full URL as a string
          const url = new URL(currentUrl);
          url.search = ""; // Remove query parameters
          navigate(url.pathname + url.hash, { replace: true });
          toast.success("Purchase verified");
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    }
  }, [search]);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;

      setCurrentTime(currentTime);
      setPercentageWatched((currentTime / duration) * 100);
    }
  };

  const handleVideoEvent = useCallback(() => {
    handleTimeUpdate();
    handleApiCall();
  }, [currentTime, percentageWatched, handleTimeUpdate]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("play", handleVideoEvent);
      videoElement.addEventListener("pause", handleVideoEvent);
    }

    const handleBeforeUnload = () => {
      handleApiCall();
      handleTimeUpdate();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handleVideoEvent);
        videoElement.removeEventListener("pause", handleVideoEvent);
      }
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleVideoEvent, handleTimeUpdate]);

  const handleApiCall = () => {
    if (lesson && selectedVideo) {
      Axios.put(`http://127.0.0.1:6543/lessons/${lesson.id}/${selectedVideo.id}/progress`, {
        percentage_watched: Math.round(percentageWatched),
        current_time: Math.round(currentTime),
        completed: percentageWatched === 100,
      }).then((res) => {});
    }
  };

  return (
    <>
      {modalOpen && (
        <ModalBox close={() => setModalOpen(false)}>
          <div className="mid:w-full w-[660px] h-full rounded-[10px] bg-white p-[40px] font-avenir">
            <div className="mmd:text-[22px] text-[25px] text-d-blue mmd:mb-[28px]  mb-[38px] font-[600]">Share this lesson</div>

            <div className="mmd:text-[18px] mmd:mb-[18px] mb-[20px] text-[20px] font-medium text-d-blue opacity-50">Lesson link</div>

            <div className="mmd:mb-[32px] overflow-hidden mb-[40px] border border-[#D9D9D9] rounded-[5px] mmd:py-[11px] mmd:pr-[11px] mmd:pl-[11px] mmd:gap-5 py-[10px] pr-[7px] pl-[24px] flex mmd:flex-col justify-between items-center w-full">
              <div className="truncate text-[18px] mmd:w-full text-left leading-[18px] font-semibold text-[#101800]">{url}</div>
              <Button type="button" className="flex justify-center h-[41px] mmd:w-full items-center w-[95px]" onClick={handleCopy}>
                Copy
              </Button>
            </div>

            <div className="mmd:text-[18px] mmd:mb-[16px] mb-[20px] font-medium text-[20px] text-d-blue opacity-50">Share link</div>

            <div className="flex items-center gap-6 flex-wrap">
              <EmailShareButton url={url} subject="" body="" separator=" ">
                <EmailIcon size={50} borderRadius={20} />
              </EmailShareButton>

              <FacebookShareButton url={url} hashtag="Dyeka">
                <FacebookIcon size={50} borderRadius={20} />
              </FacebookShareButton>

              <FacebookMessengerShareButton url={url} appId="">
                <FacebookMessengerIcon size={50} borderRadius={20} />
              </FacebookMessengerShareButton>

              <WhatsappShareButton url={url} title="" separator=" ">
                <WhatsappIcon size={50} borderRadius={20} />
              </WhatsappShareButton>

              <TwitterShareButton url={url} title="" via="" hashtags={["Dyeka"]} related={[]}>
                <TwitterIcon size={50} borderRadius={20} />
              </TwitterShareButton>

              <LinkedinShareButton url={url} title="" summary="" source="Dyeka">
                <LinkedinIcon size={50} borderRadius={20} />
              </LinkedinShareButton>

              <TelegramShareButton url={url} title="">
                <TelegramIcon size={50} borderRadius={20} />
              </TelegramShareButton>
            </div>
          </div>
        </ModalBox>
      )}

      {openModal && (
        <ModalBox close={() => setOpenModal(false)}>
          <div className="mid:w-full w-[390px] h-full rounded-[10px] bg-white p-[23px] font-avenir">
            <div className="w-full bg-[#F5F5F5] border-[#6B6968] border-dashed border rounded-[10px] h-[213px] p-3 flex !flex-col items-center mb-[23px]">
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M30.6166 8.23421H30.7538V4.8032C30.7538 3.63661 29.8617 2.74458 28.6951 2.74458L15.0407 2.74482C14.8348 2.74482 14.629 2.67613 14.4916 2.47029L13.2566 0.823347C12.8449 0.274536 12.2272 0 11.6096 0H3.99294C2.8266 0 1.93457 0.892033 1.93457 2.05862V8.23417H2.07171L30.6166 8.23421Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M32.1261 10.2932C31.7144 9.8815 31.1654 9.60693 30.6166 9.60693H2.07152C1.52246 9.60693 0.973678 9.88146 0.562005 10.2932C0.150338 10.7048 -0.0554985 11.3223 0.012946 11.8714L1.11078 23.3302H31.5769L32.6747 11.8708C32.7434 11.2533 32.5376 10.7045 32.1259 10.2928L32.1261 10.2932Z"
                  fill="#D9D9D9"
                />
                <path d="M1.79721 31.0838C1.93459 32.1132 2.82661 32.9365 3.85583 32.9365H28.8328C29.9306 32.9365 30.7539 32.1132 30.8914 31.0838L31.0972 28.8193L1.59131 28.8196L1.79721 31.0838Z" fill="#D9D9D9" />
                <path d="M1.45431 27.4479H31.2342L31.44 24.7031H1.24854L1.45431 27.4479Z" fill="#D9D9D9" />
              </svg>

              <label htmlFor="file" className={`${document?.name ? "text-d-blue" : "text-[#000]"} cursor-pointer w-full mt-[16px] mb-[8px] text-center text-[15px] font-[500]`}>
                {document?.name || "Drop your file here"}
              </label>

              <svg width="86" height="26" viewBox="0 0 86 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.5588 17.306C34.9038 17.306 32.2688 14.62 32.2688 10.982C32.2688 7.344 34.9038 4.658 38.5588 4.658C42.2138 4.658 44.8488 7.344 44.8488 10.982C44.8488 14.62 42.2138 17.306 38.5588 17.306ZM38.5588 15.98C41.3808 15.98 43.3188 13.753 43.3188 10.982C43.3188 8.211 41.3808 5.984 38.5588 5.984C35.7368 5.984 33.7988 8.211 33.7988 10.982C33.7988 13.753 35.7368 15.98 38.5588 15.98ZM46.6336 17V4.964H49.7106C52.0396 4.964 54.5046 5.304 54.5046 8.279C54.5046 10.03 53.3656 11.169 51.4956 11.458L54.9126 17H53.1616L49.8976 11.594H48.0616V17H46.6336ZM48.0616 6.29V10.268H49.3366C50.9176 10.268 52.9746 10.268 52.9746 8.279C52.9746 6.545 51.4616 6.29 50.0676 6.29H48.0616Z"
                  fill="#D9D9D9"
                />
                <line x1="59" y1="13.5" x2="86" y2="13.5" stroke="#D9D9D9" />
                <line y1="13.5" x2="27" y2="13.5" stroke="#D9D9D9" />
              </svg>

              <label htmlFor="file" className="cursor-pointer px-[26px] py-[7px] mt-[11px] rounded-[10px] border border-[#000] bg-white text-[#000] text-[17px] font-[600]">
                Browse Files
              </label>
              <input
                type="file"
                id="file"
                className="!hidden"
                // accept="application/msword, application/pdf, text/plain"
                onChange={(e) => {
                  if (e.target.files) {
                    setDocument(e.target.files[0]);
                  }
                }}
              />
            </div>

            <div className="w-full text-center text-black font-[600] text-[20px] mb-2">Upload Assignment</div>
            <div className="w-full text-center text-[#6B6968] text-[15px] border-b border-[#F5F5F5] mb-[23px] pb-4">Upload your assignment in .pdf or .doc file</div>
            <div className="mmd:!flex-col-reverse w-full flex justify-between gap-2 items-center">
              <button onClick={() => setOpenModal(false)} className="px-[22px] py-[7px] mmd:w-full rounded-[10px] border border-[#6B6968] text-[#6B6968] text-[17px] font-[600]">
                Cancel
              </button>

              <Button type="button" className="flex justify-center h-[41px] mmd:w-full items-center w-fit">
                Submit Document
              </Button>
            </div>
          </div>
        </ModalBox>
      )}

      <div>
        <div className="back-btn-cover">
          <Link to={`/app/course-modules`} className="text-primary-color">
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </Link>
        </div>

        {lesson ? (
          lesson?.bought && lesson?.price ? (
            <div>
              <div className={styles.heading}>
                <div className={styles.topic}>{lesson.name}</div>
                <div>
                  <span className={styles.tutor}>Instructor - </span>
                  {lesson.tutor.first_name} {lesson.tutor.last_name}
                </div>
              </div>
              <div className={styles.layout}>
                <div className={styles.videoSide}>
                  <div className={styles.playerCover}>
                    {selectedVideo ? (
                      <video ref={videoRef} onTimeUpdate={handleTimeUpdate} className={styles.videPlayer} src={selectedVideo?.original_url} controls controlsList="nodownload">
                        {selectedVideo && <source key={`video-file-${selectedVideo?.id}`} type="" src={selectedVideo?.original_url} data-width={selectedVideo?.meta_data?.width} data-height={selectedVideo?.meta_data?.height} />}
                      </video>
                    ) : (
                      <img width="100%" src={lesson.thumbnail} alt="thumbnail" />
                    )}
                  </div>
                  <div className="mt-20">
                    <div className="flex items-start mmd:flex-col justify-between w-full">
                      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
                        <Tab title="ABOUT">
                          <div className="mb-50">
                            <div className={styles.heading}>
                              <div className={styles.subHeading}>About This Class</div>
                            </div>
                            <div className="mt-10">{lesson.description}</div>
                          </div>
                        </Tab>
                        <Tab title="RESOURCES">
                          <div className="w-full mb-50">
                            {lesson.documents.length > 0 ? (
                              lesson.documents.map((doc, index: number) => (
                                <div key={`${lesson.id}-doc-${index}`} className="mt-30 flex w-full !items-center justify-content-between border !border-[#ff6c00] rounded-[10px] p-15 bg-[#fbf4ee]">
                                  <div className="text-primary">
                                    <div className={styles.resources}>
                                      <div>Book {index + 1} - </div>
                                      <div className="pl-5">
                                        <b>{doc.name}</b>
                                      </div>
                                    </div>
                                    {doc?.author && <div className="pl-5">Author- {doc?.author}</div>}
                                  </div>
                                  <a href={`${doc.url}#toolbar=0`} target="_blank" download className="text-primary-color" rel="noreferrer">
                                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M4.65862 9.27055C4.51811 9.58385 4.57396 9.95084 4.80131 10.2074L11.3468 17.5972C11.6784 17.9712 12.3216 17.9712 12.6532 17.5972L19.1987 10.2074C19.426 9.9504 19.4819 9.58342 19.3414 9.27055C19.2004 8.95767 18.8889 8.75607 18.5455 8.75607H14.6182V0.872727C14.6182 0.390982 14.2272 0 13.7455 0H10.2545C9.7728 0 9.38182 0.390982 9.38182 0.872727V8.75564H5.45455C5.11113 8.75607 4.79956 8.95767 4.65862 9.27055Z"
                                        fill="#15345B"
                                      />
                                      <path d="M0 20.9319C0 21.6549 0.586036 22.241 1.30909 22.241H22.6909C23.414 22.241 24 21.6549 24 20.9319C24 20.2088 23.414 19.6228 22.6909 19.6228H1.30909C0.586036 19.6228 0 20.2088 0 20.9319Z" fill="#15345B" />
                                    </svg>
                                  </a>
                                </div>
                              ))
                            ) : (
                              <div>No documents uploaded for this course</div>
                            )}
                          </div>
                        </Tab>
                      </Tabs>

                      <button onClick={() => setModalOpen(true)} className="mmd:mt-0 mmd:mb-5 mt-[10px] border-[#D9D9D9] px-[20px] py-[10px] border rounded-[7px] flex justify-center items-center text-[14px] font-semibold text-[#5f5f5f]">
                        <div className="flex items-center gap-[18px]">
                          <svg width="15" height="15" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.5472 6.89108C17.5211 6.89065 18.4493 6.47804 19.1023 5.75537C19.755 5.0327 20.0717 4.06757 19.9736 3.09856C19.8755 2.12967 19.372 1.24741 18.5875 0.67024C17.803 0.0931072 16.8109 -0.125162 15.8568 0.0696132C14.9024 0.264252 14.0752 0.853655 13.5796 1.69199C13.084 2.53047 12.9663 3.53942 13.2556 4.46925L6.2332 8.00563V8.00577C5.67937 7.24607 4.83945 6.74558 3.90788 6.61996C2.97617 6.49448 2.03368 6.75481 1.29862 7.34075C0.563554 7.9267 0.0996499 8.78738 0.0142308 9.72352C-0.0710593 10.6597 0.229615 11.5901 0.846662 12.2994C1.4637 13.0085 2.34356 13.4349 3.28247 13.4799C4.22153 13.5249 5.13809 13.1844 5.82015 12.5375L13.2294 15.6987C12.9138 16.8214 13.1868 18.0275 13.9551 18.9049C14.7234 19.7823 15.8829 20.212 17.0375 20.0474C18.192 19.8827 19.1852 19.1458 19.6775 18.0886C20.1699 17.0314 20.0947 15.797 19.4778 14.8074C18.861 13.8178 17.7858 13.2068 16.6198 13.1833C15.4539 13.1598 14.3549 13.727 13.6987 14.691L6.51032 11.623C6.93204 10.8123 7.01369 9.86707 6.73724 8.99599L13.7555 5.46193C14.4023 6.3595 15.441 6.89171 16.5473 6.89197L16.5472 6.89108Z"
                              fill="#5F5F5F"
                            />
                          </svg>

                          <span>Share</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className={`${styles.learnSide}`}>
                  <div className="">
                    <div className={styles.contentHeading}>Contents</div>
                    <div className="mt-4">
                      {lesson.videos.length > 0 ? (
                        lesson.videos.map((video, index: number) => <Outline type="videos" key={index} topic={video.title} active={selectedVideo && video.id === selectedVideo.id} duration={video.meta_data.duration} onClick={(e) => changeVideo(video)} />)
                      ) : (
                        <div>No videos uploaded for this course</div>
                      )}
                    </div>
                    <hr />
                    <div className={`${styles.contentHeading} mt-4`}>Test</div>
                    <div className="mt-4">{tests.length > 0 ? tests.map((test, index: number) => <TestOutline id={test.id} lessonId={lesson.id} type="tests" key={index} topic={test.title} />) : <div>No tests created for this course</div>}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <UnpaidClass lesson={lesson} styles={styles} selectedVideo={selectedVideo} changeVideo={changeVideo} setLoading={setLoading} />
          )
        ) : (
          <div className={styles.noClass}>
            <NoClass />
            <p className={["text-primary-color", styles.noClass__Title].join(" ")}>Your Class</p>
            <p className={["text-primary-color", styles.noClass__Body].join(" ")}>You do not have any class at this time.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MyClass;
