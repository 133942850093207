import React from "react";
import turnInImg from "../../../assets/img/school-material-writer-svgrepo-com 2.png";
import { Button } from "components";
import pdf_img from "../../../assets/img/file-pdf-svgrepo-com 1.png";
import { useNavigate, useParams } from "react-router-dom";

function WorkToDoId() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return (
    <div className="flex md:flex-row justify-between flex-col w-full font-avenir text-[#15345B]">
      <section className="w-full mb-24 md:mb-0 md:w-[405px] block">
        <div
          className={`flex cursor-pointer justify-center items-center text-[10px] sm:text-[12px]
          w-[200px] h-[48px] rounded-[12px] bg-[#15345B] text-[#FFFFFF]`}
        >
          CLASS WORK
        </div>

        <div className="py-[27px] border-b border-[#E3E3E3]">
          <div className="text-[11px] font-black mb-2">Due 11:35</div>
          <div className="text-[15px] mb-4 font-black uppercase">INTRODUCTION TO MATHEMATICS</div>
          <div className="text-[11px] font-black">20 points</div>
        </div>

        <div className="py-[20px] w-full">
          <div className="text-[11px] font-normal mb-[20px]">Attachments</div>

          <div className="gap-4">
            <img src={pdf_img} className="cursor-pointer" alt={"PDF_file"} height={42} width={35} />
          </div>
        </div>
      </section>

      <section className="flex w-full md:w-[307px] flex-col items-center">
        <div className="flex mb-[22px] w-full flex-col px-[90px] py-[11px] items-center md:border-b border-[#E3E3E3]">
          <img src={turnInImg} alt={"Turn-in your work"} height={61} width={56} />
          <p className="w-full text-center font-black text-[15px]">Turn-in your work</p>
        </div>

        <Button
          type="button"
          variant="block"
          size="medium"
          children={<div className="text-white text-[12px] font-black">Add Attachment</div>}
          onClick={() => {
            navigate(`/app/work-to-do/${id}/add-attachment`);
          }}
        />
      </section>
    </div>
  );
}

export default WorkToDoId;
