import React, { useCallback } from 'react';
import styles from './tab-title.module.scss';

type Props = {
  title: string;
  index: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  selectedTab,
  index,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li data-title={title !== ''}>
      <div
        onClick={onClick}
        className={[
          styles.button,
          index === selectedTab ? styles.active : '',
        ].join(' ')}
      >
        <div className="flex">
          <div>
            <div>{title}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default TabTitle;
