import React, { useEffect, useState } from "react";
import Barchart from "components/charts/Barchart";
import DoughnutChart from "components/charts/DoughnutChart";
import { BiChevronDown } from "react-icons/bi";
import bottom_image from "../../../assets/img/learning_analysis_bottom.png";
import Axios from "core/service/axios";
import { Loader } from "components";

function LearningAnalysis() {
  const [period, setPeriod] = useState("all");
  const [analysis, setAnalysis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    getAnalysis(period);
  }, [period]);

  const getAnalysis = (period) => {
    setLoading(true);
    Axios.get(`/users/my-learning/analysis?period=${period}`).then((res) => {
      setAnalysis(res.data?.analysis);
      setLoading(false);
    });
  };

  useEffect(() => {
    const keysArray = Object.keys(analysis);
    const valuesArray = Object.values(analysis);

    setLabels(keysArray);
    setValues(valuesArray);
  }, [analysis]);

  const periods = [
    { label: "All", value: "all" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
  ];

  return (
    <section>
      {loading ? (
        <Loader />
      ) : (
        <section className="flex h-fit mt-[20px] sm:mt-[30px] gap-x-[30px] font-avenir flex-col lg:flex-col">
          <div className="w-full flex flex-col h-full rounded-[14px] bg-white p-[30px]">
            <div className="w-full flex flex-row justify-between">
              <div className="text-[#191635] font-semibold text-[18px]">Studied Subjects</div>
              <div>
                <select
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  name="period"
                  id="period"
                  className="outline-none bg-[#F1F5FF] cursor-pointer flex justify-between items-center msm:w-fit w-[150px] rounded-[9px] px-[8px] h-[30px] ml-[10px] text-[#6C9EFF] text-[11px] font-semibold pr-[6px]"
                >
                  <option value="">Period</option>
                  {periods?.map((data, i) => {
                    return (
                      <option key={i} value={data?.value}>
                        {data?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="w-full h-fit block mt-14">
              <DoughnutChart percentage={values} label={labels} analysis={analysis} color={["#579AFF", "#62BDD3", "#FF6C00", "#FF447C", "#4CB050"]} />
            </div>
          </div>

          <div className="w-full mt-10 flex-grow h-full rounded-[14px] bg-white p-[30px]">
            <div className="flex justify-between h-fit items-center">
              <div className="text-[#191635] font-semibold text-[18px]">Subjects</div>
              <div className="p-[8px] text-[#7C7C82] ml-[12px] text-[12px]">Number of classes taken per month</div>
            </div>

            <div className="w-full block">
              <Barchart bgColor={["#579AFF", "#62BDD3", "#FF6C00", "#FF447C", "#4CB050", "#4CB3E0"]} xAxis={labels} yAxis={values} />
            </div>
          </div>
        </section>
      )}

      <div className="w-full">
        <img src={bottom_image} alt="" className="object-cover w-full h-fit" />
      </div>
    </section>
  );
}

export default LearningAnalysis;
