import { AxiosResponse } from "axios";
import { Button } from "components";
import WelcomeLayout from "components/layouts/welcome";
import UserContext from "context/user";
import { SwitchProfileApiService } from "core/service/profiles";
import { handleError } from "core/utils/error-handler";
import { SecureStorage } from "core/utils/storage";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  IProfile,
  UpdateIProfile,
  ISwitchProfileResponse,
} from "types/profiles";
import { IResponse } from "types/responses";
import styles from "./users.module.scss";
import AuthContext from "context/auth";

const Users = () => {
  const [edit, setEdit] = useState(false);
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const { setAuthAndCache } = useContext(AuthContext);
  const profile: UpdateIProfile = JSON.parse(selectedProfile);

  const { mutate, isLoading } = useMutation(SwitchProfileApiService, {
    onSuccess: (res: AxiosResponse<IResponse<ISwitchProfileResponse>>) => {
      const { success, data } = res.data;
      if (success) {
        setAuthAndCache(`${`Bearer`} ${data?.token}`);
        navigate("/app/home");
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response?.message);
    },
  });

  const setCurrentProfile = (value: string) => {
    const parsed = JSON.parse(value);
    secureStorage.storeItem("selectedProfile", value);
    mutate(parsed.id);
  };

  // useEffect(() => {
  //   !selectedProfile &&
  //     setCurrentProfile(JSON.stringify(currentUser.profiles[0]));
  // }, [selectedProfile]);

  return (
    <div>
      <WelcomeLayout spinner active={isLoading}>
        <div className={styles.grid}>
          {!currentUser?.profiles?.length
            ? null
            : currentUser.profiles.map(
              (item: IProfile | any, index: number) => (
                <Fragment key={`user=${index}`}>
                  {edit ? (
                    <div
                      className="flex justify-content-center"
                      style={{ maxWidth: "200px" }}
                      key={`user=${index}`}
                      onClick={() => setCurrentProfile(JSON.stringify(item))}
                    >
                      <div>
                        <div
                          className={
                            profile
                              ? item.id === profile.id
                                ? [
                                  styles.circle,
                                  "bg-grey selectedProfile",
                                ].join(" ")
                                : [styles.circle, "bg-grey"].join(" ")
                              : [styles.circle, "bg-grey"].join(" ")
                          }
                        >
                          <img
                            className={styles.img}
                            src={item.photo_url}
                            alt={"profile"}
                          />
                          {edit && (
                            <div
                              className={[
                                styles.circle,
                                styles.absolute,
                                "bg-dark",
                              ].join(" ")}
                            >
                              <div className="text-light">Edit Icon</div>
                            </div>
                          )}
                        </div>

                        <div className="text-dark">
                          <b>{`${item.first_name} ${item.last_name}`}</b>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex justify-content-center"
                      style={{ maxWidth: "200px" }}
                      key={`user=${index}`}
                      onClick={() => setCurrentProfile(JSON.stringify(item))}
                    >
                      <div>
                        <div
                          className={
                            profile
                              ? item.id === profile.id
                                ? [
                                  styles.circle,
                                  "bg-grey selectedProfile",
                                ].join(" ")
                                : [styles.circle, "bg-grey"].join(" ")
                              : [styles.circle, "bg-grey"].join(" ")
                          }
                        >
                          <img
                            className={styles.img}
                            src={item.photo_url}
                            alt="profile-img"
                          />
                        </div>

                        <div className="text-dark">
                          <b>{`${item.first_name} ${item.last_name}`}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )
            )}
          <Link
            to={"/app/add-profile"}
          >
            <div className="flex justify-content-center">
              <div>
                <div className={[styles.circle, "bg-grey"].join(" ")}>
                  <div
                    className="text-primary-color"
                    style={{
                      fontSize: "4em",
                      fontWeight: 900,
                    }}
                  >
                    +
                  </div>
                </div>
                <div className={[styles.name, "text-primary-color"].join(" ")}>
                  Add Profile
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="text-center mt-45">
          <Link to="/app/settings/profile">
            <Button className="mt-20" variant="grey">
              MANAGE PROFILES
            </Button>
          </Link>
        </div>
      </WelcomeLayout>
    </div>
  );
};

export default Users;
