import { CreateProfileIllustration } from "assets/svg";
import { AuthLayout } from "components";
import ChildForm from "pages/shared/child-form";
import { useSearchParams } from 'react-router-dom';
import React from "react";

const AddProfile = () => {
  const isOnboarding = useSearchParams()[0].get("type") === "onboarding";
  return (
    <AuthLayout
      welcomeText="Free trial for 7 days"
      intro="Your child is unique, let’s make it official"
      formDescription="Please fill the form with a child’s information."
      logoSideText={isOnboarding ? "Create a profile" : "Add profile"}
      illustration={CreateProfileIllustration}
    >
      <ChildForm />
    </AuthLayout>
  );
};

export default AddProfile;
