import { IProfile, ISwitchProfileRequest } from "types/profiles";
import Axios from "./axios";

export const GetProfilesApiService = () => {
  return Axios.get("/profiles");
};

export const AddProfileApiService = (credentials: IProfile) => {
  return Axios.post("/profiles", credentials);
};

export const SwitchProfileApiService = (id: ISwitchProfileRequest) => {
  return Axios.put(`/profiles/${id}`, {});
};

export const updateProfileApiService = (credentials: any) => {
  return Axios.patch(`/profiles/${credentials.id}`, {
    first_name: credentials.first_name,
    last_name: credentials.last_name,
    dob: credentials.dob,
    interests: credentials.interests,
    photo_url: credentials.photo_url,
    class_id: credentials.class_id
  });
};
