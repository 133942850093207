import "./subject.scss";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import MainLayoutContext from "../../../../context/main-layout";
import { useNavigate, useParams } from "react-router-dom";
import { SubjectData } from "../../../../types/subject";
import { Course } from "../../../../components";
import { Link } from "react-router-dom";
import { subjectAction } from "../../../../core/actions";
import TestMini from "../../../../components/test";
import { appHelpers } from "core/utils/helpers";
import CourseCard from "components/CourseCard";

const SubjectPage = ({ }) => {
  const navigate = useNavigate();
  const visible = useRef();
  const { updateHeading, setLoading, subjects } = useContext(MainLayoutContext);
  const { course_name } = useParams();
  const [subject, setSubject] = useState(null);

  const fetchSubject = useCallback(() =>
    async () => {
      setLoading(true);

      const subject = subjects[course_name];
      if (subject) setSubject(subject);

      setLoading(false);
    }, [subjects, course_name, setSubject, setLoading]
  )

  const goToLesson = async (id) => {
    navigate(`/app/lesson/${id}`);
  };

  useEffect(() => {
    visible.current = true;

    return () => {
      visible.current = false;
    };
  }, []);

  useEffect(() => {
    if (visible.current) fetchSubject().then();
  }, [fetchSubject, subjects, visible]);

  useEffect(() => {
    if (subject) {
      updateHeading({
        header: subject.description,
        subHeader: "",
      });
    }
  }, [subject]);

  return (
    <div className="subject-page-cover">
      <div className="back-btn-cover">
        <Link to={`/app/my-courses`} className="text-primary-color">
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </Link>
      </div>
      {subject && (
        <div className="subject-page">
          <p className="mt-20 mb-10 red-header">Lessons</p>
          <div className="row g-4">
            {subject?.lessons?.map((lesson) => (
              <div key={`lesson-${lesson.id}`} className="col-6 col-sm-3 col-md-4 col-lg-3">
                <CourseCard
                  PlayClick={() => goToLesson(lesson.id)}
                  bought={lesson?.bought}
                  period={lesson?.payment_period}
                  key={lesson?.id}
                  thumbnail={
                    lesson.thumbnail ||
                    lesson?.live_event?.thumbnail ||
                    lesson?.lesson?.thumbnail ||
                    (lesson?.tutor?.first_name &&
                      `${lesson?.tutor?.first_name} ${lesson?.tutor?.last_name}`)
                  }
                  tutorName={
                    lesson.tutor_name ||
                    lesson?.live_event?.tutor_name ||
                    lesson?.lesson?.tutor_name
                  }
                  description={appHelpers.truncateValue(
                    lesson?.description ||
                    lesson?.live_event?.description ||
                    lesson?.lesson?.description,
                    40
                  )}
                  live={true}
                  duration={
                    lesson?.duration ||
                    lesson?.preview_duration ||
                    lesson?.live_event?.preview_duration ||
                    lesson?.lesson?.preview_duration
                  }
                  price={
                    lesson?.price ||
                    lesson?.live_event?.price ||
                    lesson?.lesson?.price
                  }
                  startAge={""}
                  endAge={""}
                  subject={
                    lesson?.title ||
                    lesson?.name ||
                    lesson?.subject ||
                    lesson?.live_event?.subject ||
                    lesson?.lesson?.subject ||
                    lesson?.lesson?.name
                  }
                />
                {/* <Course
                  img={lesson.thumbnail}
                  title={lesson.name}
                  tutor={lesson.tutor_name}
                  category={
                    lesson.tags
                      ? lesson.tags?.length > 0
                        ? lesson.tags[0]
                        : ""
                      : ""
                  }
                  description={lesson.description}
                  PlayClick={() => goToLesson(lesson.id)}
                /> */}
              </div>
            ))}
          </div>
          <hr />
          <p className="mt-20 mb-10 red-header">Tests</p>
          <div className="row g-4">
            {subject.tests.map((test) => (
              <div
                key={`lesson-${test.id}`}
                className="col-6 col-sm-3 col-md-4 col-lg-3"
              >
                <TestMini test={test} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubjectPage;
