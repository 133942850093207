import React, { FC } from "react";

type ImageProps = {
  uploadedPhoto: string;
  photo_url?: any;
  handleImageChange: React.ChangeEventHandler<HTMLInputElement>;
};
const Image: FC<ImageProps> = ({
  photo_url,
  handleImageChange,
  uploadedPhoto,
}) => {
  return (
    <label htmlFor="contained-button-file">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 20,
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            height: 100,
            width: 100,
            borderRadius: 7,
            marginBottom: 20,

            background: "rgba(21, 52, 91, 0.26)",
          }}
        >
          <img
            src={uploadedPhoto || photo_url}
            alt="user-icon"
            style={{
              height: 100,
              width: 100,
              borderRadius: 7,
            }}
          />
        </div>

        <p
          style={{
            color: "rgba(var(--primary-rgba))",
            textDecoration: "underline",
          }}
        >
          Change Photo
        </p>

        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
      </div>
    </label>
  );
};

export default Image;
