import React, { useContext, useEffect, useState } from "react";
import MainLayoutContext from "context/main-layout";
import { appHelpers } from "core/utils/helpers";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../dashboard/dashboard.module.scss";
import { DashboardInitialResultData } from "types/dashboard";
import { BiChevronDown } from "react-icons/bi";
import { Course, Loader, UngoingCourse } from "components";
import MyLearningCard from "components/mylearning-card";
import favoriteImg from "../../../assets/img/favourite.png";
import livestreamImg from "../../../assets/img/livestream.png";
import medalImg from "../../../assets/img/medal.png";
import mediaImg from "../../../assets/img/media.png";
import studyImg from "../../../assets/img/study_group.png";
import workCaseImg from "../../../assets/img/work-case.png";
import { fakeData as values } from "../../../core/data";
import { MyLearningCardtype } from "types/card";
import Barchart from "components/charts/Barchart";
import DoughnutChart from "components/charts/DoughnutChart";
import MedalPage from "../medals/index";
import { useMutation } from "react-query";
import { handleError } from "core/utils/error-handler";
import { GetMyLearningApiService } from "core/service/mylearning";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import TestMini from "components/test";
import CourseCard from "components/CourseCard";
import PendingHomeworkCard from "components/PendingHomeworkCard";
import RecordingCard from "components/RecordingCard";

function MyLearning() {
  const { updateHeading } = useContext(MainLayoutContext);
  const navigate = useNavigate();
  const [myLearning, setMyLearning] = useState(null);
  const { hash } = useLocation();
  const medalPath = hash?.includes("medals");

  const cardsData = [
    {
      icon: workCaseImg,
      text: "Work to-do's",
      path: "/app/work-to-do",
      placeholder: "work-to-do",
    },
    {
      icon: mediaImg,
      text: "Media files",
      path: "/app/media-files",
      placeholder: "media-files",
    },
    {
      icon: livestreamImg,
      text: "Livestream Videos",
      path: "/app/live-streaming",
      placeholder: "livestream-videos",
    },
    {
      icon: studyImg,
      text: "My Study Group",
      path: "/app/my-study-group",
      placeholder: "my-study-group",
    },
    {
      icon: medalImg,
      text: "Medals/Certificates",
      path: "/app/my-learning#medals",
      placeholder: "medals",
    },
    {
      icon: favoriteImg,
      text: "My Favourites",
      path: "/app/my-favourites",
      placeholder: "my-favourites",
    },
  ];

  const { mutate } = useMutation(GetMyLearningApiService, {
    onSuccess: (res) => {
      const { success, message, data } = res.data;
      if (message === "Successful") {
        setMyLearning(res.data);
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response.message);
    },
  });

  useEffect(() => {
    updateHeading({
      header: "Take a look at your courses",
      subHeader: "The courses here are all the available ones for your profile level.",
    });
    mutate();
  }, []);

  const goToLesson = async (id) => {
    navigate(`/app/lesson/${id}`);
  };

  const handleSeeAll = (value) => {
    // value?.description?.includes("Liked")
    //   ? history.push(`/app/liked-videos`) :
    navigate(`/app/course/${appHelpers.removeCommaAndDotAndSpace(value?.description)?.toLocaleLowerCase()}`, {
      state: {
        ...value,
      },
    });
  };

  // console.log(myLearning);

  const renderCourseVideos = (myLearning) => {
    const courseElems = [];
    for (const [key, value] of Object.entries(myLearning)) {
      // console.log(value);
      courseElems.push(
        <div key={`item-${key}-`}>
          {!value?.data ? null : (
            <>
              <div className="flex justify-content-between mt-20 mb-10">
                <div className="red-header">
                  <b>{appHelpers.removeCommaAndDot(value?.description)}</b>
                </div>
                <small className="text-primary-color cursor-pointer" onClick={() => handleSeeAll(value)}>
                  See All
                </small>
              </div>
              {value?.description?.includes("Medal") ? (
                <div className={styles.column_2}>
                  <MedalPage medal={value.data.medals} certificates={value.data.certificates} />
                </div>
              ) : value?.description?.includes("Recordings") ? (
                <div className={styles.column_4}>
                  {value?.data?.map((data) => (
                    <RecordingCard key={`lesson-${data.id}`} data={data} />
                  ))}
                </div>
              ) : value?.description?.includes("Homework") ? (
                <>
                  {/* <div className={styles.column_3}>
                    {value?.data?.map((test) => (
                      <TestMini key={`lesson-${test.id}`} test={test} />
                    ))}
                  </div> */}
                  <div className={styles.column_4}>
                    {value?.data?.map((work) => (
                      <PendingHomeworkCard key={`lesson-${work.id}`} work={work} />
                    ))}
                  </div>
                </>
              ) : value?.type === "videos" ? (
                <div className={styles.column_4}>
                  {Array.isArray(value?.data) &&
                    value?.data
                      ?.slice(0, 3)
                      ?.map((item, index) => (
                        <UngoingCourse img={item.thumbnail} key={index} percentage={item?.video_history?.percentage_watched} title={item.title} description={appHelpers.truncateValue(item?.description)} subject={item.subject} tutor={item.tutor_name} PlayClick={() => goToLesson(item.id)} />
                      ))}
                </div>
              ) : (
                <div className={styles.column_4}>
                  {Array.isArray(value?.data) &&
                    value?.data.slice(0, 4).map((item, index) => (
                      <CourseCard
                        PlayClick={() => goToLesson(item.id || item?.live_event?.id || item?.lesson?.id)}
                        bought={item?.bought}
                        period={item?.payment_period}
                        key={index}
                        thumbnail={item.thumbnail || item?.live_event?.thumbnail || item?.lesson?.thumbnail}
                        tutorName={item.tutor_name || item?.live_event?.tutor_name || item?.lesson?.tutor_name || (item?.tutor?.first_name && `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)}
                        description={appHelpers.truncateValue(item?.description || item?.live_event?.description || item?.lesson?.description, 45)}
                        live={true}
                        duration={item?.duration || item?.preview_duration || item?.live_event?.preview_duration || item?.lesson?.preview_duration}
                        price={item?.price || item?.live_event?.price || item?.lesson?.price}
                        startAge={""}
                        endAge={""}
                        subject={item?.title || item?.name || item?.subject || item?.live_event?.subject || item?.lesson?.subject || item?.lesson?.name}
                      />
                      // <Course
                      //   img={
                      //     item.thumbnail ||
                      //     item?.live_event?.thumbnail ||
                      //     item?.lesson?.thumbnail
                      //   }
                      //   key={index}
                      //   time={`${Math.floor(
                      //     (item?.preview_duration ||
                      //       item?.live_event?.preview_duration ||
                      //       item?.lesson?.preview_duration) / 60
                      //   )} mins`}
                      //   title={
                      //     item.name ||
                      //     item.title ||
                      //     item?.live_event?.title ||
                      //     item?.lesson?.name
                      //   }
                      //   description={appHelpers.truncateValue(
                      //     item?.description ||
                      //       item?.live_event?.description ||
                      //       item?.lesson?.description
                      //   )}
                      //   subject={
                      //     item.subject ||
                      //     item?.live_event?.subject ||
                      //     item?.lesson?.subject
                      //   }
                      //   tutor={
                      //     item.tutor_name ||
                      //     item?.live_event?.tutor_name ||
                      //     item?.lesson?.tutor_name
                      //   }
                      //   category={
                      //     item.name ||
                      //     item?.live_event?.name ||
                      //     item?.lesson?.name
                      //   }
                      //   noOfVideos={
                      //     item?.no_of_videos ||
                      //     item?.live_event?.no_of_videos ||
                      //     item?.lesson?.no_of_videos
                      //   }
                      //   duration={
                      //     item?.preview_duration ||
                      //     item?.live_event?.preview_duration ||
                      //     item?.lesson?.preview_duration
                      //   }
                      //   PlayClick={() =>
                      //     goToLesson(
                      //       item.id ||
                      //         item?.live_event?.id ||
                      //         item?.lesson?.id
                      //     )
                      //   }
                      // />
                    ))}
                </div>
              )}{" "}
            </>
          )}
        </div>
      );
    }
    return courseElems;
  };

  return (
    <div className={styles.layout}>
      <>
        {!myLearning && <Loader />}
        {myLearning && renderCourseVideos(myLearning)}
      </>

      {/* <section className="flex mt-[20px] sm:mt-[30px] gap-x-[30px] font-avenir flex-col lg:flex-row justify-between items-center">
        <div className="w-full lg:w-auto lg:max-w-[425px] mb-[30px] lg:mb-0 flex-grow h-fit lg:h-[330px] rounded-[14px] bg-white p-[30px]">
          <div className="flex justify-between h-fit items-center mb-4">
            <div className="text-[#191635] font-semibold text-[18px]">
              Studied Subjects
            </div>
            <div className="bg-[#F1F5FF] cursor-pointer flex justify-between items-center w-fit rounded-[9px] px-[8px] h-[30px] ml-[10px]">
              <div className="text-[#6C9EFF] text-[11px] font-semibold pr-[6px]">
                Week
              </div>
              <BiChevronDown className="text-[#6C9EFF] text-[15px]" />
            </div>
          </div>

          <div className="w-full block">
            <DoughnutChart />
          </div>
        </div>

        <div className="w-full lg:w-auto lg:max-w-[558px] flex-grow h-fit lg:h-[330px] rounded-[14px] bg-white p-[30px]">
          <div className="flex justify-between h-fit items-center">
            <div className="text-[#191635] font-semibold text-[18px]">
              Subjects
            </div>
            <div className="p-[8px] text-[#7C7C82] ml-[12px] text-[12px]">
              Number of classes taken per month
            </div>
          </div>

          <div className="w-full block">
            <Barchart
              bgColor={["#579AFF", "#62BDD3", "#FF6C00", "#FF447C", "#4CB050"]}
              xAxis={["Language", "Literacy", "Science", "Art", "Writing"]}
              yAxis={["150", "275", "500", "200", "326"]}
            />
          </div>
        </div>
      </section>

      <section className="flex flex-wrap mt-[20px] sm:mt-[30px] items-start">
        {medalPath ? (
          <MedalPage />
        ) : (
          cardsData?.map((data: MyLearningCardtype) => (
            <MyLearningCard
              icon={data?.icon}
              text={data?.text}
              path={data?.path}
              placeholder={data?.placeholder}
            />
          ))
        )}
      </section> */}
    </div>
  );
}

export default MyLearning;
