import { IResponse } from "../../types/responses";
import { DataResult } from "../../types/data-result";
import { get, getById, getList, post } from "../service/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  classSubjects: async (id: String, page: number = 1) => await get({ url: `subjects`, filters: { class_id: id }, page }),
  fetchAll: async (page: number = 1) => await getList({ url: `subjects`, page }),
  fetch: async (id: number) => await getById({ url: `subjects`, id }),
  tests: async (id: number, page: number = 1) => await get({ url: `subjects/${id}/tests`, page }),
  lessons: async (id: number, page: number = 1) => await get({ url: `lessons`, filters: { subject_id: id }, page }),
  classLessons: async (subject_id: number, class_id: number, page: number = 1) => await get({ url: `lessons`, filters: { subject_id, class_id }, page }),
  submitTest: async (id: number, testId: number, answers: { answers: number[] }) => await post({ url: `subjects/${id}/${testId}/test`, data: answers }),
};
