/* eslint-disable import/no-anonymous-default-export */
import { IResponse } from "../../types/responses";
import { DataResult } from "../../types/data-result";
import { getById, getList } from "../service/api";

export default {
  classLessons: async (class_id: any): Promise<IResponse<DataResult> | undefined> => await getList({ url: "lessons", filters: { class_id } }),
  explore: async (): Promise<IResponse<DataResult> | undefined> => await getList({ url: "lessons/explore" }),
  viewed: async () => await getList({ url: `lessons/viewed` }),
  fetch: async (id: number): Promise<IResponse | undefined> => await getById({ url: "lessons/", id }),
  video: async (lessonId: number, id: number): Promise<IResponse | undefined> => await getById({ url: `lessons/${lessonId}`, id }),
  tests: async (lessonId: number): Promise<IResponse | undefined> => await getList({ url: `lessons/${lessonId}/tests` }),
  test: async (lessonId: string, testId: number): Promise<IResponse | undefined> => await getById({ url: `lessons/${lessonId}/test`, id: testId }),
};
