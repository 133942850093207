import './livestream-members.scss'
import {LivestreamMemberData} from "../../../types/livestream";
import LivestreamMember from "./member";

type Props = {
  members: LivestreamMemberData[]
}

const LivestreamMembers = ({ members = [] }: Props) => {


  return (
    <div className="livestream-members">
      <h4 className="side-section-header">Attendees</h4>
      <div className="my-5 members">
        {
          members.map(member => (
            <LivestreamMember key={member.id} member={member}/>
          ))
        }
      </div>
    </div>
  )
}

export default LivestreamMembers