import React from "react";

function FilterBox({ title, type, options, value, setValue, name }) {
  return type === "select" ? (
    <select
      value={value}
      onChange={(e) => setValue(e.target.value)}
      name={name}
      id={name}
      className="outline-none bg-transparent rounded-[10px] border border-[#E0E0E0] text-[#212121] text-[18px] font-avenir font-[600] py-[20px] px-[25px] w-full"
    >
      <option value="">{title}</option>
      {options?.map((data,i) => {
        return (
          <option  key={i}  value={data?.id || data?.value}>
            {data?.name || data?.label}
          </option>
        );
      })}
    </select>
  ) : (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      name={name}
      placeholder={title}
      id={name}
      className="outline-none bg-transparent rounded-[10px] border border-[#E0E0E0] placeholder:text-[#212121] text-[#212121] text-[18px] font-avenir font-[600] py-[15px] px-[25px] w-full"
    />
  );
}

export default FilterBox;
