import Login from "pages/auth/login";
import Signup from "pages/auth/signup";
import ForgotPassword from "pages/auth/forgot-password";
import { ErrorBoundary } from "..";
import { Navigate, Outlet } from "react-router-dom";
import { AuthLayout } from "components/layouts";
import { ForgotPasswordIllustration } from "assets/svg";
import GuardRoute from "../guard";


export const AuthRoutes = {
  path: "auth",
  element: <>
    <GuardRoute>
      <Outlet />
    </GuardRoute>
  </>,
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate to="login" />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "*",
      element: <AuthLayout color="red" welcomeText="Welcome back" intro="This is an error page" formDescription=" " logoSideText="" navigationLabel="Take me home?" navigationName="Click me" navigateTo="/" illustration={ForgotPasswordIllustration} />,
    },
  ],
};
