import { TestIcon, Play } from "assets/svg";
import React, { FC, MouseEventHandler } from "react";
import styles from "./outline.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  lessonId: number;
  topic: string;
  type: string;
};

type TestNavProps = {
  id: number;
  lessonId: number;
};

const TestOutline: FC<Props> = ({ topic, type, id, lessonId }) => {
  const navigate = useNavigate();

  const navigateToTest = (lessonId: number, id: number) => {
    const url = `/app/course-modules/${lessonId}/test/${id}`;
    navigate(url);
  };

  return (
    <div className={[styles.badge, "text-center mb-15"].join(" ")} onClick={() => navigateToTest(lessonId, id)}>
      <div className="flex justify-content-start align-items-center">
        <div>
          <TestIcon />
        </div>
        <div className="truncate ml-4">{topic}</div>
      </div>
    </div>
  );
};

export default TestOutline;
