import ProgressBar from "components/progress-bar";
import { PlaySmall } from "assets/svg";
import React, { FC } from "react";
import { truncate } from "lodash";

type Props = {
  percentage?: number;
  img?: string;
  description?: string;
  subject?: string;
  title?: string;
  tutor?: string;
  PlayClick?: () => void;
};

const UngoingCourse: FC<Props> = ({ percentage, img, title, tutor, subject, PlayClick }) => {
  return (
    <div className="mb-3 max-w-[362px] h-[143px] bg-white shadow-md rounded-[10px] p-[12px] flex gap-[16px]">
      <div className="w-[134px] relative h-full rounded-[10px]">
        <img src={img} className="w-full rounded-[10px] h-full object-cover" alt="lesson-thumbnail" />
        <div onClick={PlayClick} className="flex justify-center items-center h-full w-full rounded-[50%] cursor-pointer absolute top-0 left-0 right-0 bottom-0 m-auto">
          <PlaySmall />
        </div>
      </div>

      <div className="w-full flex flex-col gap-[16px]">
        <p className="w-full h-[57px] font-avenir text-[18px] leading-[22px] text-d-blue">{truncate(title)}</p>
        <ProgressBar percentage={percentage} />
        <div className="flex justify-between itmes-center w-full">
          <p className="text-[12px] font-avenir text-[#D21C28] opacity-50">{tutor}</p>
          <p className="text-[12px] font-avenir text-[#000] opacity-50">{percentage}%</p>
        </div>
      </div>
    </div>
  );
};
export default UngoingCourse;
