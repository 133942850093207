import { Eye, ForgotPasswordIllustration } from "assets/svg";
import { AuthLayout, Button } from "components";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./forgot-password.module.scss";
import AuthContext from "../../../context/auth";
import { userAction } from "../../../core/actions";
import useForm from "../../../core/utils/use-form";
import { IResetPassword } from "../../../types/user";
import { toast } from "react-toastify";
import { isValid } from "../../../utils";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const { loading, setLoading } = useContext(AuthContext);

  const togglePassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const togglePassword1 = () => {
    if (type1 === "password") {
      setType1("text");
    } else {
      setType1("password");
    }
  };

  const resetPassword = async () => {
    setLoading(true);

    const res = await userAction.resetPassword({
      email: data.email,
      otp: data.otp,
      new_password: data.new_password,
    });

    if (res?.success) {
      toast.success("Password reset successfully");
      navigate("/auth/login");
    }

    setLoading(false);
  };

  const sendOtp = async () => {
    setLoading(true);

    const res = await userAction.sendOtp(data.email);
    if (res?.success) toast.success("OTP sent successfully");

    setLoading(false);
  };

  const {
    inputs: data,
    errors,
    handleChange,
    handleSubmit,
  } = useForm<IResetPassword>(
    { email: "", otp: "", new_password: "", confirm_password: "" },
    resetPassword,
    true
  );

  return (
    <AuthLayout
      color="red"
      welcomeText="We’ll get you back in."
      intro="Forgot your password? No need to worry!"
      formDescription=" "
      logoSideText="Reset Password"
      navigationLabel="Remember password?"
      navigationName=" Login"
      navigateTo="/auth/login"
      illustration={ForgotPasswordIllustration}
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.grid}>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              maxLength={50}
              name="email"
              onChange={handleChange}
              data-validation="email, notNull"
            />
            {errors.email && <small className="error">{errors.email}</small>}
          </div>
          <div className="mb-15 flex align-items-bottom">
            <Button
              type="button"
              className="w-100"
              disabled={!data.email || data.email === ""}
              onClick={sendOtp}
            >
              SEND OTP TO EMAIL
            </Button>
          </div>
        </div>
        <div className="form-group">
          <label>
            OTP <span>(Check your email)</span>
          </label>
          <input
            type="text"
            name="otp"
            onChange={handleChange}
            data-validation="notNull"
          />
          {errors.otp && <small className="error">{errors.otp}</small>}
        </div>
        <div className="form-group password">
          <label>New Password</label>
          <input
            type={type}
            name="new_password"
            onChange={handleChange}
            data-validation="notNull"
          />
          <div className="show-password" onClick={togglePassword}>
            <Eye />
          </div>
          {errors.new_password && (
            <small className="error">{errors.new_password}</small>
          )}
        </div>
        <div className="form-group password">
          <label>Re-enter Password</label>
          <input type={type1} name="confirm_password" onChange={handleChange} />
          <div className="show-password" onClick={togglePassword1}>
            <Eye />
          </div>
        </div>
        <div className="form-group">
          <label className="flex">
            <input type="checkbox" className="mr-10" />
            <p className="mt-5 mb-0">Remember me</p>
          </label>
        </div>
        <div className="mt-40">
          <Button type="submit" disabled={isValid(data) || !isValid(errors)}>
            RESET PASSWORD
          </Button>

          <div className="mt-30">
            <span
              style={{ cursor: "pointer" }}
              className="text-primary-color font-medium text-[16px]"
              onClick={() => navigate(-1)}
            >
              Back
              {/* <i className="fa fa-chevron-left" aria-hidden="true"></i>
              <i className="fa fa-chevron-left" aria-hidden="true"></i> */}
            </span>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
