import { Course, UngoingCourse } from "components";
import MainLayoutContext from "context/main-layout";
import { appHelpers } from "core/utils/helpers";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./courses-type.module.scss";
import TestMini from "components/test";
import CourseCard from "components/CourseCard";

const CourseType = () => {
  const { updateHeading } = useContext(MainLayoutContext);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      updateHeading({
        header: appHelpers.removeCommaAndDot(location.state.description),
        subHeader: `${appHelpers.removeCommaAndDot(
          location.state.description
        )} courses`,
      });
    }
  }, [location.state, updateHeading]);

  const handlePlayClick = (item) => {
    navigate(
      `/app/lesson/${item.id || item?.live_event?.id || item?.lesson?.id}`
    );
  };
  return (
    <div className={styles.layout}>
      <div
        className={
          location.state.type === "videos" ? styles.column_3 : styles.column_4
        }
      >
        {location.state.description?.includes("Homework")
          ? location.state.data?.map((test) => (
            <TestMini test={test} key={`lesson-${test.id}`} />
          ))
          : location.state.data.map((item, index) =>
            location.state.type === "videos" ? (
              <UngoingCourse
                img={item.thumbnail}
                key={index}
                percentage={item?.video_history?.percentage_watched}
                title={item.title}
                description={appHelpers.truncateValue(item?.description)}
                subject={item.subject}
                tutor={item.tutor_name}
                PlayClick={() => handlePlayClick(item)}
              />
            ) : (
              <CourseCard
                key={index}
                period={item?.payment_period}
                PlayClick={() => handlePlayClick(item)}
                bought={item?.bought}
                thumbnail={
                  item.thumbnail ||
                  item?.live_event?.thumbnail ||
                  item?.lesson?.thumbnail ||
                  (item?.tutor?.first_name &&
                    `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)
                }
                tutorName={
                  item.tutor_name ||
                  item?.live_event?.tutor_name ||
                  item?.lesson?.tutor_name
                }
                description={appHelpers.truncateValue(
                  item?.description ||
                  item?.live_event?.description ||
                  item?.lesson?.description,
                  40
                )}
                live={true}
                duration={
                  item?.duration ||
                  item?.preview_duration ||
                  item?.live_event?.preview_duration ||
                  item?.lesson?.preview_duration
                }
                price={
                  item?.price ||
                  item?.live_event?.price ||
                  item?.lesson?.price
                }
                startAge={""}
                endAge={""}
                subject={
                  item?.title ||
                  item?.name ||
                  item?.subject ||
                  item?.live_event?.subject ||
                  item?.lesson?.subject ||
                  item?.lesson?.name
                }
              />
              // <Course
              //   img={
              //     item.thumbnail ||
              //     item?.live_event?.thumbnail ||
              //     item?.lesson?.thumbnail
              //   }
              //   key={index}
              //   time={`${Math.floor(
              //     (item?.preview_duration ||
              //       item?.live_event?.preview_duration ||
              //       item?.lesson?.preview_duration) / 60
              //   )} mins`}
              //   title={
              //     item.name ||
              //     item.title ||
              //     item?.live_event?.title ||
              //     item?.lesson?.name
              //   }
              //   description={appHelpers.truncateValue(
              //     item?.description ||
              //       item?.live_event?.description ||
              //       item?.lesson?.description
              //   )}
              //   subject={
              //     item.subject ||
              //     item?.live_event?.subject ||
              //     item?.lesson?.subject
              //   }
              //   tutor={
              //     item.tutor_name ||
              //     item?.live_event?.tutor_name ||
              //     item?.lesson?.tutor_name
              //   }
              //   category={
              //     item.name || item?.live_event?.name || item?.lesson?.name
              //   }
              //   noOfVideos={
              //     item?.no_of_videos ||
              //     item?.live_event?.no_of_videos ||
              //     item?.lesson?.no_of_videos
              //   }
              //   duration={
              //     item?.preview_duration ||
              //     item?.live_event?.preview_duration ||
              //     item?.lesson?.preview_duration
              //   }
              //   PlayClick={() => handlePlayClick(item)}
              // />
            )
          )}
      </div>
    </div>
  );
};

export default CourseType;
