import React, { useContext, useEffect, useState } from "react";
// import styles from "./payment.module.scss";
import { Button, Loader } from "components";
import formatToCurrency from "config/formatAmount";
import UserContext from "context/user";
import { usePaystackPayment } from "react-paystack";
import Axios from "core/service/axios";
import { toast } from "react-toastify";
import { DeleteIcon } from "assets/svg";
import { useNavigate } from "react-router-dom";

function Wallet({ price, id, type, balance, setBalance }) {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(true);
  const [card_id, setCardId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const publicKey = process.env.REACT_APP_ENV === "prod" ? "pk_live_c8fd0ef41657ab03934d2472d2ad00c169a83229" : "pk_test_bfb3f4f0268973067e31fe5d12b42cf0389f336a";
  const { currentUser } = useContext(UserContext);

  const [cards, setCards] = useState([]);

  useEffect(() => {
    getAllCards();
    getWalletBalance();
  }, []);

  const getAllCards = () => {
    Axios.get("/cards").then((res) => setCards(res.data.data.result));
  };

  const getWalletBalance = () => {
    Axios.get("/wallet").then((res) => setBalance(res.data?.wallet?.balance));
  };

  const handleWalletFund = () => {
    setLoading(true);

    if (!amount) {
      setLoading(false);
      toast.error("Please enter an amount");
    } else {
      Axios.post(`/wallet/fund`, {
        amount: amount,
      }).then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          toast.success("Redirecting");
          window.location.href = res?.data?.data?.authorization_url;
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  // console.log(balance);

  return (
    <>
      <div className="text-[#D21C28] font-avenir font-[700] mlg:text-[22px] text-[26px] mb-[35px] mlg:mb-[30px]">Dyeka Wallet</div>

      <div className={`w-full h-[246px] rounded-[8px] flex !flex-col !items-center !justify-center gap-[10px] mlg:gap-[8px] bg-d-orange font-avenir mlg:mb-[36px] mb-[40px] mlg:p-10 p-12`}>
        <div className="text-[20px] mlg:text-[17px] font-avenir  text-[#F9F9F9] uppercase">WALLET BALANCE</div>
        <div className="flex mlg:gap-3 gap-4 font-avenir items-center">
          <div className="font-avenir font-[700] mlg:text-[28px] text-[40px] text-white">&#8358; {show ? formatToCurrency(balance || 0, true) : "****.**"}</div>
          <svg width="33" height="34" onClick={() => setShow(!show)} viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.0426 17.0708C13.0426 18.0115 13.4163 18.9136 14.0814 19.5787C14.7465 20.2438 15.6486 20.6175 16.5893 20.6175C17.5299 20.6175 18.432 20.2438 19.0971 19.5787C19.7622 18.9136 20.1359 18.0115 20.1359 17.0708C20.1359 16.1302 19.7622 15.2281 19.0971 14.563C18.432 13.8978 17.5299 13.5242 16.5893 13.5242C15.6486 13.5242 14.7465 13.8978 14.0814 14.563C13.4163 15.2281 13.0426 16.1302 13.0426 17.0708ZM30.3388 16.2538C27.3369 9.93004 22.7991 6.74756 16.7159 6.74756C10.6296 6.74756 6.095 9.93004 3.09302 16.257C2.9726 16.512 2.91016 16.7904 2.91016 17.0724C2.91016 17.3544 2.9726 17.6329 3.09302 17.8878C6.095 24.2116 10.6328 27.3941 16.7159 27.3941C22.8022 27.3941 27.3369 24.2116 30.3388 17.8847C30.5827 17.3717 30.5827 16.7763 30.3388 16.2538ZM16.5893 22.6441C13.5113 22.6441 11.016 20.1488 11.016 17.0708C11.016 13.9928 13.5113 11.4975 16.5893 11.4975C19.6672 11.4975 22.1626 13.9928 22.1626 17.0708C22.1626 20.1488 19.6672 22.6441 16.5893 22.6441Z"
              fill="white"
              fill-opacity="0.5"
            />
          </svg>
        </div>
      </div>

      <div className="flex justify-between items-center w-full mb-[50px]">
        <div className="text-[#D21C28] font-[600] text-[18px] mlg:text-[16px]">Add money to your wallet</div>
        <div className="flex items-center gap-3"></div>
      </div>

      <div className="flex gap-[8px] !justify-start !items-start !flex-col">
        <label htmlFor="amount" className="font-[600] mlg:text-[16px] text-[18px] !text-left text-d-blue">
          Amount to add
        </label>
        <input value={amount} name="amount" id="amount" placeholder="10,000" onChange={(e) => setAmount(e.target.value)} className="w-full outline-none border h-[60px] font-[350px] text-[16px] text-[#999999] mlg:py-[18px] mlg:px-[20px] px-[26px] py-[20px] mb-[56px]" />
      </div>

      <div className="mt-[50px] flex justify-center w-full">
        <Button type="button" className="w-fit" onClick={() => handleWalletFund()}>
          {loading ? <Loader /> : "Fund Wallet"}
        </Button>
      </div>
    </>
  );
}

export default Wallet;
