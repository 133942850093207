import "./take-test.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { TestData, TestQuestionsData } from "../../../types/test";
import { subjectAction, lessonAction } from "../../../core/actions";
import MainLayoutContext from "../../../context/main-layout";
import Question from "../../../components/question";
import { produce } from "immer";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { SubjectData } from "../../../types/subject";
import { Loading } from "../../../assets/svg";

const TakeTest = () => {
  type Params = Record<string, string>;
  const navigate = useNavigate();
  const { course_name, test_id: testIdStr } = useParams<Params>();

  const { subjects, setLoading, loading } = useContext(MainLayoutContext);
  const [started, setStarted] = useState(false);
  const [subject, setSubject] = useState<SubjectData>();
  const [test, setTest] = useState<TestData>();

  const fetchTest = async () => {
    if (!course_name) return;
    if (testIdStr) {
      const test_id: number = parseInt(testIdStr, 10);
      const res = await lessonAction.test(course_name, test_id);
      if (res?.success) {
        setTest(res.data.result);
      }
    }
  };

  const updateQuestions = async (id: number, question: TestQuestionsData) => {
    setTest((test) =>
      produce(test, (data) => {
        if (data) data.questions[id] = question;
      })
    );
  };

  const goBack = () => {
    navigate(`/app/lesson/${course_name}`);
  };

  const submitTest = async () => {
    setLoading(true);

    if (test) {
      const options = [];

      for (const question of test.questions) {
        const marked = question.options.find((option) => option.marked);

        if (!question.optional && !marked) {
          toast.error("please answer all required questions");
          setLoading(false);
          return;
        }

        if (marked) options.push(marked.id);
      }

      const res = await subjectAction.submitTest(test.subject_id, test.id, {
        answers: options,
      });

      if (res) {
        if (res.success) {
          // todo fetch updated test
          toast.success("Test submitted");
          setStarted(false);
        } else toast.error(res.message);
      }
    } else toast.error("error submitting test");

    setLoading(false);
  };

  const calculateScorePercentage = (correctAnswers: number) => {
    const noOfQuestions: number | undefined = test?.questions.length;
    if (noOfQuestions) return (correctAnswers / noOfQuestions) * 100;
  };

  useEffect(() => {
    fetchTest().then();
  }, [subjects]);

  return (
    <div className="take-test">
      <div className="container">
        <div className="back-btn-cover"></div>
        {test && (
          <div className="test-content">
            <div className="col-md-7 mx-auto text-center">
              <div className="details">
                <h4 className="title">{test.title}</h4>
                <p className="instruction-txt">Instruction</p>
                <p className="instructions">{test.instructions}</p>
                <div className="stats d-flex justify-content-center">
                  {test?.pass_mark && (
                    <div className="stat">
                      <p className="key">Passmark</p>
                      <h5 className="value">{test.pass_mark}%</h5>
                    </div>
                  )}

                  {test?.no_of_retries && (
                    <div className="stat">
                      <p className="key">Number of retries</p>
                      <h5 className="value">{test.no_of_retries}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {started ? (
              <div className="start-test">
                <div className="questions">
                  {test.questions.map((question, no) => (
                    <Question key={`question-${question.id}`} question={question} no={no} updateQuestion={updateQuestions} />
                  ))}
                </div>
                <div className="action-btn">
                  <button className="btn bg-primary" onClick={submitTest}>
                    SUBMIT
                  </button>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="action-btn d-flex justify-content-center">
                  <button className="btn bg-primary mx-2" onClick={goBack}>
                    BACK
                  </button>
                  {test?.test_attempts?.length < test.no_of_retries ? (
                    <button className="btn bg-primary mx-2" onClick={() => setStarted(true)}>
                      START
                    </button>
                  ) : (
                    <p>You have exhausted the maximum number of retries</p>
                  )}
                </div>
                <div className="past-result mt-50">
                  <hr />
                  <p className="mt-20 mb-10 red-header">Past result</p>
                  <div className="attempts row gy-3">
                    {test?.test_attempts.map((attempt) => (
                      <div key={`attempt-${attempt.id}`} className="col-md-3">
                        <div className="attempt">
                          <div className="info">
                            <p className="key">Score</p>
                            <p className="value">{calculateScorePercentage(attempt.score)}%</p>
                          </div>
                          <div className="info">
                            <p className="key">Date</p>
                            <p className="value">{`${DateTime.fromISO(attempt.created_on).toISODate()} ${DateTime.fromISO(attempt.created_on).toISOTime()}`}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div className="loading-cover">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default TakeTest;
