import { Course } from "components";
import profile from "../../../assets/img/profile-avatar.png";
import { useContext, useEffect } from "react";
import styles from "./downloads.module.scss";
import MainLayoutContext from "context/main-layout";

const Downloads = () => {
  const { updateHeading } = useContext(MainLayoutContext);
  useEffect(() => {
    updateHeading({
      header: "All Your Downloads",
      subHeader: "The courses here are all your downloaded videos.",
    });
  }, [updateHeading]);
  return (
    <div className={styles.column_4}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_item, index) => (
        <Course
          img={profile}
          key={index}
          time="14 mins"
          title="How to read your ABC."
          tutor="Uncle Hasan"
          category="Music"
        />
      ))}
    </div>
  );
};

export default Downloads;
