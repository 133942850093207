import { Constants } from "core/utils/constants";
import { SecureStorage } from "core/utils/storage";
import React, { FC, useEffect } from "react";
import { Navigate } from "react-router-dom";

const GuardRoute = ({ children }: { children: React.ReactNode }) => {
  const secureStorage = new SecureStorage();
  const isAuthenticated = secureStorage.getItem(Constants.token);
  const isCurrentUser = secureStorage.getItem(Constants.currentUser);

  const auth = () => {
    if (isAuthenticated && isCurrentUser) {
      return true;
    } else {
      return false;
    }
  };

  return !auth() ? <>{children}</> : <Navigate to="/" />;
};
export default GuardRoute;
