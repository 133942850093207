import { Lock, Play } from "assets/svg";
import React, { FC, MouseEventHandler } from "react";
import styles from "./outline.module.scss";
import { truncate } from "lodash";

type Props = {
  active?: boolean;
  topic: string;
  duration: number;
  onClick: MouseEventHandler;
  type: string;
};

const Outline: FC<Props> = ({ active, topic, duration, onClick, type }) => {
  const formatDuration = (duration: number) => {
    const minutes = Math.floor(duration / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (duration % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className={[styles.badge, "text-center mb-15", active ? "outline-active" : ""].join(" ")} onClick={onClick}>
      <div className="flex justify-content-between align-items-center">
        <div>{active ? <Play /> : <Lock />}</div>
        <div>{truncate(topic)}</div>
        <div>{formatDuration(duration)}</div>
      </div>
    </div>
  );
};

export default Outline;
