import {
  ChatMessageData,
  LivestreamData,
  LivestreamMemberData,
  StreamSession,
} from "../../../types/livestream";
import "./watch-livestream.scss";
import { Session } from "@opentok/client";
import LivestreamChat from "../livestream-chat";
import LivestreamMembers from "../livestream-members";
import { useContext, useEffect, useState } from "react";
import MainLayoutContext from "../../../context/main-layout";

type Props = {
  livestream: LivestreamData;
  session?: Session;
  streamSession?: StreamSession;
  messages: ChatMessageData[];
  members: LivestreamMemberData[];
};

const WatchLivestream = ({
  livestream,
  session,
  streamSession,
  messages = [],
  members,
}: Props) => {
  const { setLoading } = useContext(MainLayoutContext);
  const [sessionConnected, setSessionConnected] = useState(false);

  useEffect(() => {
    if (session && streamSession) {
      session.connect(streamSession.token, async function (error) {
        if (error) console.error(error);
        setLoading(false);
      });
    }
  }, [session, streamSession]);

  return (
    <div className="watch-livestream">
      <div className="livestream-view">
        <div className="row">
          <div className="col-md-8">
            <div className="player-cover">
              <div className="player ratio ratio-16x9">
                <div id="player" />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <LivestreamChat messages={messages} session={session} />
          </div>
        </div>
      </div>
      <div className="livestream-details">
        <div className="row g-3">
          <div className="col-md-8">
            <div className="description">
              <p className="title">About this livestream</p>
              <p>{livestream.description}</p>
            </div>
          </div>
          <div className="col-md-4">
            <LivestreamMembers members={members} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchLivestream;
