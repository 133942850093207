import { get, getById, getList } from "../service/api";
import { IResponse } from "../../types/responses";
import { LivestreamData } from "../../types/livestream";

export default {
  fetchMine: async (page: undefined | number) =>
    await get({ url: "live", page: page }),
  fetchLivestream: async (
    id: number
  ): Promise<IResponse<LivestreamData> | undefined> =>
    await getById({ url: "live", id }),
  init: async (id: number) => await getList({ url: `live/${id}/init` }),
};
