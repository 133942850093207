import { LivestreamData } from "../../../types/livestream";
import livestreamBg from "../../../assets/img/livestream-bg.png";
import clockIC from "../../../assets/svg/clock.svg";
import teacherIC from "../../../assets/svg/teacher.svg";
import HourGlassIC from "../../../assets/svg/hour-glass.svg";
import "./view-livestream.scss";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import formatToCurrency from "config/formatAmount";
import Button from "components/button";
import ModalBox from "components/ViewImage";
import { useNavigate } from "react-router-dom";

type Props = {
  livestream: LivestreamData;
  join: () => void;
};

const ViewLivestream = ({ livestream, join }: Props) => {
  const navigate = useNavigate();
  const visible = useRef(true);
  const [formattedStartTime, setFormattedStartTime] = useState<any>(DateTime.now());
  const [formattedEndTime, setFormattedEndTime] = useState<any>(DateTime.now());
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const [dateDifference, setDateDifference] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      visible.current = false;
    };
  }, []);

  useEffect(() => {
    if (livestream) {
      setFormattedStartTime(DateTime.fromISO(livestream.event_start_time));
      setFormattedEndTime(DateTime.fromISO(livestream.event_close_time));
      setCurrentDate(DateTime.now());
    }
  }, [livestream]);

  useEffect(() => {
    try {
      const diff = formattedStartTime
        .diff(currentDate, ["hours", "minutes", "seconds"])
        .toObject();
      if (diff.seconds! > 0 || diff.minutes! > 0 || diff.hours! > 0) {
        setTimeout(() => {
          if (visible.current) setCurrentDate(DateTime.now());
        }, 1000);
      }
    } catch (e) {
      console.error(e);
    }
  }, [currentDate]);

  useEffect(() => {
    const diffDate = formattedStartTime.diff(currentDate, [
      "seconds",
      "minutes",
      "hours",
    ]);
    const diffDateToObj = diffDate.toObject();

    if (
      diffDateToObj.seconds! <= 0 &&
      diffDateToObj.minutes! <= 0 &&
      diffDateToObj.hours! <= 0
    ) {
      setDateDifference("Starting Soon");
    } else setDateDifference(`${diffDate.toFormat("hh:mm:ss")} to go`);
  }, [currentDate, formattedStartTime]);

  const started = livestream.status.toLowerCase() === "started";

  return (
    <>
      {openModal && (
        <ModalBox close={() => setOpenModal(false)}>
          <div className="w-[480px] h-fit bg-white rounded-[10px] py-[20px] px-[20px]">
            <div className="text-d-blue text-[25px] font-[700] font-avenir mb-[25px]">
              Pay For Live Class
            </div>
            <div className="text-[#000] text-[16px] font-avenir mb-[25px] leading-[150%]">
              You are about to pay for this life class{" "}
              <span>{livestream?.title}</span>. Please select a payment ment
              below.
            </div>

            <div className="grid grid-cols-2 gap-7 mb-10 w-full items-center justify-between">
              <div className="h-[52px] !text-center w-full text-[16px] font-[600] rounded-[5px] bg-d-orange text-white py-[12px] px-[10px]">
                Pay With Dyeka Wallet
              </div>
              <div className="h-[52px] !text-center w-full text-[16px] font-[600] rounded-[5px] border border-[#000] text-[#000] py-[12px] px-[10px]">
                Pay With Your Card
              </div>
            </div>
          </div>
        </ModalBox>
      )}
      <div className="view-livestream">
        <div className="livestream-bg">
          <img src={livestreamBg} alt="livestream-bg" />
        </div>
        <p className="title">
          Welcome to <span>{livestream.title}</span>
        </p>
        <p className="desc">{livestream.description}</p>
        <div className="tutor-time d-flex">
          <p className="flex !items-center">
            <span className="icon">
              <img src={clockIC} alt="time-ic" />
            </span>
            {`${formattedStartTime.toFormat(
              "hh:mm a"
            )} - ${formattedEndTime.toFormat("hh:mm a")}`}
          </p>

          <p className="flex !items-center">
            <span className="icon">
              <img src={teacherIC} alt="time-ic" />
            </span>
            {livestream.tutor_name}
          </p>
        </div>

        <div className="!border w-fit mt-6 !border-[#D21C28] !text-[#D21C28] rounded-[7px] px-[15px] py-[10px] text-[14px]">
          <span className="font-[600]">
            {formatToCurrency(livestream.price)}
          </span>
          &#160; per student
        </div>

        {livestream.status.toLowerCase() === "started" ? (
          <div
            className="btn text-white bg-primary action-btn"
            onClick={() => {
              livestream.bought
                ? join()
                : navigate("/app/settings/payments", {
                  state: {
                    data: {
                      id: livestream?.id,
                      price: livestream?.price,
                      type: "live",
                    },
                  }
                });
            }}
          >
            {livestream.bought ? "JOIN" : "Pay For Class"}
          </div>
        ) : (
          <div className="btn text-white bg-primary action-btn disabled">
            <img className="icon" src={HourGlassIC} alt="hg-ic" />
            <span className="me-1">{dateDifference}</span>
          </div>
        )}

        {!started && !livestream.bought && (
          <Button
            type="button"
            className="text-[16px] mt-6 w-fit"
            onClick={() =>
              navigate(`/app/settings/payments`, {
                state: {
                  data: {
                    id: livestream?.id,
                    price: livestream?.price,
                    type: "live",
                  },
                }
              })
            }
          >
            Pay For Class
          </Button>
        )}
      </div>
    </>
  );
};

export default ViewLivestream;
