import { Loading } from "assets/svg";
import { AxiosResponse } from "axios";
import { Chips, Button } from "components";
import UserContext from "context/user";
import MainContext from "context/main";

import { AddProfileApiService } from "core/service/profiles";
import { Constants } from "core/utils/constants";
import { handleError } from "core/utils/error-handler";
import { SecureStorage } from "core/utils/storage";
import useForm from "core/utils/use-form";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { IProfile } from "types/profiles";
import { IResponse } from "types/responses";
import { IUser } from "types/user";
import styles from "./child-form.module.scss";
import { TagResultData } from "types/tag";
import { appHelpers } from "core/utils/helpers";
import { ClassResultData } from "types/class";
import Image from "components/Image";
import profile from "../../../assets/img/profile-avatar.png";

const ChildForm = () => {
  const { updateCurrentUser, removeTemp } = useContext(UserContext);
  const { tags, classes } = useContext(MainContext);
  const [selectedInterests, setselectedInterests] = useState<string[]>([]);
  const [photo, setPhoto] = useState<any>("https://dyeka-assets.s3.us-east-2.amazonaws.com/default+profile.svg");
  const secureStorage = new SecureStorage();
  const isTemp = secureStorage.getItem(Constants.temp);
  const Temp: any = isTemp;
  const tempJSON: IUser = JSON.parse(Temp);

  const currentUser = secureStorage.getItem(Constants.currentUser);
  const current: any = currentUser;
  const currentUserJSON: IUser = JSON.parse(current);

  let navigate = useNavigate();

  const { mutate, isLoading } = useMutation(AddProfileApiService, {
    onSuccess: (res: AxiosResponse<IResponse<IProfile>>) => {
      const { success, data } = res.data;
      if (success) {
        if (window.location.pathname.includes("/app/add-profile?type=onboarding")) {
          tempJSON.profiles = [...tempJSON.profiles, data];
          updateCurrentUser(tempJSON);
          navigate("/app/profile");
          removeTemp();
        }
        else {
          currentUserJSON.profiles = [...currentUserJSON.profiles, data];
          updateCurrentUser(currentUserJSON);
          if (window.location.pathname.includes("/app/add-profile")) {
            navigate("/app/profile");
          }
          else if (window.location.pathname.includes("/app/settings/profile")) {
            window.location.reload();
          }
        }
        appHelpers.alertSuccess("Profile created successfully");
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      console.log(error);
      appHelpers.alertError(response?.message ?? "Something happened please try again");
    },
  });

  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toISOString().split("T")[0];
  const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 12)).toISOString().split("T")[0];

  const initState = {
    first_name: "",
    last_name: "",
    dob: "",
    class_id: "",
    interests: [],
  };
  const submit = () => {
    const payload = {
      ...inputs,
      interests: selectedInterests,
      photo_url: photo,
    };
    mutate(payload);
  };

  const { inputs, handleChange, handleSubmit } = useForm<IProfile>(initState, submit);

  const handleInterestClick = (value: string) => {
    if (selectedInterests.includes(value)) {
      let out = selectedInterests.filter((h) => h !== value);
      setselectedInterests(out);
    } else {
      let joined = selectedInterests.concat(value);
      setselectedInterests(joined);
    }
  };

  const handleImageChange = (e: any) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setPhoto(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <form onSubmit={handleSubmit}>
      {window.location.pathname.includes("/app/add-profile") && (
        <div className="mb-[41px]">
          <span style={{ cursor: "pointer" }} className="text-primary-color font-medium text-[16px]" onClick={() => navigate(-1)}>
            {/* Back */}
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </span>
        </div>
      )}
      <Image uploadedPhoto={photo} photo_url={profile} handleImageChange={handleImageChange} />
      <div className={styles.form}>
        <div className="form-group">
          <label>First Name Of Child</label>
          <input type="text" name="first_name" onChange={handleChange} value={inputs.first_name} maxLength={20} />
        </div>
        <div className="form-group">
          <label>Last Name Of Child</label>
          <input type="text" name="last_name" onChange={handleChange} value={inputs.last_name} maxLength={20} />
        </div>
        <div className="form-group dob">
          <label>Date of Birth of Child</label>
          <input type="date" name="dob" onChange={handleChange} value={inputs.dob} min={minDate} max={maxDate} />
        </div>
        <div className="form-group select">
          <label>School Class of Child</label>
          <select name="class_id" value={inputs.class_id} onChange={handleChange}>
            <option>Select Class</option>
            {classes.map((item: ClassResultData, index) => (
              <option key={`class=${index}`} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label>Interests</label>
        <div className={styles.chips}>
          {tags.map((item: TagResultData, index) => (
            <Chips item={item.tag} key={`chip=${index}`} active={selectedInterests?.includes(item.tag)} onClick={() => handleInterestClick(item.tag)} />
          ))}
        </div>
      </div>
      <div className="mt-40">
        <Button type="submit" disabled={!inputs.first_name || !inputs.last_name || !inputs.dob || !inputs.class_id || isLoading}>
          {isLoading ? <Loading /> : "FINISH"}
        </Button>
      </div>
    </form>
  );
};

export default ChildForm;
