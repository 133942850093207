import { Trash } from "assets/svg";
import UserContext from "context/user";
import { SecureStorage } from "core/utils/storage";
import React, { useContext, useState } from "react";
import CustomAvatar from "components/custom-avatar";
import { useNavigate } from "react-router-dom";
import { IProfile, ISwitchProfileResponse } from "types/profiles";
import styles from "./switchProfile.module.scss";
import { useMutation } from "react-query";
import { SwitchProfileApiService } from "core/service/profiles";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import AuthContext from "context/auth";
import { handleError } from "core/utils/error-handler";

interface SwitchProfileProps {
  setSelectedTab?: (value: number) => void;
}

const SwitchProfile = (props: SwitchProfileProps) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const { setAuthAndCache } = useContext(AuthContext);

  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const profile: IProfile = JSON.parse(selectedProfile);

  const { mutate, isLoading } = useMutation(SwitchProfileApiService, {
    onSuccess: (res: AxiosResponse<IResponse<ISwitchProfileResponse>>) => {
      const { success, data } = res.data;
      if (success) {
        setAuthAndCache(`${`Bearer`} ${data?.token}`);
        navigate("/app/home");
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response?.message);
    },
  });

  const setCurrentProfile = (value: string) => {
    const parsed = JSON.parse(value);
    mutate(parsed.id);
    secureStorage.storeItem("selectedProfile", value);
    navigate("/app/home");
  };

  const handleShowChildProfile = () => {
    if (props.setSelectedTab) {
      props.setSelectedTab(1);
    }
  };

  return (
    <div className={styles.container}>
      <div className="mb-50">
        <small>Select a child's profile to switch.</small>
      </div>

      <div className={styles.grid}>
        {currentUser.profiles?.length === 0
          ? null
          : currentUser.profiles.map((item: IProfile, index: number) => (
            <div
              className="flex justify-content-center"
              // onClick={() => setCurrentProfile(JSON.stringify(item))}
              key={`user-${index}`}
            >
              <div
                className={
                  profile
                    ? item.id === profile.id
                      ? [styles.circle, "bg-grey selectedProfile"].join(" ")
                      : [styles.circle, "bg-grey"].join(" ")
                    : [styles.circle, "bg-grey"].join(" ")
                }
              >
                <div className={styles.pointer}>
                  {/* <img className={styles.img} src={item.photo_url} alt={item.photo_url} /> */}
                  <CustomAvatar
                    src={item.photo_url}
                    alt={item.photo_url}
                    firstName={item.first_name}
                    lastName={item.last_name}
                    onClick={() => setCurrentProfile(JSON.stringify(item))}
                  />
                </div>
                <div
                  className={[
                    styles.name,
                    "text-dark text-[15px] font-medium",
                  ].join(" ")}
                >
                  <b>{`${item.first_name} ${item.last_name}`}</b>
                </div>
                {/* <div
                    className={[styles.remove, "text-primary-color"].join(" ")}
                  >
                    <Trash /> Remove Profile
                  </div> */}
              </div>
            </div>
          ))}

        {
          currentUser.subscription_id ? (
            <div
              className="flex justify-content-center"
              onClick={handleShowChildProfile}
            >
              <div
                className={[styles.circle, styles.pointer, "bg-grey"].join(" ")}
              >
                <div
                  className="text-primary-color"
                  style={{
                    fontSize: "100px",
                    lineHeight: "45px",
                    fontWeight: 900,
                  }}
                >
                  +
                </div>
              </div>

              <div
                className={[
                  styles.remove,
                  "text-primary-color text-[15px] font-medium",
                ].join(" ")}
              >
                Add Profile
              </div>
            </div>
          ) : null
          // <div className="flex justify-content-center">
          //   <div
          //     className={[styles.circle, styles.pointer, "bg-grey"].join(" ")}
          //   >
          //     <div
          //       className="text-primary-color"
          //       style={{
          //         fontSize: "100px",
          //         lineHeight: "45px",
          //         fontWeight: 900,
          //       }}
          //     ></div>
          //   </div>
          // </div>
        }
      </div>
    </div>
  );
};

export default SwitchProfile;
