export default function formatToCurrency(amount, removeN) {
  return !removeN
    ? "₦ " +
        parseInt(amount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : parseInt(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(".00", "");
}
