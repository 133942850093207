import TeacherCard from "components/teacher-card";
import MainLayoutContext from "context/main-layout";
import React, { useContext, useEffect } from "react";
import styles from "./teachers.module.scss";
import teacherPic1 from "../../../assets/img/teacher.png";
import teacherPic2 from "../../../assets/img/teacher-2.png";
import teacherPic3 from "../../../assets/img/teacher-3.png";

const Teachers = () => {
  const { updateHeading } = useContext(MainLayoutContext);
  useEffect(() => {
    updateHeading({
      header: "Teachers",
      subHeader:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, earum!",
    });
  }, []);
  return (
    <div className={styles.layout}>
      <div className="flex justify-content-between mt-20 mb-10">
        <div className="text-red">
          <b>Grade 1</b>
        </div>
        <small className="text-primary-color">See All</small>
      </div>
      <div className={styles.column_4}>
        {[1, 2, 3, 4].map((item, index) => (
          <TeacherCard
            key={index}
            tutor="Aunty Sam"
            img={teacherPic1}
            subject="Mathematics"
          />
        ))}
      </div>
      <div className="flex justify-content-between mt-20 mb-10">
        <div className="text-red">
          <b>Grade 2</b>
        </div>
        <small className="text-primary-color">See All</small>
      </div>
      <div className={styles.column_4}>
        {[1, 2, 3, 4].map((item, index) => (
          <TeacherCard
            key={index}
            tutor="Aunty Tejiri"
            img={teacherPic2}
            subject="Biology"
          />
        ))}
      </div>
      <div className="flex justify-content-between mt-20 mb-10">
        <div className="text-red">
          <b>Grade 3</b>
        </div>
        <small className="text-red">See All</small>
      </div>
      <div className={styles.column_4}>
        {[1, 2, 3, 4].map((item, index) => (
          <TeacherCard
            key={index}
            tutor="Aunty Becky"
            img={teacherPic3}
            subject="Psychology"
          />
        ))}
      </div>
    </div>
  );
};

export default Teachers;
