import ChildForm from "pages/shared/child-form";

import { Trash } from "assets/svg";
import UserContext from "context/user";
import { SecureStorage } from "core/utils/storage";
import { useContext, useEffect, useRef, useState } from "react";
import CustomAvatar from "components/custom-avatar";
import { useNavigate } from "react-router-dom";
import { IProfile, ISwitchProfileResponse } from "types/profiles";
import styles from "./switchProfile.module.scss";
import { useMutation } from "react-query";
import { SwitchProfileApiService } from "core/service/profiles";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import AuthContext from "context/auth";
import { handleError } from "core/utils/error-handler";
import UpdateChildForm from "./UpdateChildForm";
import { Modal } from "bootstrap";
import { Button } from "../../../../../components";
import { userAction } from "../../../../../core/actions";
import MainLayoutContext from "../../../../../context/main-layout";
import { toast } from "react-toastify";

interface SwitchProfileProps {
  setSelectedTab?: (value: number) => void;
}

const ChildProfile = ({ setSelectedTab }: SwitchProfileProps) => {
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useContext(UserContext);
  const { setAuthAndCache } = useContext(AuthContext);
  const { setLoading } = useContext(MainLayoutContext);

  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const profile: IProfile = JSON.parse(selectedProfile);
  const [editProfile, setEditProfile] = useState(false);
  const [addProfile, setAddProfile] = useState(false);
  const [childProfileSelected, setChildProfileSelected] = useState(false);

  const passwordModalRef = useRef<HTMLDivElement>(null);
  const [passwordModal, setPasswordModal] = useState<Modal | null>(null);
  const [deleteProfileId, setDeleteProfileId] = useState(0);
  const [password, setPassword] = useState("");

  useMutation(SwitchProfileApiService, {
    onSuccess: (res: AxiosResponse<IResponse<ISwitchProfileResponse>>) => {
      const { success, data } = res.data;
      if (success) {
        setAuthAndCache(`${`Bearer`} ${data?.token}`);
        navigate("/app/home");
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response?.message);
    },
  });

  const setCurrentProfile = (value: string) => {
    const parsed = JSON.parse(value);
    setChildProfileSelected(parsed);
    //mutate(parsed.id);
    secureStorage.storeItem("selectedProfile", value);
    //navigate("/app/home");
    setEditProfile(true);
  };

  const handleAddProfile = () => {
    setAddProfile(true);
  };

  const initDeleteProfile = async (profileId: number) => {
    if (!passwordModal) return;
    setDeleteProfileId(profileId);
    passwordModal.show();
  };

  const handleProfileDelete = async () => {
    setLoading(true);

    const res = await userAction.deleteProfile(deleteProfileId, { password });
    if (res) {
      if (res.success) {
        toast.success("Profile deleted successfully");
        setPassword("");
        await refreshUser();
        passwordModal?.hide();
      } else toast.error(res.message);
    } else toast.error("An error occurred");

    setLoading(false);
  };

  const closeModal = (modal: Modal | null) => {
    if (modal) modal.hide();
  };

  useEffect(() => {
    if (passwordModalRef.current) {
      setPasswordModal(new Modal(passwordModalRef.current));
    }
  }, [passwordModalRef]);

  return (
    <>
      {editProfile ? (
        <UpdateChildForm userDetails={childProfileSelected} />
      ) : addProfile ? (
        <ChildForm />
      ) : (
        <div className={styles.container}>
          <div className="mb-50">
            <small>Select a child's profile to switch.</small>
          </div>

          <div className={styles.grid}>
            {currentUser.profiles?.length === 0
              ? null
              : currentUser.profiles.map((item: IProfile, index: number) => (
                <div
                  className="flex justify-content-center"
                  // onClick={() => setCurrentProfile(JSON.stringify(item))}
                  key={`user-${index}`}
                >
                  <div
                    className={
                      profile
                        ? item?.id === profile.id
                          ? [styles.circle, "bg-grey selectedProfile"].join(
                            " "
                          )
                          : [styles.circle, "bg-grey"].join(" ")
                        : [styles.circle, "bg-grey"].join(" ")
                    }
                  >
                    <div className={styles.pointer}>
                      {/* <img className={styles.img} src={item?.photo_url} alt={item?.photo_url} /> */}
                      <CustomAvatar
                        addEdit
                        src={item?.photo_url}
                        alt={item?.photo_url}
                        firstName={item?.first_name}
                        lastName={item?.last_name}
                        onClick={() =>
                          setCurrentProfile(JSON.stringify(item))
                        }
                      />
                    </div>
                    <div className={[styles.name, "text-dark"].join(" ")}>
                      <b>{`${item?.first_name} ${item?.last_name}`}</b>
                    </div>
                    <div
                      className={[styles.remove, "text-primary-color"].join(
                        " "
                      )}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        item?.id && initDeleteProfile(Number(item?.id));
                      }}
                    >
                      <Trash /> Remove Profile
                    </div>
                  </div>
                </div>
              ))}
            {
              currentUser ? (
                <div onClick={handleAddProfile}>
                  <div
                    className={[
                      styles.circle,
                      styles.pointer,
                      "bg-grey",
                      "flex",
                      "justify-content-center",
                    ].join(" ")}
                  >
                    <div
                      className="text-primary-color"
                      style={{
                        fontSize: "100px",
                        fontWeight: 900,
                      }}
                    >
                      +
                    </div>
                  </div>

                  <div
                    className={[
                      styles.remove,
                      "text-primary-color text-[15px] font-medium",
                    ].join(" ")}
                  >
                    Add Profile
                  </div>
                </div>
              ) : null
              // <div className="flex justify-content-center">
              //   <div
              //     className={[styles.circle, styles.pointer, "bg-grey"].join(
              //       " "
              //     )}
              //   >
              //     <div
              //       className="text-primary-color"
              //       style={{
              //         fontSize: "100px",
              //         lineHeight: "45px",
              //         fontWeight: 900,
              //       }}
              //     />
              //   </div>
              // </div>
            }
          </div>
          <div className="modal" ref={passwordModalRef} tabIndex={-2}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enter Password</h5>
                  <input
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closeModal(passwordModal)}
                  ></input>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn bg-red btn-danger"
                      onClick={() => closeModal(passwordModal)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn text-white bg-primary"
                      onClick={handleProfileDelete}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChildProfile;

/*
const ChildProfile = () => {
  return <ChildForm />;
};



export default ChildProfile;
*/
