import React, { FC, useState } from "react";
import styles from "./toggle.module.scss";

type Props = {
  option1: string;
  option2?: string;
  name: string;
  handleToggle?: any;
  toggled?: boolean;
};
const Toggle: FC<Props> = ({
  option1,
  option2,
  name,
  handleToggle,
  toggled,
}) => {
  return (
    <div>
      <label className={styles.toggle} htmlFor={name}>
        <input name={name} type="checkbox" id={name} />
        <span onClick={handleToggle} className={styles.slider}>
          <span className={styles.options}>
            <span className={!toggled ? styles.activeOption : ""}>
              {option1}
            </span>
            <span className={toggled ? styles.activeOption : ""}>
              {option2}
            </span>
          </span>
        </span>
      </label>
    </div>
  );
};

export default Toggle;
