import { Button } from "components";
import UserContext from "context/user";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import styles from "./supervisor.module.scss";
import { UpdateSupervisorApiService } from "core/service/supervisor";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import { handleError } from "core/utils/error-handler";
import { Loading } from "assets/svg";
import useForm from "core/utils/use-form";
import { ISupervisorRequest } from "types/supervisor";
import { appHelpers } from "core/utils/helpers";
import { emailRegex } from "utils";

const Supervisor = () => {
  const { currentUser } = useContext(UserContext);
  const initState = {
    phone_number: currentUser?.phone_number || "",
    email: currentUser?.email,
    first_name: currentUser?.first_name,
    last_name: currentUser?.last_name,
  };

  const { mutate, isLoading } = useMutation(UpdateSupervisorApiService, {
    onSuccess: (res: AxiosResponse<IResponse<any>>) => {
      const { success } = res.data;
      if (success) {
        appHelpers.alertSuccess("Information updated sucessfully");
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);

      appHelpers.alertError(
        response?.message ?? "Something happened please try again"
      );
    },
  });

  const submit = () => {
    if (
      inputs.first_name === "" ||
      inputs.last_name === "" ||
      inputs.phone_number === "" ||
      inputs.email === ""
    ) {
      return appHelpers.alertError("Please fill out guardian form");
    }

    if (!emailRegex.test(inputs.email as string)) {
      appHelpers.alertError("Enter Valid Email");
    } else {
      delete inputs.email;
      mutate(inputs);
    }
  };

  const { inputs, handleChange, handleSubmit } = useForm<ISupervisorRequest>(
    initState,
    submit
  );

  return (
    <div>
      <div className="mb-50">
        <small>
          Please edit the form with a guardian or account supervisor
          information.
        </small>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            name="first_name"
            onChange={handleChange}
            value={inputs.first_name}
            defaultValue={currentUser.first_name}
            maxLength={20}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="last_name"
            onChange={handleChange}
            value={inputs.last_name}
            maxLength={20}
            defaultValue={currentUser.last_name}
          />
        </div>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            disabled
            value={inputs.email}
            defaultValue={currentUser.email}
            maxLength={50}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="number"
            name="phone_number"
            onChange={handleChange}
            value={inputs.phone_number}
            defaultValue={currentUser.phone_number}
            maxLength={15}
          />
        </div>
        <div className={styles.button}>
          <Button
            type="submit"
            className="text-center mt-45"
            disabled={isLoading}
          >
            {isLoading ? <Loading /> : "DONE"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Supervisor;
