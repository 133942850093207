import './option.scss'
import {TestQuestionsOptionData} from "../../../types/test";

type Props = {
  no: number
  option: TestQuestionsOptionData
  markOption: (id: number) => void
}

const Option = ({ no, option, markOption }: Props) => {

  const optionsLetters = ['A', 'B', 'C', 'D', 'E', 'F']

  return (
    <div className={`option ${option.marked ? 'marked' : ''}`}>
      <div className="row">
        <div className="col-auto">
          <p className="letter" onClick={() => markOption(no)}>{ optionsLetters[no] }</p>
        </div>
        <div className="col my-auto">
          <p className="option-txt">{ option.option }</p>
        </div>
      </div>
    </div>
  )
}

export default Option