import { appHelpers } from "core/utils/helpers";
import React from "react";
import styles from "../dashboard/dashboard.module.scss";
import { InnerResultData } from "types/dashboard";
import { Course, UngoingCourse } from "components";
import { useNavigate } from "react-router-dom";
import CourseCard from "components/CourseCard";

function MyFavourites() {
  const navigate = useNavigate();

  const value = {
    type: "picture",
    description: "My Favourites",
    data: [
      {
        id: "3",
        title: "Example Name",
        name: "Example Name",
        description: "This is an example description",
        class_id: "4",
        no_of_videos: 2,
        subject: "mathematics",
        tutor_name: "Abbas Musa",
        tutor_bio: "",
        tutor_image: "",
        tags: ["new", "short"],
        preview_video: "",
        preview_duration: 2,
        downloadable: true,
        thumbnail: "https://i.vimeocdn.com/video/1223394571_1920x1080?r=pad",
        created_on: "2021-08-25T00:50:48.130Z",
        modified_on: "2021-08-25T00:50:48.130Z",
      },
      {
        id: "3",
        title: "Example Name",
        name: "Example Name",
        description: "This is an example description",
        class_id: "4",
        no_of_videos: 2,
        subject: "mathematics",
        tutor_name: "Abbas Musa",
        tutor_bio: "",
        tutor_image: "",
        tags: ["new", "short"],
        preview_video: "",
        preview_duration: 2,
        downloadable: true,
        thumbnail: "https://i.vimeocdn.com/video/1223394571_1920x1080?r=pad",
        created_on: "2021-08-25T00:50:48.130Z",
        modified_on: "2021-08-25T00:50:48.130Z",
      },
    ],
  };

  const goToLesson = async (id: any) => {
    navigate(`/app/lesson/${id}`);
  };

  return (
    <div className={styles.layout}>
      {value?.data?.length >= 1 && (
        <>
          <div className="flex justify-content-between mt-20 mb-10">
            <div className="red-header">
              <b>{appHelpers.removeCommaAndDot(value?.description)}</b>
            </div>
          </div>
          {value.type === "videos" ? (
            <div className={styles.column_3}>
              {value.data.slice(0, 3).map((item: any, index: number) => (
                <UngoingCourse
                  img={item.thumbnail}
                  key={index}
                  percentage={item?.video_history?.percentage_watched}
                  title={item.title}
                  description={appHelpers.truncateValue(item?.description)}
                  subject={item.subject}
                  tutor={item.tutor_name}
                  PlayClick={() => goToLesson(item.id)}
                />
              ))}
            </div>
          ) : (
            <div className={styles.column_4}>
              {value.data.slice(0, 4).map((item: any, index: number) => (
                <CourseCard
                  PlayClick={() => goToLesson(item.id)}
                  bought={item?.bought}
                  key={index}
                  period={item?.payment_period}
                  thumbnail={
                    item.thumbnail ||
                    item?.live_event?.thumbnail ||
                    item?.lesson?.thumbnail ||
                    (item?.tutor?.first_name &&
                      `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)
                  }
                  tutorName={
                    item.tutor_name ||
                    item?.live_event?.tutor_name ||
                    item?.lesson?.tutor_name
                  }
                  description={appHelpers.truncateValue(
                    item?.description ||
                    item?.live_event?.description ||
                    item?.lesson?.description,
                    40
                  )}
                  live={true}
                  duration={
                    item?.duration ||
                    item?.preview_duration ||
                    item?.live_event?.preview_duration ||
                    item?.lesson?.preview_duration
                  }
                  price={
                    item?.price ||
                    item?.live_event?.price ||
                    item?.lesson?.price
                  }
                  startAge={""}
                  endAge={""}
                  subject={
                    item?.title ||
                    item?.name ||
                    item?.subject ||
                    item?.live_event?.subject ||
                    item?.lesson?.subject ||
                    item?.lesson?.name
                  }
                />
                // <Course
                //   img={item.thumbnail}
                //   key={index}
                //   time={`${Math.floor(item?.preview_duration / 60)} mins`}
                //   title={item.name}
                //   description={appHelpers.truncateValue(item?.description)}
                //   subject={item.subject}
                //   tutor={item.tutor_name}
                //   category={item.name}
                //   noOfVideos={item?.no_of_videos}
                //   duration={item?.preview_duration}
                //   PlayClick={() => goToLesson(item.id)}
                // />
              ))}
            </div>
          )}{" "}
        </>
      )}
    </div>
  );
}

export default MyFavourites;
