import React from "react";
import { formatPrice } from "../config/formatPrice";
import { Play } from "assets/svg";
import moment from "moment";

function PendingHomeworkCard({ work }) {
  return (
    <section className="flex mb-[1rem] flex-col flex-grow mmd:w-auto w-auto h-fit shadow-md bg-white rounded-[10px] mmd:p-[10px] p-[14px]">
      <div
        // onClick={}
        className={`w-full cursor-pointer relative mmd:h-[135px] overflow-hidden h-[165px] flex justify-center items-center rounded-[10px] mb-[11px] bg-[#EFEFEB]`}
      >
        <svg
          width="215"
          height="140"
          viewBox="0 0 215 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="215" height="140" rx="13" fill="#EFEFEB" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.124 39.9167C114.124 39.7067 114.04 39.5053 113.892 39.3569C113.743 39.2084 113.542 39.125 113.332 39.125H91.1654C88.8558 39.125 86.6408 40.0425 85.0076 41.6756C83.3745 43.3087 82.457 45.5237 82.457 47.8333V92.1667C82.457 94.4763 83.3745 96.6913 85.0076 98.3244C86.6408 99.9575 88.8558 100.875 91.1654 100.875H122.832C125.142 100.875 127.357 99.9575 128.99 98.3244C130.623 96.6913 131.54 94.4763 131.54 92.1667V60.9655C131.54 60.7555 131.457 60.5542 131.308 60.4057C131.16 60.2572 130.959 60.1738 130.749 60.1738H116.499C115.869 60.1738 115.265 59.9236 114.819 59.4782C114.374 59.0328 114.124 58.4287 114.124 57.7988V39.9167ZM116.499 70.7917C117.129 70.7917 117.733 71.0419 118.178 71.4873C118.623 71.9327 118.874 72.5368 118.874 73.1667C118.874 73.7966 118.623 74.4006 118.178 74.846C117.733 75.2914 117.129 75.5417 116.499 75.5417H97.4987C96.8688 75.5417 96.2647 75.2914 95.8193 74.846C95.3739 74.4006 95.1237 73.7966 95.1237 73.1667C95.1237 72.5368 95.3739 71.9327 95.8193 71.4873C96.2647 71.0419 96.8688 70.7917 97.4987 70.7917H116.499ZM116.499 83.4583C117.129 83.4583 117.733 83.7086 118.178 84.154C118.623 84.5994 118.874 85.2034 118.874 85.8333C118.874 86.4632 118.623 87.0673 118.178 87.5127C117.733 87.9581 117.129 88.2083 116.499 88.2083H97.4987C96.8688 88.2083 96.2647 87.9581 95.8193 87.5127C95.3739 87.0673 95.1237 86.4632 95.1237 85.8333C95.1237 85.2034 95.3739 84.5994 95.8193 84.154C96.2647 83.7086 96.8688 83.4583 97.4987 83.4583H116.499Z"
            fill="#3A3939"
          />
          <path
            d="M118.875 40.9426C118.875 40.3599 119.486 39.9894 119.939 40.3536C120.322 40.6639 120.667 41.0249 120.962 41.4366L130.503 54.7271C130.718 55.0311 130.484 55.4238 130.11 55.4238H119.667C119.457 55.4238 119.255 55.3404 119.107 55.1919C118.958 55.0434 118.875 54.8421 118.875 54.6321V40.9426Z"
            fill="#3A3939"
          />
        </svg>
      </div>

      <div className="flex w-full items-center font-AvenirltstdBook font-normal mb-[19px]">
        <div className="text-[16px] leading-[17px] text-d-blue h-[44px]">
          {work?.title || "- - -"}
        </div>
      </div>

      <div className="text-[#15345B] font-normal text-[13px] leading-[22px] mb-[21px] w-full flex item-center overflow-hidden">
        <span className="font-[600]">Teacher: </span>
        {work?.tutor_name}
      </div>

      <div className="w-full flex items-center justify-between gap-2 font-avenir text-[12px]">
        <div>Posted {moment(work?.created_on).format("ll")}</div>
        <div className="text-[#D21C28]">
          Due {moment(work?.due_date).format("ll")}
        </div>
      </div>
    </section>
  );
}

export default PendingHomeworkCard;
