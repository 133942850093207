import AddProfile from "pages/main/addProfile";
import Dashboard from "pages/main/dashboard";
import Downloads from "pages/main/download";
import Groups from "pages/main/groups";
import GroupPage from "pages/main/groups/GroupPage";
import LiveStreaming from "pages/main/live-streaming";
import LivestreamPage from "pages/main/live-streaming/livestream";
import PrivateClasses from "pages/main/private-class";
import Settings from "pages/main/settings";
import PrivateClassPage from "pages/main/private-class/PrivateClass";
import Teachers from "pages/main/teachers";
import MyClass from "pages/main/my-class";
import MyFavourites from "pages/main/my-favourites";
import MediaFiles from "pages/main/mediaFiles";
import AddAttachment from "pages/main/workToDo/addAttachment";
import WorkToDoId from "pages/main/workToDo/work-to-do-id";
import WorkToDo from "pages/main/workToDo";
import MyLearning from "pages/main/my-learning";
import LearningAnalysis from "pages/main/learning-analysis";
import CourseType from "pages/main/course-type";
import TakeTest from "pages/main/take-test";
import LikedVideos from "pages/main/my-learning/LikedVideos";
import SubjectPage from "pages/main/course-modules/subject";
import MyCourses from "pages/main/course-modules";
import Users from "pages/main/profile/users";
import { ErrorBoundary } from "..";
import { AppLayout } from "components/layouts";
import { MainLayoutProviderContainer } from "context/main-layout";
import PrivateRoute from "../protected";
import { Navigate, Outlet } from "react-router-dom";
import LiveStream from "pages/main/live-stream";
import LivestreamIDPage from "pages/main/live-stream/id";
import Payment from "pages/main/settings/payments";
import SettingsProfile from "pages/main/settings/settings-profile";
import Security from "pages/main/settings/security";
import Notification from "pages/main/settings/notification";
import Subscription from "pages/main/settings/subscription";

export const MainRoutes = {
  path: "app",
  element: (
    <MainLayoutProviderContainer>
      <PrivateRoute />
    </MainLayoutProviderContainer>
  ),

  errorElement: <ErrorBoundary />,
  children: [
    {
      path: "profile",
      element: <Users />,
    },
    {
      path: "*",
      element: (
        <>
          <AppLayout>
            <Outlet />
          </AppLayout>
        </>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="home" />,
        },
        {
          path: "add-profile",
          element: <AddProfile />,
        },
        {
          path: "course/*",
          element: <CourseType />,
        },
        {
          path: "home",
          element: <MyCourses />,
        },
        {
          path: "course-modules/:course_name",
          element: <SubjectPage />,
        },
        {
          path: "course-modules/:course_name/test/:test_id",
          element: <TakeTest />,
        },
        {
          path: "download",
          element: <Downloads />,
        },
        {
          path: "groups",
          element: <Groups />,
        },
        {
          path: "groups/:groupid",
          element: <GroupPage />,
        },
        {
          path: "homes",
          element: <Dashboard />,
        },
        {
          path: "learning-analysis",
          element: <LearningAnalysis />,
        },
        {
          path: "lesson/:id",
          element: <MyClass />,
        },
        {
          path: "liked-videos",
          element: <LikedVideos />,
        },
        {
          path: "live-streaming",
          element: <LiveStreaming />,
        },
        {
          path: "live-streaming/:id",
          element: <LivestreamPage />,
        },
        {
          path: "live-stream",
          element: <LiveStream />,
        },
        {
          path: "live-stream/:id",
          element: <LivestreamIDPage />,
        },
        {
          path: "media-files",
          element: <MediaFiles />,
        },
        {
          path: "my-class",
          element: <MyClass />,
        },
        {
          path: "my-favourites",
          element: <MyFavourites />,
        },
        {
          path: "my-learning",
          element: <MyLearning />,
        },
        {
          path: "private-classes",
          element: <PrivateClasses />,
        },
        {
          path: "private-classes/:classid",
          element: <PrivateClassPage />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "settings/profile",
          element: <SettingsProfile />,
        },
        {
          path: "settings/subscription",
          element: <Subscription />,
        },
        {
          path: "settings/payments",
          element: <Payment />,
        },
        {
          path: "settings/security",
          element: <Security />,
        },
        {
          path: "settings/notifications",
          element: <Notification />,
        },
        {
          path: "teachers",
          element: <Teachers />,
        },
        {
          path: "work-to-do",
          element: <WorkToDo />,
        },
        {
          path: "work-to-do/:id",
          element: <WorkToDoId />,
        },
        {
          path: "work-to-do/:id/add-attachment",
          element: <AddAttachment />,
        },
        {
          path: "*",
          element: (
            <div className="take-me-home">
              <h2 className="d-flex justify-content-center align-items-center">This is an error page</h2>
              <p className="my-5 d-flex justify-content-center align-items-center">
                Take me home? <a href={"/"}>&nbsp; Click me</a>
              </p>
            </div>
          ),
        },
      ],
    },
  ],
};
