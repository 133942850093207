import { toast } from "react-toastify";
import { ApiRequest } from "../../types/api-request";
import Axios from "./axios";
import { setLogout } from "context/auth";
import axios, { AxiosError, AxiosResponse } from "axios";
import { IResponse } from "../../types/responses";

const perPage: number = 20;

const addFilters = (url: string, filters: any): string => {
  let filtersFormatted: [string, string][];
  if (!Array.isArray(filters)) filtersFormatted = Object.entries(filters);
  else filtersFormatted = filters;

  filtersFormatted.forEach((filter) => {
    if (filter[1] !== null && filter[1] !== "") url += `&${filter[0]}=${filter[1]}`;
  });

  return url;
};

export const get = async ({ url, page, filters = [] }: ApiRequest) => {
  url = `/${url}?page=${page}&size=${perPage}`;
  url = addFilters(url, filters);

  try {
    const { data: res }: { data: IResponse } = await Axios.get(url);
    return res;
  } catch (e: unknown) {
    handleError(e);
  }

  return null;
};

export const getList = async ({ url, filters = [] }: ApiRequest): Promise<IResponse | undefined> => {
  url = `/${url}?`;
  url = addFilters(url, filters);

  try {
    const { data } = await Axios.get(url);
    return data;
  } catch (e: unknown) {
    handleError(e);
  }
};

export const getById = async ({ url, id }: ApiRequest): Promise<IResponse | undefined> => {
  url = `/${url}/${id}`;

  try {
    const { data: res }: { data: IResponse } = await Axios.get(url);
    return res;
  } catch (e: unknown) {
    handleError(e);
  }
};

export const put = async ({ url, data }: ApiRequest) => {
  url = `/${url}`;

  try {
    const res = await Axios.put(url, data);
    return res.data;
  } catch (e: unknown) {
    handleError(e);
  }
};

export const deleteReq = async ({ url, id, data }: ApiRequest) => {
  url = `/${url}/${id}`;

  try {
    const { data: res }: { data: IResponse } = await Axios.delete(url, {
      data,
    });
    return res;
  } catch (e: unknown) {
    handleError(e);
  }

  return null;
};

export const post = async ({ url, data }: ApiRequest) => {
  url = `/${url}`;

  try {
    const { data: res }: { data: IResponse } = await Axios.post(url, data);
    return res;
  } catch (e: unknown) {
    handleError(e);
  }

  return null;
};

export const patch = async ({ url, data }: ApiRequest) => {
  url = `/${url}`;

  try {
    const res = await Axios.patch(url, data);
    return res.data;
  } catch (e: unknown) {
    handleError(e);
  }
};

/*
const patchForRes = (auth, url, data, cb) => {
  url  = `${baseUrl}/${url}`;

  try {
    const request = fetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });

    request.then(response => {
      handleError(response)
      response.json().then(res => {
        cb(res)
      })
    })
  } catch (e) {
    console.log(e.message)
  }
}

const patchForResAsync = async (auth, url, data) => {
  url  = `${baseUrl}/${url}`;

  try {
    const request = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });

    handleError(request)
    return await request.json()
  } catch (e) {
    console.log(e.message)
  }
}

const putForRes = async (auth, url, data) => {
  url  = `${baseUrl}/${url}`;

  try {
    const request = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });

    handleError(request)
    return await request.json()
  } catch (e) {
    console.log(e.message)
  }
}

const uploadFile = async (auth, type, data) => {
  const url = `${baseUrl}/admin/uploads?type=${type}`;

  try {
    const request = await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });

    handleError(request)
    return await request.json();
  } catch (e) {
    console.log(e.message)
  }
}

const deleteReq = (auth, url, id, cb) => {
  url  = `${baseUrl}/${url}/${id}`;

  try {
    const request = fetch(url, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });

    request.then(response => {
      handleError(response)
      response.json().then(res => {
        cb(res)
      })
    })
  } catch (e) {
    console.log(e.message)
  }
}

const deleteReqAsync = async (auth, url, id) => {
  url  = `${baseUrl}/${url}/${id}`;

  try {
    const request = await fetch(url, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });

    handleError(request)
    return await request.json();
  } catch (e) {
    console.log(e.message)
  }
}*/

const handleError = (e: unknown, cb = null) => {
  if (!axios.isAxiosError(e)) return;

  if (e.response) {
    const res: AxiosResponse = e.response;

    if (res.status >= 500) {
      toast.error("An unknown error occurred");
    } else if (res.status === 403) {
      toast.error("Permission Denied");
    } else if (res.status === 401) {
      toast.error("Unauthorised");
      setLogout();
    } else {
      const data: IResponse = res.data;
      if (data) toast.error(data.message);
    }
  } else {
    console.log(e);
  }
};

const ApiCalls = {
  get,
  getList,
  getById,
  put,
  deleteReq,
  /*getById,
    getFetchList,
    getFetchListAsync,
    postForRes,
    postForResAsync,
    patchForRes,
    patchForResAsync,
    putForRes,
    uploadFile,
    deleteReqAsync*/
};

export default ApiCalls;
