import formatToCurrency from "config/formatAmount";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import group_default from "../../../assets/img/group_thumbnail.png";

function UnpaidGroups({ group }) {
  const navigate = useNavigate();

  return (
    <section className="w-full flex flex-col">
      <header className="relative mlg:mb-[90px] mmd:mb-[50px] mb-[122px] w-full flex flex-col mlg:gap-[20px] mmd:gap-[16px] gap-[26px]">
        <div className="font-AvenirltstdBook !w-full !text-left font-medium mmd:text-[17px] mlg:text-[20px] text-[24px] mlg:leading-[24px] leading-[35px] text-[#D9D9D9]">
          Group
        </div>
        <div className="flex mmd:!flex-col !flex-row mlg:gap-[60px] mmd:gap-[40px] gap-[80px] w-full">
          <div className="w-full flex flex-col !items-start">
            <div className="w-full font-angella mmd:text-[20px] mlg:text-[24px] text-[28px] mlg:leading-[30px] leading-[45px] text-d-blue mmd:mb-[16px] mlg:mb-[18px] mb-[20px]">
              {group?.name || group?.title || group?.subject}
            </div>
            <div className="w-full font-AvenirltstdBook mmd:text-[15px] mlg:text-[16px] text-[18px] ld:leading-[22px] leading-[35px] text-[#000] mmd:mb-[20px] mlg:mb-[30px] mb-[45px]">
              {group?.description}
            </div>
            <button
              onClick={() =>
                navigate(`/app/settings/payments`, {
                  state: {
                    data: {
                      id: group?.id,
                      price: group?.price,
                      type: "groups",
                    },
                  }
                })
              }
              className="rounded-[10px] bg-d-orange w-fit h-fit font-AvenirltstdBook font-extrabold mmd:text-[15px] text-[17px] leading-[28px] text-white mmd:py-[10px] py-[13px] mmd:px-[28px] px-[38px]"
            >
              Join Group
            </button>
          </div>

          <div className="shadow-lg w-full h-fit bg-white rounded-[10px] mmd:p-[8px] p-[12px]">
            <img
              loading="lazy"
              src={group?.thumbnail || group_default}
              alt=""
              className="object-cover w-full"
            />
          </div>
        </div>
      </header>

      <section className="mmd:gap-[20px] w-full mmd:pl-[16px] mlg:pl-[30px] pl-[45px] mmd:py-[16px] py-[20px] mmd:pr-[16px] mlg:pr-[10px] pr-[15px] flex mmd:!flex-col flex-row bg-white justify-between mmd:mb-[40px] mlg:mb-[55px] mb-[70px]">
        <div className="flex flex-col mmd:gap-[6px] mlg:gap-[8px] gap-10px mmd:border-r-0 border-r border-[#D9D9D9] w-full pt-[5px] mmd:pb-[8px] mlg:pb-[8px] pb-[10px] mmd:pr-[12px] mlg:pr-[14px] pr-[15px] font-AvenirltstdBook">
          <div className="text-[#000] mmd:text-[14px] mlg:text-[16px] text-[18px] mlg:leading-[20px] leading-[25px] w-full">
            Class Type
          </div>
          <div className="capitalize text-d-blue font-extrabold mmd:text-[16px] mlg:text-[18px] text-[20px] mlg:leading-[26px] w-full leading-[20px]">
            {group?.event_type || group?.payment_period} Class
          </div>
        </div>
        <div className="flex mmd:pl-0 mlg:pl-[20px] pl-[28px] flex-col mmd:gap-[6px] mlg:gap-[8px] gap-10px mmd:border-r-0 w-full border-r border-[#D9D9D9] pt-[5px] mmd:pb-[8px] mlg:pb-[10px] pb-[12px] mmd:pr-[16px] mlg:pr-[18px] pr-[20px] font-AvenirltstdBook">
          <div className="text-[#000] mmd:text-[14px] mlg:text-[16px] text-[18px] mlg:leading-[20px] leading-[25px] w-full">
            Duration
          </div>
          <div className="text-d-blue font-extrabold mmd:text-[16px] mlg:text-[18px] text-[20px] mlg:leading-[26px] w-full leading-[20px]">
            {(group?.duration || group?.preview_duration || "0") + "minutes" ||
              group?.payment_period}
          </div>
        </div>
        <div className="flex mmd:pl-0 mlg:pl-[20px] pl-[28px] flex-col mmd:gap-[6px] mlg:gap-[8px] gap-10px mmd:border-r-0 w-full border-r border-[#D9D9D9] pt-[5px] mmd:pb-[8px] mlg:pb-[10px] pb-[12px] mmd:pr-[16px] mlg:pr-[18px] pr-[20px] font-AvenirltstdBook">
          <div className="text-[#000] mmd:text-[14px] mlg:text-[16px] text-[18px] mlg:leading-[20px] leading-[25px] w-full">
            Ages
          </div>
          <div className="text-d-blue font-extrabold mmd:text-[16px] mlg:text-[18px] text-[20px] mlg:leading-[26px] w-full leading-[20px]">
            {group?.start_age} - {group?.end_age} year olds
          </div>
        </div>
        <div className="flex w-full mmd:justify-start justify-end mmd:pl-0 mlg:pl-[18px] pl-[22px]">
          <div className="flex !flex-col !items-center mmd:w-full w-fit rounded-[5px] bg-[#D21C28] mlg:px-[20px] mmd:px-[14px] px-[16px] mlg:py-[14px] mmd:py-[10px] py-[16px] font-AvenirltstdBook text-[#FFF]">
            <div className="mlg:text-[15px] w-full whitespace-nowrap mmd:text-[14px] text-[16px] mlg:leading-[20px] leading-[26px] mb-[2px]">
              Cost per learner
            </div>
            <div className="font-extrabold w-full whitespace-nowrap mlg:text-[20px] mmd:text-[18px] text-[22px] mlg:leading-[20px] leading-[24px]">
              {formatToCurrency(group?.price)}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default UnpaidGroups;
