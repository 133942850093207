export const Constants = {
  token: "Token",
  type: "Type",
  currentUser: "currentUser",
  temp: "temp",
};

export const Page = {
  dashboard: "/app",
  verify: "/auth/validation",
  setup: "/auth/setup",
  signup: "/auth/signup",
};

// export const URL = process.env.REACT_APP_ENV === "prod" ? "http://127.0.0.1:6543" : "http://127.0.0.1:6543";
export const URL = process.env.REACT_APP_ENV === "prod" ? "https://api.test.dyeka.ng" : "https://api.test.dyeka.ng";
