import React from "react";
import { formatPrice } from "../config/formatPrice";
import { Play } from "assets/svg";

function CourseCard({
  thumbnail,
  tutorName,
  description,
  live,
  period,
  duration,
  price,
  startAge,
  endAge,
  subject,
  bought,
  PlayClick,
}) {
  return (
    <section className="flex mb-[1rem] flex-col flex-grow mmd:w-auto w-auto h-fit shadow-md bg-white rounded-[10px] mmd:p-[10px] p-[14px]">
      <div
        onClick={PlayClick}
        className={`w-full cursor-pointer relative mmd:h-[135px] overflow-hidden h-[165px] flex justify-center items-center rounded-[10px] mb-[11px] bg-[#62BDD3]`}
      >
        <img src={thumbnail} alt="" className="w-full h-full object-cover" />
        <Play
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{ height: "70%", width: "70%", cursor: "pointer" }}
        />
      </div>

      <div className="flex !flex-row w-full !justify-between items-center font-AvenirltstdBook font-semibold mb-[15px]">
        <div className="text-[14px] leading-[17px] text-[#FF6C00] truncate">
          {subject || "- - -"}
        </div>
        <div className="text-[13px] leading-[25px] text-[#D21C28]">
          {tutorName}
        </div>
      </div>

      <div className="text-[#15345B] font-extrabold text-[16px] leading-[22px] mb-[25px] w-full flex item-center overflow-hidden">
        {description}
      </div>

      <div
        className={`${
          live ? "grid-cols-2" : "grid-cols-3"
        } w-full grid gap-[5px]`}
      >
        {!live && (
          <div className="w-full rounded-[5px] bg-[#93D2C7] text-[#15345B] p-[2px] flex flex-col items-center">
            <div className="text-center font-AvenirltstdBook font-medium text-[12px] items-center">
              Ages
            </div>
            <div className="text-center font-AvenirltstdBook font-extrabold text-[13px] items-center">
              {startAge || "0"} - {endAge || "-"}
            </div>
          </div>
        )}

        <div className="w-full rounded-[5px] bg-[#F9DD40] text-[#15345B] p-[2px] flex flex-col items-center">
          <div className="text-center font-AvenirltstdBook font-medium text-[12px] items-center">
            Duration
          </div>
          <div className="text-center font-AvenirltstdBook font-extrabold text-[13px] items-center">
            {period ? period : duration ? duration + " min" : "0 min"}
          </div>
        </div>

        {bought && price ? (
          <div className="w-full rounded-[5px] bg-[#93D2C7] text-[#15345B] p-[2px] flex flex-col items-center">
            <div className="text-center font-AvenirltstdBook font-medium text-[12px] items-center">
              Class Fee
            </div>
            <div className="text-center font-AvenirltstdBook font-extrabold text-[13px] items-center">
              PAID
            </div>
          </div>
        ) : (
          <div className="w-full rounded-[5px] bg-[#B61626] text-[#FFFFFF] p-[2px] flex flex-col items-center">
            <div className="text-center font-AvenirltstdBook font-medium text-[12px] items-center">
              Class Fee
            </div>
            <div className="text-center font-AvenirltstdBook font-extrabold text-[13px] items-center">
              {price ? formatPrice(price) : "N0"}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default CourseCard;
