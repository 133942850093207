import React from "react";

const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          background: "transparent",
          display: "block",
          shapeRendering: "auto",
        }}
        width={"50px"}
        height={"50px"}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#FF6C00"
          strokeWidth="5"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
        {/* [ldio] generated by https://loading.io/*/}
      </svg>
    </div>
  );
};

export default Loader;
