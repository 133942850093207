import { Play } from "assets/svg";
import React, { FC, useEffect, useState } from "react";
import styles from "./event.module.scss";
import { DateTime } from "luxon";
import { formatPrice } from "config/formatPrice";
import { Link } from "react-router-dom";

type Props = {
  img?: string;
  title: string;
  to?: string;
  type?: Type
  tutor: string;
  date: string;
  price?: number;
};

export enum Type { ongoing, past, upcoming }

const Event: FC<Props> = ({ img, to, title, type = Type.upcoming, tutor, date, price }) => {
  const [formattedDate, setFormattedDate] = useState<DateTime>();

  useEffect(() => {
    setFormattedDate(DateTime.fromISO(date));
  }, [date]);

  return (
    <div className={styles.card}>
      <div className={styles.img}>
        <img src={img} className="nav-img large" alt="dyeka ungoing event" />
        <div className={styles.playIcon}>
          <Play style={{ height: "100%", width: "100%" }} />
        </div>
      </div>
      <div>
        <div className="mt-10 text-dark">
          <div>
            <b className="font-[600]">{title}</b>
          </div>
          <div className="text-primary-color mt-5">
            <small className="font-[600]">Teacher: {tutor}</small>
          </div>
          <div className="text-primary-color mt-5">
            <small className="font-[600] text-[#D21c28]">
              Price: {formatPrice(price)}
            </small>
          </div>
          {formattedDate && (
            <div className="flex justify-content-between mt-10">
              <small className="text-dark">
                <b className="font-[600]">{formattedDate.toFormat("LLL dd")}</b>
              </small>
              <small className="text-dark">
                <b className="font-[600]">
                  {formattedDate.toFormat("hh:mm a")}
                </b>
              </small>
            </div>
          )}
        </div>
      </div>
      <Link className="btn bg-primary mt-6" style={{ gridColumn: '1 / span 3' }} to={`${to || '#'}`}>{type === Type.ongoing ? 'Join Now' : 'View Class'}</Link>
    </div>
  );
};
export default Event;
