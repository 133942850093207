import React from "react";
import turnInImg from "../../../assets/img/school-material-writer-svgrepo-com 2.png";
import { Button } from "components";
import pdf_img from "../../../assets/img/file-pdf-svgrepo-com 1.png";
import { useNavigate, useParams } from "react-router-dom";

function AddAttachment() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  return (
    <div className="flex md:flex-row justify-between flex-col w-full font-avenir text-[#15345B]">
      <section className="w-full mb-32 md:mb-0 md:w-[405px] block">
        <div
          className={`flex cursor-pointer justify-center items-center text-[10px] sm:text-[12px]
          w-[200px] h-[48px] rounded-[12px] bg-[#15345B] text-[#FFFFFF]`}
        >
          CLASS WORK
        </div>

        <div className="flex flex-row font-avenir items-center justify-between mt-[77px] mb-[25px]">
          <div className="text-[#D21C28] font-extrabold text-[15px]">Turn-in your work</div>

          <span className="text-[#15345B font-normal text-[10px]">Pending</span>
        </div>

        <div className="bg-[#96D9C9] mb-[28px] px-[28px] py-[6px] rounded-[10px] w-full h-[54px] text-white text-[10px] font-avenir">
          <div className="font-extrabold mb-2">INTRODUCTION TO MATHEMATICS</div>
          <div className="font-normal">Posted 12 jan, 10:17</div>
        </div>

        <div className="text-[12px] mt-[20px] cursor-pointer font-extrabold text-[#FF6C00] w-full text-center">+ Add Attachment</div>
      </section>

      <section className="flex w-full md:w-[307px] flex-col items-center">
        <div className="flex mb-[22px] w-full flex-col px-[90px] py-[11px] items-center">
          <img src={turnInImg} alt={"Turn-in your work"} height={61} width={56} />
        </div>

        <Button
          type="button"
          variant="block"
          size="medium"
          children={<div className="text-white text-[12px] font-black">TURN IN</div>}
          onClick={() => {
            navigate(`/app/work-to-do/${id}`);
          }}
        />
      </section>
    </div>
  );
}

export default AddAttachment;
