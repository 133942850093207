import { toast } from "react-toastify";


export const appHelpers = {


    alertError: (message: string, duration: number = 3700) => {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: duration,
        });
    },
    alertSuccess: (message: string, duration: number = 3700) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: duration,
        });
    },
    alertWarning: (message: string, duration: number = 3700) => {
        toast.warn(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: duration,
        });
    },
    truncateValue: (email: string, length: number = 25) => {
        if (email) {
            if (email.length > length) {
                return email.substring(0, length) + "...";
            }
            return email;
        } else {
            return "";
        }
    },

    removeCommaAndDot: (value: string) => {
        if (value) {
            var regex = /[.,]/g;

            return value.replace(regex, '');
        }
    },
    removeCommaAndDotAndSpace: (value: string) => {
        if (value) {
            var regex = /[.,\s]/g;

            return value.replace(regex, '');
        }
    },

    formatCurrency: (value: string | number): string => {

        value = Number(value)
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

        return '₦' + formatter.format(parseFloat(value.toFixed(2)))
    }
}