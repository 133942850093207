import { useContext, useEffect } from "react";
import MainLayoutContext from "../../../../context/main-layout";
import "./notification.scss";
import { notificationAction } from "../../../../core/actions";
import { toast } from "react-toastify";

const Notification = () => {
  const { setLoading, settings, setSettings } = useContext(MainLayoutContext);

  const toggleInAppNotification = async () => {
    setLoading(true);
    const in_app_notification = settings.in_app_notification;
    const res = await notificationAction.toggle(!in_app_notification);
    console.log(res);
    if (res) {
      if (res.success) {
        toast.success("settings updated");
        setSettings(res.data);
      } else toast.error(res.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    console.log(settings);
  }, [settings]);

  return (
    <div className="notifications-page">
      <div className="notification-toggle-cover">
        <div className="row">
          <div className="col-auto me-auto">Receive notifications</div>
          <div className="col-auto">
            <label className="switch">
              <input
                type="checkbox"
                id=""
                checked={settings.in_app_notification}
                onChange={toggleInAppNotification}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
