import React from "react";
import propTypes from "prop-types";
import _ from "lodash";

const Pagination = ({ onSelectPage, size, total, page: currentPage }) => {
  const pageCount = Math.ceil(total / size);
  const pages = _.range(1, pageCount + 1);
  return (
    <React.Fragment>
      <nav>
        <ul className="pagination">
          {pages.map((page) => {
            return (
              <li
                style={{ cursor: "pointer" }}
                className={
                  page === currentPage ? "page-item active" : "page-item"
                }
                key={page}
              >
                <a className="page-link" onClick={() => onSelectPage(page)}>
                  {page}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  onSelectPage: propTypes.func.isRequired,
  size: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  page: propTypes.number.isRequired,
};

export default Pagination;
