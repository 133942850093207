import React, { useContext, useEffect, useState } from "react";
import MainLayoutContext from "context/main-layout";
import { appHelpers } from "core/utils/helpers";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../dashboard/dashboard.module.scss";
import { DashboardInitialResultData } from "types/dashboard";
import { BiChevronDown } from "react-icons/bi";
import { Course, Loader, UngoingCourse } from "components";
import MyLearningCard from "components/mylearning-card";
import favoriteImg from "../../../assets/img/favourite.png";
import livestreamImg from "../../../assets/img/livestream.png";
import medalImg from "../../../assets/img/medal.png";
import mediaImg from "../../../assets/img/media.png";
import studyImg from "../../../assets/img/study_group.png";
import workCaseImg from "../../../assets/img/work-case.png";
import { fakeData as values } from "../../../core/data";
import { MyLearningCardtype } from "types/card";
import Barchart from "components/charts/Barchart";
import DoughnutChart from "components/charts/DoughnutChart";
import MedalPage from "../medals/index";
import { useMutation } from "react-query";
import { handleError } from "core/utils/error-handler";
import { GetMyLearningApiService } from "core/service/mylearning";
import { AxiosResponse } from "axios";
import { IResponse } from "types/responses";
import TestMini from "components/test";
import CourseCard from "components/CourseCard";
import PendingHomeworkCard from "components/PendingHomeworkCard";
import RecordingCard from "components/RecordingCard";
import { GetLikedVideos } from "core/service/likedvideso";

function LikedVideos() {
  const { updateHeading } = useContext(MainLayoutContext);
  const navigate = useNavigate();
  const [likedVideos, setLikedVideos] = useState(null);
  // const [loading, setLoading] = useState(true);

  const { mutate } = useMutation(GetLikedVideos, {
    onSuccess: (res) => {
      const { success, message, data } = res.data;
      if (success) {
        setLikedVideos(data?.result);
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      alert(response.message);
    },
  });

  useEffect(() => {
    updateHeading({
      header: "Liked Videos",
      subHeader: "Here, you will find all your most preferred courses.",
    });
    mutate();
  }, []);

  const goToLesson = async (id) => {
    navigate(`/app/lesson/${id}`);
  };

  // const handleSeeAll = (value) => {
  //   value?.description?.includes("Liked")
  //     ? history.push(`/app/liked-videos`)
  //     : history.push({
  //         pathname: `/app/course/${appHelpers
  //           .removeCommaAndDotAndSpace(value?.description)
  //           ?.toLocaleLowerCase()}`,
  //         state: {
  //           ...value,
  //         },
  //       });
  // };

  // console.log(likedVideos);

  const renderCourseVideos = (likedVideos) => {
    const courseElems = [];
    for (const [key, value] of Object.entries(likedVideos)) {
      // console.log(value);
      courseElems.push(
        <div key={`item-${key}-`}>
          {!value?.data
            ? null
            : value?.data?.length !== 0 && (
                <>
                  {/* <div className="flex justify-content-between mt-20 mb-10">
                    <div className="red-header">
                      <b>{appHelpers.removeCommaAndDot(value?.description)}</b>
                    </div>
                    <small
                      className="text-primary-color cursor-pointer"
                      onClick={() => handleSeeAll(value)}
                    >
                      See All
                    </small>
                  </div> */}
                  {value?.description?.includes("Medal") ? (
                    <div className={styles.column_2}>{value?.data?.certificates?.certificate_url && null}</div>
                  ) : value?.description?.includes("Recordings") ? (
                    <div className={styles.column_4}>
                      {value?.data?.map((data) => (
                        <RecordingCard key={`lesson-${data.id}`} data={data} />
                      ))}
                    </div>
                  ) : value?.description?.includes("Homework") ? (
                    <div className={styles.column_4}>
                      {value?.data?.map((work) => (
                        <PendingHomeworkCard key={`lesson-${work.id}`} work={work} />
                      ))}
                    </div>
                  ) : value?.type === "videos" ? (
                    <div className={styles.column_4}>
                      {value?.data &&
                        Array.isArray(value?.data) &&
                        value?.data
                          ?.slice(0, 3)
                          ?.map((item, index) => (
                            <UngoingCourse img={item.thumbnail} key={index} percentage={item?.video_history?.percentage_watched} title={item.title} description={appHelpers.truncateValue(item?.description)} subject={item.subject} tutor={item.tutor_name} PlayClick={() => goToLesson(item.id)} />
                          ))}
                    </div>
                  ) : (
                    <div className={styles.column_4}>
                      {value?.data &&
                        Array.isArray(value?.data) &&
                        value?.data
                          ?.slice(0, 4)
                          .map((item, index) => (
                            <CourseCard
                              PlayClick={() => goToLesson(item.id || item?.live_event?.id || item?.lesson?.id)}
                              bought={item?.bought}
                              period={item?.payment_period}
                              key={index}
                              thumbnail={item.thumbnail || item?.live_event?.thumbnail || item?.lesson?.thumbnail}
                              tutorName={item.tutor_name || item?.live_event?.tutor_name || item?.lesson?.tutor_name || (item?.tutor?.first_name && `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)}
                              description={appHelpers.truncateValue(item?.description || item?.live_event?.description || item?.lesson?.description, 45)}
                              live={true}
                              duration={item?.duration || item?.preview_duration || item?.live_event?.preview_duration || item?.lesson?.preview_duration}
                              price={item?.price || item?.live_event?.price || item?.lesson?.price}
                              startAge={""}
                              endAge={""}
                              subject={item?.title || item?.name || item?.subject || item?.live_event?.subject || item?.lesson?.subject || item?.lesson?.name}
                            />
                          ))}
                    </div>
                  )}{" "}
                </>
              )}
        </div>
      );
    }
    return courseElems;
  };

  return (
    <div className={styles.layout}>
      <>
        {!likedVideos && <Loader />}
        {likedVideos && renderCourseVideos(likedVideos)}
      </>
    </div>
  );
}

export default LikedVideos;
