import React, { useRef } from "react";
import { Chart, CategoryScale, ArcElement, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

function Barchart({ xAxis, yAxis, bgColor }) {
  const barRef = useRef();

  const maxNumber = Math.max(...yAxis);
  const totalNumber = Number((maxNumber + 20)?.toFixed(0));

  const barData = {
    labels: xAxis,
    datasets: [
      {
        label: "",
        data: yAxis,
        backgroundColor: bgColor,
        borderRadius: 7,
        borderSkipped: false,
        categoryPercentage: 0.5,
      },
      {
        label: "",
        data: ["8000", "8000", "8000", "8000", "8000", "8000"],
        backgroundColor: "#F2F2F2",
        borderRadius: 7,
        borderSkipped: false,
        barPercentage: 0.8,
        categoryPercentage: 0.8,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 0,
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        border: {
          color: "#F1F1F1",
        },
        ticks: {
          padding: 12,
          color: "#5F6868",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#F1F1F1",
          lineWidth: 0.5,
          drawTicks: false,
        },
        max: totalNumber,
        min: 0,
        grace: 1,
        ticks: {
          padding: 15,
          stepSize: (totalNumber / 4)?.toFixed(0),
          color: "#C1C0C0",
        },
        border: {
          display: false,
          width: 0,
        },
      },
    },
  };

  return (
    <div className="w-full h-full">
      <Bar options={options} data={barData} ref={barRef} />
    </div>
  );
}

export default Barchart;
