import React, { FunctionComponent } from "react";
import Avatar, { ReactAvatarProps } from "react-avatar";
import { CSSProperties } from "react";

interface AvatarProps extends ReactAvatarProps {
  firstName?: string;
  lastName?: string;
  imgSrc?: string;
  imgClassName?: string;
  style?: CSSProperties;
  bgcolor?: string;
  addEdit?: boolean;
}

const CustomAvatar: FunctionComponent<AvatarProps> = ({
  onClick,
  bgcolor = "#F4F3F3",
  style,
  imgSrc,
  imgClassName,
  firstName,
  lastName,
  ...rest
}) => {
  return (
    <Avatar
      onClick={onClick}
      round
      color={bgcolor}
      textSizeRatio={0.1}
      textMarginRatio={0.1}
      fgColor="#FF6C00"
      className={imgClassName}
      src={imgSrc}
      name={`${lastName} ${firstName}`}
      {...rest}
      style={{
        ...style,
        ...{
          // other custom styles
        },
      }}
    />
  );
};
export default CustomAvatar;
