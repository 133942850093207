import { Eye, Loading, LoginIllustration } from "assets/svg";
import { AxiosResponse } from "axios";
import { AuthLayout, Button } from "components";
import AuthContext from "context/auth";
import UserContext from "context/user";
import { LoginApiService } from "core/service/auth";
import { handleError } from "core/utils/error-handler";
import { appHelpers } from "core/utils/helpers";
import useForm from "core/utils/use-form";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { IResponse } from "types/responses";
import { ILogin, IUser } from "types/user";
import { emailRegex } from "utils";

const Login = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("password");

  const togglePassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const { setAuthAndCache } = useContext(AuthContext);
  const { updateCurrentUser } = useContext(UserContext);

  const { mutate, isLoading } = useMutation(LoginApiService, {
    onSuccess: (res: AxiosResponse<IResponse<IUser>>) => {
      const { success, data } = res.data;
      if (success) {
        setAuthAndCache(`${`Bearer`} ${data?.token}`);
        updateCurrentUser(data);
        navigate("/app/profile");
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      if (response === undefined) {
        appHelpers.alertError("Check your network and try again");
      }
      if (response) {
        appHelpers.alertError(response?.message);
      }
    },
  });

  const submit = () =>
    !emailRegex.test(inputs.email as string)
      ? alert("Enter Valid Email")
      : mutate(inputs);
  const initState = { email: "", password: "", device_token: "random" };
  const { inputs, handleChange, handleSubmit } = useForm<ILogin>(
    initState,
    submit
  );

  return (
    <AuthLayout
      color="red"
      welcomeText="Welcome back"
      intro="The teacher is waiting for you, let’s get you back in class"
      formDescription=" "
      logoSideText="Login to"
      navigationLabel="Don’t have an account?"
      navigationName="Sign up"
      navigateTo="/auth/signup"
      illustration={LoginIllustration}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            maxLength={50}
          />
        </div>
        <div className="form-group password">
          <label>Password</label>
          <input type={type} name="password" onChange={handleChange} />
          <div className="show-password" onClick={togglePassword}>
            <Eye />
          </div>
        </div>
        <div className="flex justify-content-between">
          <label className="flex">
            <input type="checkbox" className="mr-10" />
            <p className="mt-5 mb-0">Remember me</p>
          </label>
          <Link to="/auth/forgot-password" className="text-primary-color">
            Forgot Password?
          </Link>
        </div>
        <div className="mt-40">
          <Button
            type="submit"
            disabled={!inputs.email || !inputs.password || isLoading}
          >
            {isLoading ? <Loading /> : "LOGIN"}
          </Button>

          <div className="mt-30">
            <span
              style={{ cursor: "pointer" }}
              className="text-primary-color font-medium text-[16px]"
              onClick={() => navigate(-1)}
            >
              Back
              {/* <i className="fa fa-chevron-left" aria-hidden="true"></i>
              <i className="fa fa-chevron-left" aria-hidden="true"></i> */}
            </span>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
