import { useContext, useEffect, useState } from "react";
import styles from "./live-stream.module.scss";
import { Event } from "components";
import MainLayoutContext from "context/main-layout";
import livestreamBg from "../../../assets/img/livestream-bg.png";
import { livestreamAction } from "../../../core/actions";
import { LivestreamData } from "../../../types/livestream";
import { DateTime } from "luxon";
import { Type } from "components/event";

const LiveStream = () => {
  const { updateHeading } = useContext(MainLayoutContext);
  const [groupedLivestreams, setGroupedLivestreams] = useState<{
    [key: string]: any;
  }>({});
  const [selectedDate, setSelectedDate] = useState("");
  const [ongoing, setOngoing] = useState<LivestreamData[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchLivestream = async () => {
    setLoading(true);
    const res = await livestreamAction.fetchMine(1);
    if (!res) return;

    setLoading(false);
    const data = res.data.result.length ? res.data.result : [];
    data.sort(
      (a: any, b: any) =>
        new Date(a.event_start_time).getTime() -
        new Date(b.event_start_time).getTime()
    );
    const ongoing: LivestreamData[] = []
    const formatted = data.reduce(
      (l: { [key: string]: LivestreamData[] }, r: LivestreamData) => {
        const date = DateTime.fromISO(r.event_start_time).toFormat(
          "dd-MM-yyyy"
        );
        l[date] = l[date] ? l[date] : [];
        l[date].push(r);
        const [event_start_time, event_close_time] = [DateTime.fromISO(r.event_start_time), DateTime.fromISO(r.event_close_time)]
        if (event_start_time.diffNow().milliseconds <= 0 && event_close_time.diffNow().milliseconds >= 0) { ongoing.push(r) }
        return l;
      },
      {}
    );
    setOngoing(ongoing)
    setGroupedLivestreams(formatted);
  };

  useEffect(() => {
    fetchLivestream().then();
    updateHeading({
      header: "Live Stream Events",
      subHeader: "See all your upcoming and ongoing Live stream classes here",
    });
  }, []);

  useEffect(() => {
    const dates = Object.keys(groupedLivestreams);
    if (dates?.length > 0) setSelectedDate(dates[0]);
  }, [groupedLivestreams]);
  return (
    <div className={styles.main}>
      <div>
        {selectedDate === "" ?
          <section>
            <div className={`${styles.livestream} flex justify-center`}>
              <img src={livestreamBg} alt="livestream-bg" />
            </div>
            <div className={styles.title}>
              <span>You do not have any streams at the time.</span>
            </div>
          </section> : <>
            <section className="fw-bold ms-2 mb-4 text-primary">
              {selectedDate && DateTime.fromFormat(selectedDate, "dd-MM-yyyy").toLocaleString({
                weekday: "long",
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </section>
            <section className={styles.calender}>
              {Object.keys(groupedLivestreams).map((date, id) => {
                const d = DateTime.fromFormat(date, "dd-MM-yyyy");
                const today = DateTime.local();
                return <div
                  key={id}
                  className={date === selectedDate ? styles.active : styles.all}
                  onClick={(e) => setSelectedDate(date)}
                >
                  {d.hasSame(today, "day") ? (
                    <div>Today</div>
                  ) : (
                    <div>
                      <div>
                        <b>{d.day}</b>
                      </div>
                      <small>{d.toLocaleString({ weekday: "long" })}</small>
                      {/* <small>{d.monthShort}</small> */}
                    </div>
                  )}
                </div>
              })}
            </section>
            <section>
              <div className="mt-50 mb-30">
                <p className="red-header">
                  <b>Ongoing Events</b>
                </p>
              </div>
              <div className={styles.main}>
                <div className={styles.events}>
                  {ongoing.length ?
                    ongoing.map((lv: LivestreamData, i: number) => (
                      <Event key={i}
                        title={lv.title}
                        to={`${lv.id}`}
                        type={Type.ongoing}
                        tutor={lv.tutor_name}
                        img={lv.thumbnail}
                        date={lv.event_start_time}
                        price={lv.price}
                      />
                    )):<div className="ms-4"> No ongoing events</div>}
                </div>
              </div>
            </section>
            <section>
              <div className="mt-50 mb-30">
                <p className="red-header">
                  <b>Upcoming Events</b>
                </p>
              </div>
              <div className={styles.main}>
                <div className={styles.events}>
                  {selectedDate !== "" &&
                    groupedLivestreams[selectedDate].map((lv: LivestreamData, i: number) => (
                      <Event key={i}
                        title={lv.title}
                        to={`${lv.id}`}
                        tutor={lv.tutor_name}
                        img={lv.thumbnail}
                        date={lv.event_start_time}
                        price={lv.price}
                      />
                    ))}
                </div>
              </div>
            </section>
          </>}

      </div>
    </div>
  );
};

export default LiveStream;
