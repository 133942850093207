import MainLayoutContext from "context/main-layout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Axios from "core/service/axios";
import { handleError } from "core/utils/error-handler";
import { Loader } from "components";
import UnpaidClass from "./UnpaidClass";

function PrivateClassPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [private_classs, setPrivateClass] = useState({});
  const { updateHeading } = useContext(MainLayoutContext);
  const [loading, setLoading] = useState(true);
  const { classid } = useParams();

  const private_class = location?.state;

  const fetchPrivateClass = async (id) => {
    setLoading(true);

    Axios.get(`/live/${id}`)
      .then((res) => {
        setLoading(false);
        const { success, data } = res.data;
        if (success) {
          setPrivateClass(data);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        const { response } = handleError(err);
        alert(response.message);
      });

    setLoading(false);
  };

  useEffect(() => {
    updateHeading({
      header: `Private Class`,
      subHeader:
        "You are doing great this week. Your teachers are proud of you. Keep it up!",
    });

    fetchPrivateClass(classid);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && private_class && (
        <UnpaidClass private_class={private_class} />
      )}
    </>
  );
}

export default PrivateClassPage;
