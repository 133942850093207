import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Select, { SingleValue, GroupBase, OptionsOrGroups } from "react-select";
import { lessonAction } from "../../core/actions";
import { useNavigate } from "react-router-dom";
import { LessonData } from "types/lesson";
import { SecureStorage } from "core/utils/storage";
import { IProfile } from "types/profiles";
import { Search } from "assets/svg";

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    fontFamily: "Avenir, san-serif",
    fontSize: 15,
    boxShadow: state.isFocused ? 0 : 0,
    cursor: "text",
    borderRadius: 8,
    borderColor: state.isFocused ? "#ff6c00" : "#15345b",
  }),

  option: (styles: any, { isFocused }: any) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: isFocused ? "white" : "white",
      color: isFocused ? "#ff6c00" : "#15345b",
      lineHeight: 2,
    };
  },

  input: (styles: any) => ({
    ...styles,
    color: "#15345b",
    fontFamily: "Avenir, san-serif",
  }),

  menu: (styles: any) => ({
    ...styles,
    marginTop: 0,
    boxShadow: "none",
    borderRadius: 0,
  }),

  singleValue: (styles: any) => ({
    ...styles,
    color: "#15345b",
  }),
};

const SearchBar = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] =
    useState<SingleValue<{ value: string; label: string }>>();
  const [options, setOptions] = useState<
    OptionsOrGroups<
      {
        value: string;
        label: string;
      },
      GroupBase<{
        value: string;
        label: string;
      }>
    >
  >();

  const handleChange = (
    selectedOption: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    setSelectedOption(selectedOption);
    navigate(`/app/lesson/${selectedOption?.value}`);
  };

  useEffect(() => {
    const secureStorage = new SecureStorage();
    setSelectedOption(null);

    const value = secureStorage.getItem("selectedProfile");
    if (value) {
      const selectedProfile: IProfile = JSON.parse(value);
      lessonAction.classLessons(selectedProfile?.class_id).then((res) => {
        const lessons: LessonData[] = res?.data.result;
        const options = lessons.map((lesson) => {
          return {
            value: `${lesson.id}`,
            label: lesson.name,
          };
        });

        setOptions(options);
      });
    }
  }, []);

  return (
    <div>
      <Search className="prefix-icon" />
      <Select
        value={selectedOption}
        options={options}
        onChange={handleChange}
        styles={customStyles}
        placeholder="Search any keyword"
        openMenuOnClick={false}
        classNamePrefix="select"
      />
    </div>
  );
};

export default SearchBar;
