import './card.scss'
import {Card as CardType} from "../../types/card";
import mastercard from "assets/img/mastercard.png";

type Props = {
  card: CardType
  onClick: (id?: number) => void,
  className?: string
}

const Card = ({ card, onClick, className }: Props) => {
  return (
    <div className={`payment-card card ${className}`} onClick={() => onClick(card.id)} >
      <div className="d-flex align-items-center">
        <div className="img">
          <img src={mastercard} alt="card" />
        </div>
        <p className="ms-auto">{ card.last4 }</p>
      </div>
    </div>
  )
}

export default Card;

