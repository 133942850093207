import { ILogin, IPassword, IUser } from 'types/user';
import Axios from './axios';

export const SignUpApiService = (credentials: IUser) => {
  return Axios.post('/auth/signup', credentials);
};

export const LoginApiService = (credentials: ILogin) => {
  return Axios.post('/auth/login', credentials);
};

export const ChangePasswordApiService = (credentials: IPassword) => {
  return Axios.post('/auth/password', credentials);
};
