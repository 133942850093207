import GroupsCard from "components/GroupsCard";
import MainLayoutContext from "context/main-layout";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { GetGroupApiService } from "core/service/groups";
import { handleError } from "core/utils/error-handler";
import { Button, Loader } from "components";
import { DUMMY_DATA } from "config/dummyData";
import Axios from "core/service/axios";
import styles from "./groups.module.scss";
import hand_png from "../../../assets/img/hand.png";
import FilterBox from "components/FilterBox";
import CourseCard from "components/CourseCard";
import { appHelpers } from "core/utils/helpers";

function Groups() {
  const navigate = useNavigate();
  const { updateHeading } = useContext(MainLayoutContext);

  const [group_data, setGroupData] = useState([]);
  const [subject, setSubject] = useState("");
  const [price, setPrice] = useState("");
  const [curriculum, setCurriculum] = useState("");
  const [slot, setSlot] = useState("");
  const [topic, setTopic] = useState("");
  const [loading, setLoading] = useState(false);

  const [rawSubject, setRawSubject] = useState([]);

  const getGroupsData = () => {
    setLoading(true);
    Axios.get(
      `/groups?page=1${subject ? "&subject_id=" + subject : ""}${price ? "&price=" + price : ""
      }${curriculum ? "&curriculum=" + curriculum : ""}${topic ? "&topic=" + topic : ""
      }${slot ? "&slot=" + slot : ""}`
    )
      .then((res) => {
        setLoading(false);
        const { success, data } = res.data;
        if (success) {
          setGroupData(data.result);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        const { response } = handleError(err);
        alert(response.message);
      });
  };

  useEffect(() => {
    updateHeading({
      header: `Join a Group Today`,
      subHeader:
        "You are doing great this week. Your teachers are proud of you. Keep it up!",
    });
    getGroupsData();
    getSubjects();
  }, []);

  const handleSearch = () => {
    getGroupsData();
  };

  const getSubjects = () => {
    Axios.get(`/subjects`)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          setRawSubject(data.result);
          return;
        }
      })
      .catch((err) => {
        const { response } = handleError(err);
        alert(response.message);
      });
  };

  const goToGroup = async (value) => {
    navigate(`/app/groups/${value?.id}`, {
      state: {
        ...value,
      },
    });
  };

  return (
    <>
      {/* <section className="w-full flex bg-d-blue flex-col mmd:pt-[20px] pt-[50px] mmd:pl-[20px] mlg:pl-[60px] pl-[109px] mmd:pr-[20px] mlg:pr-[80px] pr-[120px] mmd:pb-[20px] mlg:pb-[52px] pb-[62px]  mmd:mb-[50px] mb-[92px]">
        <div className="w-full block">
          <div className="mid:!bg-transparent z-30 bg-white py-[12px] rounded-[5px] mid:px-0 mid:pr-[15px] px-[14px] flex mid:!flex-col mid:gap-[18px] flex-row items-center">
            <div className="border-r mid:rounded-[7px] mid:px-[16px] mid:py-[14px] mid:bg-white z-30 mid:border-r-0 w-full h-full mlg:gap-[10px] gap-[29px] border-[#D9D9D9] mlg:pr-[14px] pr-[28px] flex flex-row items-center">
              <svg
                width="28px"
                height="29px"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4816 26.0257L22.1244 20.761C24.122 18.56 25.363 15.6451 25.363 12.4626C25.363 5.5918 19.673 0 12.6815 0C5.69002 0 0 5.5918 0 12.4626C0 19.3334 5.69002 24.9252 12.6815 24.9252C15.1634 24.9252 17.4635 24.2113 19.4006 23.0216L24.9695 28.4944C25.3025 28.8216 25.7564 29 26.2105 29C26.6644 29 27.1185 28.8216 27.4515 28.4944C28.1777 27.8103 28.1777 26.7097 27.4816 26.0257L27.4816 26.0257ZM3.54108 12.4626C3.54108 7.52514 7.62711 3.48006 12.6814 3.48006C17.7358 3.48006 21.7915 7.52519 21.7915 12.4626C21.7915 17.4001 17.7055 21.4452 12.6814 21.4452C7.65737 21.4452 3.54108 17.4297 3.54108 12.4626Z"
                  fill="#D9D9D9"
                />
              </svg>

              <select
                defaultValue={groups_type}
                onChange={(e) => setGroupsType(e.target.value)}
                name="groups_type"
                placeholder="Group"
                className="border-0 outline-0 h-full w-full bg-white font-AvenirltstdBook mmd:text-[14px] text-[16px] leading-[20px] text-[#000]"
              >
                <option value="">Group</option>
                <option value="Group 1">Group 1</option>
              </select>
            </div>

            <input
              value={age}
              onChange={(e) => setAge(e.target.value)}
              name="age"
              placeholder="Age"
              className="w-full mid:rounded-[7px] z-30 mid:px-[16px] mid:py-[18px]  mid:border-r-0 outline-0 h-full mlg:pl-[14px] pl-[28px] pr-[10px] font-AvenirltstdBook mmd:text-[14px] text-[16px] border-[#D9D9D9] leading-[20px] text-[#000] border-r"
            />
            <input
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              name="price"
              placeholder="Price"
              className="w-full z-30 mid:px-[16px] mid:py-[18px] mid:rounded-[7px] mid:border-r-0 outline-0 h-full mlg:pl-[14px] pl-[28px] pr-[10px] font-AvenirltstdBook mmd:text-[14px] text-[16px] border-[#D9D9D9] leading-[20px] text-[#000] border-r"
            />
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              name="date"
              placeholder="Date"
              className="w-full mid:rounded-[7px] z-30 mid:px-[16px] mid:py-[18px]  outline-0 h-full pl-[28px] pr-[10px] font-AvenirltstdBook mmd:text-[14px] text-[16px] leading-[20px] text-[#000]"
            />

            <button
              onClick={handleSearch}
              className="z-30 mid:w-full bg-d-orange rounded-[7px] py-[16px] px-[21px] font-AvenirltstdBook font-extrabold text-[15px] leading-[16px] text-[#F5F5F5] hover:bg-d-blue"
            >
              Search
            </button>
          </div>
        </div>
      </section> */}
      <div className={styles.layout}>
        <div className={styles.imageBgDashboard}>
          <div className=" msm:justify-start 2lg:justify-center mmd:!p-[16px] h-full mlg:w-full w-[100%] flex !items-center justify-between overflow-hidden rounded-[10px]">
            <div className="pl-[51px] mmd:pl-0 flex h-fit !items-start !flex-col gap-[31px]">
              <div className="text-d-blue md:text-[28px] text-[35px] !font-[800] font-avenir leading-[35px]">
                Collaborate with your
                <br className="2lg:hidden" /> friends today.
              </div>

              <Button
                type="button"
                className="max-w-[255px] h-[55px]"
                onClick={() => navigate("/app/groups")}
              >
                Join a Group
              </Button>
            </div>
          </div>

          <img
            src={hand_png}
            alt=""
            loading="lazy"
            className="object-cover mid:top-[-50px] 2lg:hidden absolute top-0 right-0"
          />
        </div>

        <div className="mb-[45px] w-full items-center gap-4 grid grid-cols-6 text-[10px] mlg:grid-cols-2 2lg:grid-cols-3 mid:grid-cols-1 sm:text-[12px] font-avenir">
          <FilterBox
            title={"Subject"}
            type="select"
            options={rawSubject}
            name={"subject"}
            value={subject}
            setValue={setSubject}
          />

          <FilterBox
            title={"Price"}
            type="input"
            options={[]}
            name={"price"}
            value={price}
            setValue={setPrice}
          />

          <FilterBox
            title={"Curriculum"}
            type="input"
            options={[]}
            name={"curriculum"}
            value={curriculum}
            setValue={setCurriculum}
          />

          <FilterBox
            title={"Slot"}
            type="input"
            options={[]}
            name={"slot"}
            value={slot}
            setValue={setSlot}
          />

          <FilterBox
            title={"Topic"}
            type="input"
            options={[]}
            name={"topic"}
            value={topic}
            setValue={setTopic}
          />

          <Button
            type="button"
            className="w-full h-[55px]"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>

        <>
          {loading ? (
            <Loader />
          ) : (
            <section className="mlg:gap-[25px] px-4 mmd:gap-[18px] w-full items-center gap-4 grid grid-cols-4 mlg:grid-cols-2 mid:grid-cols-1">
              {group_data?.map((item) => (
                // {DUMMY_DATA[2]?.data?.map((item) => (
                <CourseCard
                  PlayClick={() => goToGroup(item)}
                  period={item?.payment_period}
                  bought={item?.bought}
                  key={item?.id}
                  thumbnail={
                    item.thumbnail ||
                    item?.live_event?.thumbnail ||
                    item?.lesson?.thumbnail
                  }
                  tutorName={
                    item.tutor_name ||
                    item?.live_event?.tutor_name ||
                    item?.lesson?.tutor_name ||
                    (item?.tutor?.first_name &&
                      `${item?.tutor?.first_name} ${item?.tutor?.last_name}`)
                  }
                  description={appHelpers.truncateValue(
                    item?.description ||
                    item?.live_event?.description ||
                    item?.lesson?.description,
                    40
                  )}
                  live={true}
                  duration={
                    item?.duration ||
                    item?.preview_duration ||
                    item?.live_event?.preview_duration ||
                    item?.lesson?.preview_duration
                  }
                  price={
                    item?.price ||
                    item?.live_event?.price ||
                    item?.lesson?.price
                  }
                  startAge={""}
                  endAge={""}
                  subject={
                    item?.title ||
                    item?.name ||
                    item?.subject ||
                    item?.live_event?.subject ||
                    item?.lesson?.subject ||
                    item?.lesson?.name
                  }
                />
                // <GroupsCard
                //   value={{ ...item }}
                //   id={item?.id}
                //   title={item?.title}
                //   description={item?.description}
                //   price={item?.price}
                //   start_age={item?.start_age}
                //   end_age={item?.end_age}
                //   tutor={
                //     item?.tutor?.first_name
                //       ? `${item?.tutor?.first_name} ${item?.tutor?.last_name}`
                //       : "---"
                //   }
                //   thumbnail={item?.thumbnail}
                // />
              ))}
            </section>
          )}
        </>
      </div>
    </>
  );
}

export default Groups;
