import React from "react";
import formatAmount from "../config/formatAmount";
import { Play } from "assets/svg";

function RecordingCard({ data, inside }) {
  return (
    <section
      className={`flex mb-[1rem] flex-col flex-grow mmd:w-auto w-auto h-fit shadow-md ${
        inside ? "bg-[#96D9C9]" : "bg-white"
      } rounded-[10px] mmd:p-[10px] p-[14px]`}
    >
      <div
        // onClick={PlayClick}
        className={`w-full cursor-pointer relative mmd:h-[135px] overflow-hidden h-[165px] flex justify-center items-center rounded-[10px] mb-[11px] bg-[#62BDD3]`}
      >
        <img
          src={data?.event?.thumbnail}
          alt=""
          className="w-full h-full object-cover"
        />
        <Play
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{ height: "70%", width: "70%", cursor: "pointer" }}
        />
      </div>

      <div className="flex w-full items-center font-AvenirltstdBook font-normal mb-[19px]">
        <div className="text-[16px] leading-[17px] text-d-blue h-[44px]">
          {data?.event?.title || "- - -"}
        </div>
      </div>

      <div className="text-[#15345B] font-normal text-[13px] leading-[22px] mb-[21px] w-full flex item-center overflow-hidden">
        <span className="font-[600]">Teacher: </span>
        {data?.tutor_name || data?.event?.tutor_name}
      </div>

      <div className="w-full flex items-center justify-between gap-2 font-avenir text-[12px]">
        <div className="text-[#D21C28]">
          Views: {formatAmount(data?.views, true)}
        </div>
        <div>
          <span className="font-[600]">Duration: </span> {data?.duration}mins
        </div>
      </div>
    </section>
  );
}

export default RecordingCard;
