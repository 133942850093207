import React from "react";
import group_img from "../assets/img/Group 1000004363.png";
import { Link, useNavigate } from "react-router-dom";
import formatToCurrency from "config/formatAmount";

function GroupsCard({
  title,
  description,
  price,
  start_age,
  end_age,
  tutor,
  thumbnail,
  id,
  value,
}) {
  const navigate = useNavigate();

  const handleClick = (value) => {
    navigate(`/app/groups/${id}`, {
      state: {
        ...value,
      },
    });
  };

  return (
    <div
      // to={`/app/groups/${id}`}
      onClick={() => handleClick(value)}
      className="w-full mid:h-fit max-h-[288px] border rounded-[10px] border-[#5F5F5F80] mmd:gap-[20px] mlg:gap-[38px] gap-[46px] mmd:py-[10px] py-[14px] mmd:px-[10px] px-[23px] bg-white flex mid:!flex-col flex-row items-center"
    >
      <div className="h-full rounded-[7px] mid:w-full w-[380px]">
        <img
          src={thumbnail}
          alt={title}
          loading="lazy"
          className="h-full w-full rounded-[7px] object-cover"
        />
      </div>

      <div className="flex flex-col items-start w-full">
        <div className="w-full font-angella font-normal mid:text-[20px] mmd:text-[20px] mlg:text-[22px] text-[30px] leading-[35px] text-d-blue mmd:mb-[20px] mb-[25px]">
          {title}
        </div>
        <div className="font-avenir max-h-[81px] font-normal mmd:text-[14px] text-[15px] leading-[27px] text-[#000] mid:mb-[20px] mmd:mb-[25px] mb-[32px] opacity-50">
          {description}
        </div>

        <div className="w-full flex justify-between items-end">
          <button className="text-[#FFF] font-avenir font-extrabold mlg:text-[16px] mmd:text-[15px] text-[18px] leading-[25px] mmd:py-[8px] py-[11px] mid:px-[15px] mmd:px-[28px] mlg:px-[35px] px-[40px] rounded-[5px] bg-[#D21C28]">
            {formatToCurrency(price)}
          </button>

          <div className="flex justify-between items-end mid:gap-[17px] mmd:gap-[20px] mlg:gap-[25px] gap-[30px]">
            <div className="font-avenir font-extrabold mid:text-[12px] mmd:text-[14px] text-[15px] leading-[25px] text-[#000]">
              Ages {start_age || 0} - {end_age || 0}
            </div>

            <div className="font-avenir font-normal mid:text-[12px] mmd:text-[14px] text-[15px] leading-[25px] text-[#D21C28] opacity-50">
              {tutor}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupsCard;
