import React, { useState } from "react";
import medalImg from "../../../assets/img/medals.png";
import certificateImg from "../../../assets/img/certificate.png";
import ViewImage from "../../../components/ViewImage";
import { Bronze, Gold, Silver } from "assets/svg";
// import { AnimatePresence } from "framer-motion";
import { Document, Page, pdfjs } from "react-pdf";

interface MedalFC {
  medal: any;
  certificates: any;
}

function MedalPage({ medal, certificates }: MedalFC) {
  const [path, setPath] = useState("free");
  const [view, setView] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

  console.log("====================================");
  console.log({ certificates });
  console.log("====================================");
  return (
    <>
      {view && (
        // <AnimatePresence>
        <ViewImage close={() => setView(false)}>
          <img src={certificateImg} alt="" className="object-cover w-full h-full" />
        </ViewImage>
        // </AnimatePresence>
      )}

      <div className="flex flex-col lg:flex-row items-center w-full gap-[65px]">
        <div className="flex flex-col items-center gap-[25px] w-full mt-10">
          <div className="w-full flex flex-row justify-between h-fit">
            <div className="flex flex-col justify-center">
              <Gold />
              <div className="mt-[16px] text-center">
                <p className="text-14px font-750 leading-20 text-d-gray font-avenir">Gold</p>
                <p className="text-14px font-600 leading-20 text-d-l-blue mt-[8px]">
                  Learn <span className="font-750">3 days</span> in a week
                  <p>to earn Gold</p>
                </p>
              </div>
            </div>
            <div>
              <Silver />
              <div className="mt-[16px] text-center">
                <p className="text-14px font-750 leading-20 text-d-gray font-avenir">Silver</p>
                <p className="text-14px font-600 leading-20 text-d-l-blue mt-[8px]">
                  Learn <span className="font-750">2 days</span> in a week
                  <p>to earn Gold</p>
                </p>
              </div>
            </div>
            <div>
              <Bronze />
              <div className="mt-[16px] text-center">
                <p className="text-14px font-750 leading-20 text-d-gray font-avenir">Bronze</p>
                <p className="text-14px font-600 leading-20 text-d-l-blue mt-[8px]">
                  Learn <span className="font-750">1 day</span> in a week
                  <p>to earn Gold</p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-[25px] w-full">
          <div className="w-full h-fit bg-white">
            <iframe src={certificates?.certificate_url} className="object-cover w-full h-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default MedalPage;
