import React, { useEffect, useState } from "react";
import { Button } from "components";
import styles from "./changePassword.module.scss";
import { Eye, Loading } from "assets/svg";
import { IPassword, IUser } from "types/user";
import { AxiosResponse } from "axios";
import { ChangePasswordApiService } from "core/service/auth";
import { handleError } from "core/utils/error-handler";
import useForm from "core/utils/use-form";
import { useMutation } from "react-query";
import { IResponse } from "types/responses";
import { appHelpers } from "../../../../../core/utils/helpers";
import { isValid } from "../../../../../utils";

const ChangePassword = () => {
  const [type, setType] = useState(false);
  const [type1, setType1] = useState(false);
  const [type2, setType2] = useState(false);

  const { mutate, isLoading } = useMutation(ChangePasswordApiService, {
    onSuccess: (res: AxiosResponse<IResponse<IUser>>) => {
      const { success } = res.data;
      if (success) {
        appHelpers.alertSuccess("Successful");
        return;
      }
    },
    onError: (error) => {
      const { response } = handleError(error);
      appHelpers.alertError(response?.message);
    },
  });

  const submit = () => mutate(inputs);
  const initState = { old_password: "", new_password: "" };
  const { inputs, handleChange, handleSubmit, errors } = useForm<IPassword>(
    initState,
    submit,
    true
  );

  useEffect(() => {
    // console.log(errors)
  }, [errors]);

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className="form-group password">
          <label>Old Password</label>
          <input
            name="old_password"
            type={!type ? "password" : "text"}
            onChange={handleChange}
          />
          <div className="show-password" onClick={() => setType(!type)}>
            <Eye />
          </div>
        </div>
        <div className="form-group password">
          <label>New Password</label>
          <input
            name="new_password"
            type={!type1 ? "password" : "text"}
            onChange={handleChange}
            data-validation="notNull"
          />
          <div className="show-password" onClick={() => setType1(!type1)}>
            <Eye />
          </div>
          {errors.new_password && (
            <small className="error">{errors.new_password}</small>
          )}
        </div>
        <div className="form-group password">
          <label>Confirm New Password</label>
          <input type={!type2 ? "password" : "text"} id="confirmPwd" />
          <div className="show-password" onClick={() => setType2(!type2)}>
            <Eye />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            className="text-center mt-45"
            disabled={isLoading || !isValid(errors)}
          >
            {isLoading ? <Loading /> : "CHANGE PASSWORD"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
