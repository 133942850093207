import { DeleteIcon } from "assets/svg";
import mastercard from "assets/img/mastercard.png";
import styles from "./payment-method.module.scss";
import { useContext, useEffect, useState } from "react";

import { Button } from "components";
import Axios from "core/service/axios";

import { usePaystackPayment } from "react-paystack";
import UserContext from "context/user";
import { toast } from "react-toastify";

const PaymentMethods = () => {
  const publicKey =
    process.env.REACT_APP_ENV === "prod"
      ? "pk_live_c8fd0ef41657ab03934d2472d2ad00c169a83229"
      : "pk_test_bfb3f4f0268973067e31fe5d12b42cf0389f336a";
  const { currentUser } = useContext(UserContext);
  const config = {
    reference: new Date().getTime(),
    email: currentUser.email,
    amount: 5000,
    publicKey,
  };

  const initializePayment = usePaystackPayment(config);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    getAllCards();
  }, []);

  const getAllCards = () => {
    Axios.get("/cards").then((res) => setCards(res.data.data.result));
  };

  const handleCardDelete = (id) => {
    Axios.delete(`/cards/${id}`)
      .then(() => {
        toast.success("Card Deleted");
        getAllCards();
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    Axios.get(`/cards/verify?reference=${reference.reference}`)
      .then(() => {
        toast.success("Card Successfully added");
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.

    alert("Transaction Failed");
  };

  return (
    <div>
      <div className="mt-50">
        <p className="text-red">
          <b>Saved Cards</b>
        </p>
      </div>
      <div className="mt-40">
        {cards?.length
          ? cards.map((item) => (
              <div className="flex justify-content-between" key={item.id}>
                <div className={styles.creditCardOption}>
                  <input name="radio" type="radio" id="card1" />
                  <label htmlFor="card1">
                    <div className="flex justify-content-between">
                      <img src={mastercard} alt="card" />
                      <span>xxxx xxxx xxxx {item.last4}</span>
                    </div>
                  </label>
                </div>
                <div className="align-self-end mr-20">
                  <div
                    onClick={() => handleCardDelete(item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>

      <hr />

      <div className="flex justify-content-center" style={{ marginTop: 50 }}>
        <Button
          variant="stripped"
          className="bg-red"
          onClick={() => {
            initializePayment(onSuccess, onClose);
          }}
        >
          Add Card
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethods;
