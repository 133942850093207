import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./nav.module.scss";
import { IProfile } from "types/profiles";
import {
  Bell,
  Cog,
  Hamburger,
  PowerOffIcon,
  ProfileIcon,
} from "../../assets/svg";
import MainLayoutContext from "context/main-layout";
import { Link } from "react-router-dom";
import AuthContext from "context/auth";
import UserContext from "context/user";
import notification from "../../core/actions/notification";
import { SecureStorage } from "core/utils/storage";
import moment from "moment";
import SearchBar from "components/searchbar";

type Props = {
  onClick?: () => void;
};

const Nav: FC<Props> = ({ onClick }) => {
  const secureStorage = new SecureStorage();
  const selectedProfile: any = secureStorage.getItem("selectedProfile");
  const profile: IProfile = JSON.parse(selectedProfile);
  const { headings, notifications } = useContext(MainLayoutContext);
  const { setLogout } = useContext(AuthContext);
  const { removeLoggedOutUser, removeSelectedProfile } =
    useContext(UserContext);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);

  const logout = () => {
    setLogout();
    removeLoggedOutUser();
    removeSelectedProfile();
  };

  const toggleProfileDropdown = () => {
    if (!profileDropdown) {
      setProfileDropdown(true);
    } else {
      setProfileDropdown(false);
    }
  };

  const toggleNotificationDropdown = () => {
    if (!notificationDropdown) {
      setNotificationDropdown(true);
    } else {
      setNotificationDropdown(false);
    }
  };

  useEffect(() => {
    console.log(notification);
  }, [notifications]);

  return (
    <div className={styles.layout}>
      <div className={styles.hamburger}>
        <Hamburger onClick={onClick} />
      </div>
      <div className={styles.info}>
        <h3 className="mt-0 mb-0 text-primary-color">{headings.header}</h3>
        <small>{headings.subHeader}</small>

        {window.location.pathname.includes("/app/download") ? (
          <div className={styles.clearBtn}>Clear Downloads</div>
        ) : null}
      </div>
      <div>
        <div className="form-group">
          <SearchBar />
        </div>
      </div>
      <div className="flex">
        <Link to="/app/settings">
          <Cog className="mr-20 " />
        </Link>

        <div
          className={styles.notificationContainer}
          onClick={toggleNotificationDropdown}
        >
          <div className={styles.notification}>
            <Bell />
            <div className={styles.badge}>
              {notifications ? notifications.length : 0}
            </div>
          </div>
          {notificationDropdown ? (
            <div className={styles.dropdown}>
              {notifications.slice(0, 3).map((notification, id) => (
                <div className="mb-20">
                  <p className="text-dark">{notification.body}</p>
                  <small className="text-primary-color">
                    {moment(notification.created_on).format("LLL")}
                  </small>
                  <hr />
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className={styles.profileImg} onClick={toggleProfileDropdown}>
          <img src={profile?.photo_url} alt="" className="nav-img medium " />
          {profileDropdown ? (
            <div className={[styles.dropdown, "profile-dropdown"].join(" ")}>
              <div className="flex mb-10">
                <div>
                  <ProfileIcon />
                </div>
                <Link to="/app/profile">
                  <div className="ml-20 text-primary-color">Switch Profile</div>
                </Link>
              </div>
              <div className="flex">
                <div>
                  <PowerOffIcon />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="ml-20"
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Nav;
