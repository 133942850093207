import Tabs from "components/tabs";
import Tab from "components/tabs/tab";
import React, { useState } from "react";
import ChangePassword from "./change-password";

const Security = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handlesetSelectedTab = (value: number) => {
    setSelectedTab(value);
  };
  return (
    <div>
      <Tabs selectedTab={selectedTab} setSelectedTab={handlesetSelectedTab}>
        <Tab title="">
          <ChangePassword />
        </Tab>
        <Tab title=""></Tab>
      </Tabs>
    </div>
  );
};

export default Security;
