import React, { useEffect, useState } from "react";
import Wallet from "./wallet";
import PayWithCard from "./pay-with-card";
import Tabs from "components/tabs";
import Tab from "components/tabs/tab";
import { Card } from "../../../../types/card";
import { subscriptionAction } from "../../../../core/actions";
import PaymentHistory from "./payment-history";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "core/service/axios";
import { toast } from "react-toastify";

const Payment = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [balance, setBalance] = useState(0);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  let price = location?.state?.data?.price;
  let type = location?.state?.data?.type;
  let id = location?.state?.data?.id;

  const fetchPaymentMethods = async () => {
    const res = await subscriptionAction.cards();
    if (res) {
      if (res.success) setCards(res.data.result);
      else console.error(res.message);
    }
  };

  useEffect(() => {
    getWalletBalance();
  }, []);

  useEffect(() => {
    fetchPaymentMethods().then();
  }, []);

  const getWalletBalance = () => {
    Axios.get("/wallet").then((res) => setBalance(res.data?.wallet?.balance));
  };

  useEffect(() => {
    const reference = searchParams.get("reference");
    if (reference) {
      setLoading(true);
      Axios.get(`/wallet/fund/verify?reference=${reference}`)
        .then((res) => {
          // remove query
          const url = new URL(window.location);
          url.search = "";
          navigate(url.pathname, { replace: true });

          setBalance(res?.data?.data?.wallet?.balance);
          toast.success("Purchase verified");
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="p-4">
      <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab}>
        <Tab title="D-WALLET">
          <Wallet price={price} id={id} type={type} balance={balance} setBalance={setBalance} />
        </Tab>
        <Tab title="PAYMENT HISTORY">
          <PaymentHistory />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Payment;
